import React, { useState } from 'react';
import {
  TableBody,
  TableRow,
  withStyles,
  TableCell as MUITableCell,
  Box,
  makeStyles,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import { Order } from '../../utils/types';
import { SortableFields } from './DeliveriesTable';
import { DeliveriesQuery, DeliveryFieldsFragment } from '../../generated';
import CourierSelection from './CourierSelection';
import DeliveryDetails from './DeliveryDetails';
import DeliveryStatusTypography from './DeliveryStatus';
import { ApolloQueryResult } from '@apollo/client';

interface EnhancedTableBodyProps {
  data: DeliveryFieldsFragment[];
  order: Order;
  orderBy: SortableFields;
  refetchDeliveries: () => Promise<ApolloQueryResult<DeliveriesQuery>>;
}

const useStyles = makeStyles(() => ({
  detailsButton: {
    width: '150px',
    height: '35px',
    fontSize: '14px',
    padding: '5px 15px',
  },
  statusUnassigned: {
    color: '#DC1F1F',
    font: 'bold 14px/20px Montserrat',
  },
  statusDefault: {
    color: '#525252',
    font: 'bold 14px/20px Montserrat',
  },
  statusInProgress: {
    color: '#57A200',
    font: 'bold 14px/20px Montserrat',
  },
}));

const TableCell = withStyles(() => ({
  root: {
    font: 'normal normal 14px/14px Montserrat',
    padding: '16px 16px 16px 0',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    '&:first-child': {
      padding: '16px',
    },
  },
}))(MUITableCell);

const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = ({
  data,
  orderBy,
  order,
  refetchDeliveries,
}) => {
  const classes = useStyles();

  const [selectCourierDialogOpen, setSelectCourierDialogOpen] = useState(false);
  const [deliveryDetailsDialogOpen, setDeliveryDetailsDialogOpen] = useState(
    false,
  );
  const [
    selectedDelivery,
    setSelectedDelivery,
  ] = useState<DeliveryFieldsFragment | null>(null);

  const descendingComparator = (
    a: DeliveryFieldsFragment,
    b: DeliveryFieldsFragment,
    orderBy: SortableFields,
  ) => {
    if (b && a && b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] && a[orderBy] && b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = <T,>(
    array: DeliveryFieldsFragment[],
    comparator: (a: T, b: T) => number,
  ) => {
    const stabilizedThis = array.map(
      (el, index) => ([el, index] as unknown) as [T, number],
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: Order,
    orderBy: SortableFields,
  ): ((a: DeliveryFieldsFragment, b: DeliveryFieldsFragment) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  return (
    <TableBody>
      {selectedDelivery?.id && (
        <>
          <CourierSelection
            open={selectCourierDialogOpen}
            delivery={selectedDelivery}
            onCancel={() => {
              setSelectCourierDialogOpen(false);
              setSelectedDelivery(null);
            }}
            refetchDeliveries={refetchDeliveries}
          />
          <DeliveryDetails
            open={deliveryDetailsDialogOpen}
            delivery={selectedDelivery}
            onCancel={() => {
              setDeliveryDetailsDialogOpen(false);
              setSelectedDelivery(null);
            }}
          />
        </>
      )}
      {stableSort(data, getComparator(order, orderBy)).map((delivery) => {
        if (!delivery?.id) return null;
        return (
          <TableRow key={delivery.id}>
            <TableCell width="17%">
              <Box display="flex" alignItems="center">
                {delivery.id}
              </Box>
            </TableCell>
            <TableCell width="17%">
              <Box display="flex" alignItems="center">
                {delivery?.orderBatch?.referenceNumber}
              </Box>
            </TableCell>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                {delivery?.pickUp?.location?.formattedAddress}
              </Box>
            </TableCell>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                {delivery?.dropOff?.location?.formattedAddress}
              </Box>
            </TableCell>
            <TableCell width="10%">
              <DeliveryStatusTypography status={delivery?.status} />
            </TableCell>
            <TableCell width="150px">
              {delivery?.status === 'UNASSIGNED' ? (
                <Button
                  gradient
                  size="small"
                  classes={{ root: classes.detailsButton }}
                  onClick={() => {
                    setSelectCourierDialogOpen(true);
                    setSelectedDelivery(delivery);
                  }}>
                  Assign to courier
                </Button>
              ) : (
                <Button
                  size="small"
                  classes={{ root: classes.detailsButton }}
                  onClick={() => {
                    setDeliveryDetailsDialogOpen(true);
                    setSelectedDelivery(delivery);
                  }}>
                  Delivery details
                </Button>
              )}
            </TableCell>
            <TableCell width="50px" />
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default EnhancedTableBody;
