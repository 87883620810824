import React, { useCallback, useMemo } from 'react';
import {
  Box,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { CustomerFieldsFragment, UserFieldsFragment } from '../../generated';
import { SortableFields } from './CustomersTable';
import { Order } from '../../utils/types';

const useStyles = makeStyles(() => ({
  tableHeader: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
  tableHeaderCell: {
    padding: '16px 16px 16px 0',
    font: 'bold 14px/14px Montserrat',
    color: '#525252',
    '&:first-child': {
      padding: '16px',
    },
  },
  activeSortIcon: {
    opacity: 1,
  },
  inactiveSortIcon: {
    opacity: 0.3,
  },
}));
interface EnhancedTableProps {
  order: Order;
  orderBy: SortableFields;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: SortableFields,
  ) => void;
}
interface HeadCell {
  id:
    | keyof UserFieldsFragment
    | keyof CustomerFieldsFragment
    | 'walletApprovalState';
  label: string;
  width?: string;
}

const sortableFields = ['name', 'createdAt', 'walletApprovalState'];

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const classes = useStyles();

  const headCells: HeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        label: 'Customer Name',
        width: '20%',
      },
      {
        id: 'phone',
        label: 'Phone',
        width: '10%',
      },
      {
        id: 'email',
        label: 'Email',
        width: '20%',
      },
      {
        id: 'currentAddress',
        label: 'Current address',
        width: '20%',
      },
      {
        id: 'tag',
        label: 'Tag',
        width: '10%',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        width: '10%',
      },
      {
        id: 'walletApprovalState',
        label: 'Wallet activation',
        width: '10%',
      },
    ];
  }, []);

  const createSortHandler = useCallback(
    (property: SortableFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow classes={{ root: classes.tableHeader }}>
        {headCells.map((headCell) => (
          <TableCell
            width={headCell.width}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.tableHeaderCell }}>
            {sortableFields.includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                classes={{
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon,
                }}
                onClick={createSortHandler(headCell.id as SortableFields)}>
                <Box>{headCell.label}</Box>
              </TableSortLabel>
            ) : (
              <Box>{headCell.label}</Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
