import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import SearchInput from '../components/SearchInput';
import Button from '../components/common/Button';
import { Actions, AppContext } from '../store/index';
import classNames from 'classnames';
import { ReactComponent as ProfileIcon } from '../icons/profile-page-icon.svg';
import { ReactComponent as ProfileIconSelected } from '../icons/profile-page-icon-selected.svg';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { routes } from '../App';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(2),
    height: '55px',
    width: '55px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  headerContainer: {
    padding: 0,
    margin: '0 25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  layoutHeader: {
    height: '80px',
    background:
      'transparent linear-gradient(273deg, #DC1F5C 0%, #B22552 100%) 0% 0% no-repeat padding-box',
    boxShadow: 'none',
  },
  title: {
    marginLeft: '15px',
    font: 'normal normal 900 18px/18px Montserrat',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const useTabStyles = makeStyles((theme) => ({
  tab: {
    marginRight: '20px',
    padding: '5px 30px',
    fontWeight: 'bold',
    border: '2px solid #FFFFFF',
    borderRadius: '25px',
    background: 'none',
    color: '#FFFFFF',
    '&:hover': {
      background: 'none',
    },
  },
  iconTab: {
    border: '2px solid #FFFFFF',
    height: '40px',
    width: '40px',
    marginRight: '20px',
  },
  iconLabel: {
    width: '24px',
  },
  selectedTab: {
    background: '#FFFFFF',
    color: theme.palette.primary.main,
  },
}));

interface TabButtonProps {
  label: string;
  path: string;
}

const isHomePage = (path: string, location: string) =>
  location.includes(path) || location === '/';

const TabButton: React.FC<TabButtonProps> = ({ label, path }) => {
  const classes = useTabStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(AppContext);

  const clearFilters = useCallback(() => {
    dispatch({
      type: Actions.SET_MERCHANTS_SEARCH_INPUT,
      payload: '',
    });
    dispatch({
      type: Actions.UNDO_ALL_MERCHANTS_FILTERS,
    });
  }, [dispatch]);

  const tabSelected = useCallback(
    (path: string) => {
      return path.includes('/merchants')
        ? isHomePage(path, location.pathname)
        : location.pathname === path;
    },
    [location.pathname],
  );

  return path === '/profile' ? (
    <IconButton
      aria-label="delete"
      classes={{ label: classes.iconLabel }}
      className={classNames(
        classes.iconTab,
        tabSelected(path) && classes.selectedTab,
      )}
      onClick={() => navigate(path)}>
      {tabSelected(path) ? <ProfileIconSelected /> : <ProfileIcon />}
    </IconButton>
  ) : (
    <Button
      variant="outlined"
      className={classNames(
        classes.tab,
        tabSelected(path) && classes.selectedTab,
      )}
      onClick={() => {
        clearFilters();
        navigate(`${path}?${createSearchParams({ page: '1' }).toString()}`);
      }}>
      {label}
    </Button>
  );
};

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const tabs: TabButtonProps[] = useMemo(
    () => [
      { label: 'Merchants', path: routes.merchants },
      { label: 'Customers', path: routes.customers },
      { label: 'Couriers', path: routes.couriers },
      { label: 'Deliveries', path: routes.deliveries },
      { label: 'Profile Page', path: routes.profile },
    ],
    [],
  );

  useEffect(() => {
    if (!state.merchantSearchResults) return;
    dispatch({
      type: Actions.SET_MERCHANTS,
      payload: state.merchantSearchResults,
    });
  }, [state.merchantSearchResults, dispatch]);

  return (
    <>
      <AppBar
        position="static"
        classes={{
          root: classes.layoutHeader,
        }}>
        <Toolbar
          classes={{
            root: classes.headerContainer,
          }}>
          <Box display="flex" alignItems="center" height="80px">
            <Box
              className={classes.logoContainer}
              onClick={() => {
                dispatch({
                  type: Actions.SET_MERCHANTS_SEARCH_INPUT,
                  payload: '',
                });
                dispatch({
                  type: Actions.UNDO_ALL_MERCHANTS_FILTERS,
                });
                navigate(
                  `${routes.merchants}?${createSearchParams({
                    page: '1',
                  }).toString()}`,
                );
              }}>
              <Avatar className={classes.avatar} src="./logo-white.png" />
              <Typography className={classes.title} variant="h6">
                Cashia Admin
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" ml={6}>
              {tabs.map(({ label, path }) => {
                return <TabButton key={path} label={label} path={path} />;
              })}
            </Box>
          </Box>
          {isHomePage('/merchants', location.pathname) && <SearchInput />}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{children}</main>
    </>
  );
};

export default Layout;
