import React, { useCallback, useContext, useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tab,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CategoryForm from './CategoryForm';
import { MerchantCategory } from '../../generated';
import Button from '../../components/common/Button';
import { AppContext } from '../../store';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

interface AddOrUpdateCategoriesDialogProps {
  open: boolean;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    height: '90vh',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: '25px 40px 40px 40px',
  },
  title: {
    margin: '28px 0 0 40px',
  },
  manageCategoryTitle: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
  },
  closeButton: {
    height: '40px',
    padding: '0',
    margin: '8px',
  },
  icon: {
    fontSize: '40px',
    color: '#525252',
  },
  categoryFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
  },
  addCategoryButton: {
    width: '170px',
    alignSelf: 'end',
    margin: '20px 0',
  },
  tabPanel: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  noSubcategoriesBox: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  noSubcategories: {
    alignSelf: 'center',
  },
}));

const AddOrUpdateCategoriesDialog: React.FC<AddOrUpdateCategoriesDialogProps> = ({
  open,
  onCancel,
}) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);

  const [categories, setCategories] = useState<MerchantCategory[]>(
    state.merchantCategories || [],
  );
  const [expanded, setExpanded] = useState<number[]>([]);
  const [currentTab, setCurrentTab] = useState('0');

  useEffect(() => {
    setCategories(state.merchantCategories);
  }, [state.merchantCategories]);

  const addCategory = useCallback(
    (id) => {
      const newCategory: MerchantCategory = {
        name: '',
        id: '',
        children: [],
      };
      setExpanded([...expanded, categories.length]);
      setCategories(
        categories.map((cat) =>
          cat.id === id
            ? {
                ...cat,
                children: cat.children
                  ? [...cat.children, newCategory]
                  : [newCategory],
              }
            : cat,
        ),
      );
    },
    [categories, expanded],
  );

  const removeCategory = useCallback(
    (index: number) => {
      setCategories(categories.filter((_, i) => i !== index));
      setExpanded(expanded.filter((e) => e !== index));
    },
    [categories, expanded],
  );

  const onExpand = useCallback(
    (index: number, expand: boolean) => {
      setExpanded(
        expand ? [...expanded, index] : expanded.filter((e) => e !== index),
      );
    },
    [expanded, setExpanded],
  );

  const handleTabChange = useCallback((_, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialog }}>
      <Box className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.title}>
              <Typography className={classes.manageCategoryTitle}>
                Add or update merchants categories
              </Typography>
            </Box>
            <IconButton className={classes.closeButton} onClick={onCancel}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.categoryFormContainer}>
            <TabContext value={currentTab}>
              <TabList
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="on">
                {categories.map((category, i) => (
                  <Tab
                    value={`${i}`}
                    label={category.name}
                    wrapped
                    key={`${category.name}${i}`}
                  />
                ))}
              </TabList>
              {categories.map((category, i) => (
                <TabPanel
                  value={`${i}`}
                  key={`${category.name}${i}`}
                  className={classes.tabPanel}>
                  <Button
                    gradient
                    large
                    className={classes.addCategoryButton}
                    onClick={() => addCategory(category.id)}>
                    Add category
                  </Button>
                  {category.children?.length === 0 && (
                    <Box className={classes.noSubcategoriesBox}>
                      <Typography className={classes.noSubcategories}>
                        No subcategories
                      </Typography>
                    </Box>
                  )}
                  {category.children?.map(
                    (cat, i) =>
                      cat && (
                        <CategoryForm
                          key={`${category.name}${i}`}
                          category={cat}
                          parent={category}
                          expanded={expanded.includes(i)}
                          onExpand={(e) => onExpand(i, e)}
                          onRemove={() => removeCategory(i)}
                        />
                      ),
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddOrUpdateCategoriesDialog;
