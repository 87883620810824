import {
  TableBody,
  TableRow,
  withStyles,
  TableCell as MUITableCell,
  Box,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';
import React from 'react';
import { CourierFieldsFragment } from '../../generated';
import { Order, SortableFields } from './CouriersTable';
import DeliveriesHistory from './DeliveriesHistory';
import AvatarWithBadge from './AvatarWithBadge';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../App';

interface EnhancedTableBodyProps {
  data: CourierFieldsFragment[];
  order: Order;
  orderBy: SortableFields;
}

const useStyles = makeStyles(() => ({
  onlineStatus: {
    color: '#57A200',
    font: 'bold 14px/20px Montserrat',
  },
  offlineStatus: {
    color: '#DC1F1F',
    font: 'bold 14px/20px Montserrat',
  },
  deliveriesHistoryButton: {
    color: '#525252',
    font: 'normal 14px/20px Montserrat',
  },
}));

const TableCell = withStyles(() => ({
  root: {
    font: 'normal normal 14px/14px Montserrat',
    padding: '16px 16px 16px 0',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    '&:first-child': {
      padding: '16px',
    },
  },
}))(MUITableCell);

const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = ({
  data,
  orderBy,
  order,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { courierId } = useParams();

  const descendingComparator = (
    a: CourierFieldsFragment,
    b: CourierFieldsFragment,
    orderBy: SortableFields,
  ) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = <T,>(
    array: CourierFieldsFragment[],
    comparator: (a: T, b: T) => number,
  ) => {
    const stabilizedThis = array.map(
      (el, index) => ([el, index] as unknown) as [T, number],
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: Order,
    orderBy: SortableFields,
  ): ((a: CourierFieldsFragment, b: CourierFieldsFragment) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy)).map((courier) => {
        return (
          <TableRow key={courier?.id}>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                <AvatarWithBadge
                  online={courier?.online}
                  avatar={courier.avatar?.small}
                />
                {courier?.user?.name}
              </Box>
            </TableCell>
            <TableCell width="10%">{courier?.user?.phone}</TableCell>
            <TableCell width="10%">
              <Box display="flex" alignItems="center">
                {courier?.online ? (
                  <Typography
                    style={{ whiteSpace: 'nowrap' }}
                    className={classes.onlineStatus}>
                    Online
                  </Typography>
                ) : (
                  <Typography
                    style={{ whiteSpace: 'nowrap' }}
                    className={classes.offlineStatus}>
                    Offline
                  </Typography>
                )}
              </Box>
            </TableCell>
            <TableCell width="10%">
              {courier?.id && (
                <>
                  <Link
                    component="button"
                    className={classes.deliveriesHistoryButton}
                    onClick={() => {
                      navigate(
                        `${routes.couriers}/history/${courier.id || ''}`,
                      );
                    }}>
                    View deliveries history
                  </Link>
                  {courierId && <DeliveriesHistory />}
                </>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default EnhancedTableBody;
