import React from 'react';
import ReactIs from 'react-is';

interface TestAttributeProps {
  id: string;
  suffix?: string;
}

export const TestAttribute: React.FC<TestAttributeProps> = ({
  children,
  id,
  suffix = 'testid',
}) => {
  const isAvailable = process.env.REACT_ENV === 'test';

  function withTestAttribute(nodes: React.ReactNode): React.ReactNode {
    const node = React.Children.only(nodes);
    const testAttributeName = `data-${suffix}`;

    if (ReactIs.isFragment(node)) {
      return React.createElement('div', { [testAttributeName]: id }, node);
    } else if (ReactIs.isElement(node)) {
      return React.cloneElement(node, {
        [testAttributeName]: id,
      });
    } else {
      return node;
    }
  }

  return <>{isAvailable ? withTestAttribute(children) : children}</>;
};
