import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { MerchantCategory } from '../../generated';
import CategoryItem from './CategoryItem';

interface CategoryFormProps {
  category: MerchantCategory;
  parent?: MerchantCategory;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onRemove: () => void;
}

const useStyles = makeStyles(() => ({
  accordion: {
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  noSubcategoriesBox: {
    height: '52px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noSubcategories: {
    alignSelf: 'center',
  },
  expandIcon: {
    marginLeft: '6px',
    marginRight: '6px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 1px 10px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '50%',
    },
  },
}));

const CategoryForm: React.FC<CategoryFormProps> = ({
  category,
  parent,
  expanded,
  onExpand,
  onRemove,
}) => {
  const classes = useStyles();

  const [data, setData] = useState<MerchantCategory>(category);

  useEffect(() => {
    setData(category);
  }, [category, setData]);

  const addSubCategory = () => {
    const newSubcategory: MerchantCategory = { id: '', name: '', children: [] };
    setData({
      ...data,
      children: !data.children
        ? [newSubcategory]
        : [newSubcategory, ...data.children],
    });
  };

  const removeSubCategory = (index: number) => {
    if (!data.children) return;
    const children = [...data.children];
    children.splice(index, 1);
    setData({ ...data, children });
  };

  return (
    <Accordion
      onChange={(_, expanded) => onExpand(expanded)}
      expanded={expanded}
      className={classes.accordion}>
      <Box width="100%" borderRadius="5px">
        <AccordionSummary
          expandIcon={<ExpandMore className={classes.expandIcon} />}>
          <CategoryItem
            category={category}
            onDelete={onRemove}
            isNew={category.id === ''}
            onCreate={addSubCategory}
            expanded={expanded}
            parent={parent}
          />
        </AccordionSummary>
      </Box>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%">
          {data?.children?.length === 0 && (
            <Box className={classes.noSubcategoriesBox}>
              <Typography className={classes.noSubcategories}>
                No subcategories
              </Typography>
            </Box>
          )}
          {data?.children?.map(
            (subCategory, i) =>
              subCategory && (
                <Box key={i} width="100%">
                  <Box width="100%">
                    <CategoryItem
                      key={`${subCategory.name}${i}`}
                      category={subCategory}
                      onDelete={() => removeSubCategory(i)}
                      parent={category}
                      isNew={subCategory.id === ''}
                      saveDisabled={category.id === ''}
                    />
                  </Box>
                </Box>
              ),
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoryForm;
