import React from 'react';
import Button from '../../components/common/Button';
import { Box, makeStyles, TextField } from '@material-ui/core';

interface CommentTextfieldProps {
  comment: string;
  setComment: (approvalState: string) => void;
  updateApprovalState: () => void;
}

const useStyles = makeStyles(() => ({
  messageTextarea: {
    margin: '15px 15px 10px 15px',
    width: 'fill-available',
  },
  textareaInput: {
    borderRadius: '8px',
    fontSize: '14px',
  },
  skipButton: {
    minWidth: '84px',
    height: '35px',
    marginRight: '20px',
  },
  addButton: {
    minWidth: '84px',
    height: '35px',
  },
}));

const maxCommentLength = 280;

const CommentTextfield: React.FC<CommentTextfieldProps> = ({
  comment,
  setComment,
  updateApprovalState,
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        className={classes.messageTextarea}
        placeholder="You can add a reason here"
        InputProps={{
          className: classes.textareaInput,
        }}
        value={comment}
        onChange={({ target }) => setComment(target.value)}
        error={comment.length > maxCommentLength}
        helperText={
          comment.length > maxCommentLength &&
          `Your comment should not be longer than ${maxCommentLength} characters`
        }
      />
      <Box mb={2} display="flex" justifyContent="center">
        <Button className={classes.skipButton} onClick={updateApprovalState}>
          Skip
        </Button>
        <Button
          gradient
          disabled={comment.length > maxCommentLength}
          className={classes.addButton}
          onClick={updateApprovalState}>
          Add
        </Button>
      </Box>
    </>
  );
};

export default CommentTextfield;
