import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  CardMedia,
  Link,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import {
  MerchantDetailsFieldsFragment,
  Maybe,
  OperatingTimeFieldsFragment,
  useMerchantDetailsQuery,
} from '../../generated';
import CloseIcon from '@material-ui/icons/Close';
import MerchantProducts from './MerchantProducts';
import { InsertPhoto } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getValidUrl } from '../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    padding: '55px 40px 40px 40px',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    padding: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.primary.main,
  },
  merchantInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    paddingRight: '40px',
  },
  merchantProducts: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  merchantLogo: {
    maxWidth: 170,
    minWidth: 170,
    height: 170,
    borderRadius: '14px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  infoTitle: {
    font: 'normal normal bold 15px/24px Montserrat',
    color: '#525252',
    marginBottom: '20px',
  },
  infoTitleContainer: {
    margin: '0',
    marginBottom: '10px',
  },
  description: {
    width: '50%',
    marginRight: '10px',
  },
  operatingTime: {
    marginLeft: '10px',
    width: '50%',
  },
  operatingHours: {
    marginBottom: '10px',
  },
  coverImageButton: {
    font: 'normal 15px/24px Montserrat',
    marginTop: '20px',
  },
  progressContainer: {
    width: '100%',
    textAlign: 'center',
  },
  address: {
    display: 'block',
    marginTop: '5px',
    '&:first-child': {
      display: 'inline',
    },
  },
  coverImage: {
    width: '100%',
    height: '80vh',
  },
}));

const daysOfWeek = {
  mo: 'Monday',
  tu: 'Tuesday',
  we: 'Wednesday',
  th: 'Thursday',
  fr: 'Friday',
  sa: 'Saturday',
  su: 'Sunday',
} as const;

type DaysOfWeek = keyof typeof daysOfWeek;

const checkURL = (url: string) => {
  return /\.(jpeg|jpg|gif|png)$/.exec(url);
};

const MerchantDetails: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [merchant, setMerchant] = useState<
    Maybe<MerchantDetailsFieldsFragment>
  >();
  const [showCoverImage, setShowCoverImage] = useState(false);
  const { data, loading } = useMerchantDetailsQuery({
    variables: { id: params.merchantId || '' },
  });

  useEffect(() => {
    setMerchant(data?.merchant);
  }, [data?.merchant]);

  const operatingHoursNotEmpty = useCallback<{
    (
      operatingTime: Maybe<
        { __typename?: 'OperatingTime' } & OperatingTimeFieldsFragment
      >,
    ): boolean;
  }>(
    (operatingTime) =>
      !!operatingTime &&
      (Object.keys(operatingTime) as DaysOfWeek[]).filter(
        (day: DaysOfWeek | '__typename') =>
          day !== '__typename' && operatingTime?.[day]?.length !== 0,
      ).length !== 0,
    [],
  );

  return (
    <>
      {merchant?.coverImage && (
        <Dialog
          open={showCoverImage}
          onClose={() => setShowCoverImage(false)}
          classes={{ paper: classes.dialog }}>
          {merchant.coverImage.large && checkURL(merchant.coverImage.large) ? (
            <CardMedia component="img" image={merchant.coverImage.large} />
          ) : (
            <Avatar variant="square" className={classes.coverImage}>
              <InsertPhoto />
            </Avatar>
          )}
        </Dialog>
      )}
      <Dialog
        open
        onClose={() => navigate(-1)}
        classes={{ paper: classes.dialog }}>
        <Box className={classes.dialogContainer}>
          <DialogTitle className={classes.dialogTitle}>
            <IconButton
              className={classes.closeButton}
              onClick={() => navigate(-1)}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogTitle>
          {loading && (
            <div className={classes.progressContainer}>
              <CircularProgress />
            </div>
          )}
          <DialogContent className={classes.dialogContent}>
            {!loading && !!merchant && (
              <>
                <Box className={classes.merchantInfo}>
                  <Box display="flex">
                    {merchant.logo?.small ? (
                      <Avatar
                        variant="square"
                        className={classes.merchantLogo}
                        src={merchant.logo?.small}
                      />
                    ) : (
                      <Avatar variant="square" className={classes.merchantLogo}>
                        <InsertPhoto />
                      </Avatar>
                    )}
                    <Box
                      ml={2.5}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-around">
                      {merchant.name && (
                        <p className={classes.infoTitleContainer}>
                          <span className={classes.infoTitle}>
                            Name: {merchant.name}
                          </span>
                        </p>
                      )}
                      {merchant.email && (
                        <p className={classes.infoTitleContainer}>
                          <span className={classes.infoTitle}>Email: </span>
                          <span>{merchant.email}</span>
                        </p>
                      )}
                      {merchant.phone && (
                        <p className={classes.infoTitleContainer}>
                          <span className={classes.infoTitle}>Phone: </span>
                          <span>{merchant.phone}</span>
                        </p>
                      )}
                      {merchant.website && (
                        <p className={classes.infoTitleContainer}>
                          <span className={classes.infoTitle}>Website: </span>
                          {merchant.website && (
                            <Link
                              target="_blank"
                              rel="noopener"
                              href={getValidUrl(merchant.website)}>
                              {merchant.website}
                            </Link>
                          )}
                        </p>
                      )}
                      {merchant.addresses && merchant.addresses.length > 0 && (
                        <p className={classes.infoTitleContainer}>
                          <span className={classes.infoTitle}>Address: </span>
                          <span>
                            {merchant.addresses.map(({ id, location }) => (
                              <span key={id} className={classes.address}>
                                <Link
                                  target="_blank"
                                  rel="noopener"
                                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    location.formattedAddress,
                                  )}`}>
                                  {location.formattedAddress}
                                </Link>
                              </span>
                            ))}
                          </span>
                        </p>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" mt={3.5}>
                    <Box className={classes.description}>
                      {merchant.description && (
                        <Box display="flex" flexDirection="column">
                          <span className={classes.infoTitle}>
                            Full description:
                          </span>
                          <span>{merchant.description}</span>
                        </Box>
                      )}
                      {merchant?.coverImage && (
                        <Link
                          component="button"
                          className={classes.coverImageButton}
                          onClick={() => {
                            setShowCoverImage(true);
                          }}>
                          See cover image
                        </Link>
                      )}
                    </Box>

                    {merchant.operatingTime &&
                      operatingHoursNotEmpty(merchant.operatingTime) && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={classes.operatingTime}>
                          <span className={classes.infoTitle}>
                            Opening hours:
                          </span>
                          <Box display="flex">
                            <Box display="flex" flexDirection="column">
                              {Object.values(daysOfWeek).map((day) => (
                                <span
                                  key={day}
                                  className={classes.operatingHours}>
                                  {day}
                                </span>
                              ))}
                            </Box>
                            <Box display="flex" flexDirection="column" ml={2}>
                              {(Object.keys(daysOfWeek) as DaysOfWeek[]).map(
                                (day: DaysOfWeek) => {
                                  return (
                                    <span
                                      key={day}
                                      className={classes.operatingHours}>
                                      {merchant?.operatingTime?.[day]
                                        ?.length === 0
                                        ? 'Closed'
                                        : merchant?.operatingTime?.[day]
                                            ?.map((interval) => {
                                              return (
                                                interval &&
                                                `${String(
                                                  interval.from || 'any',
                                                )} - ${String(
                                                  interval.to || 'any',
                                                )}`
                                              );
                                            })
                                            .join(', ')}
                                    </span>
                                  );
                                },
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                </Box>
                <Box className={classes.merchantProducts}>
                  <MerchantProducts merchant={merchant} />
                </Box>
              </>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default MerchantDetails;
