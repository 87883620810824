import React, { useState, ReactNode, useCallback } from 'react';
import { makeStyles, Switch, Box, Popover } from '@material-ui/core';
import { ApprovalState, MerchantFieldsFragment } from '../../generated';
import Button from '../../components/common/Button';
import CommentTextfield from './CommentTextfield';

interface ChangeApprovalStatePopover {
  merchant: MerchantFieldsFragment;
  onConfirm: (approvalState: ApprovalState, message?: string) => void;
}

const ChangeApprovalStatePopover: React.FC<ChangeApprovalStatePopover> = ({
  merchant,
  onConfirm,
}) => {
  const [anchorEl, setAnchorEl] = useState<ReactNode | null>(null);
  const [popoverContent, setPopoverContent] = useState('buttons');
  const [comment, setComment] = useState('');
  const [approvalState, setApprovalState] = useState<ApprovalState>(
    ApprovalState.ReviewPending,
  );

  const useStyles = makeStyles(() => ({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px',
    },
    actionButton: {
      marginBottom: '10px',
      '&:last-child': {
        marginBottom: '0',
      },
    },
    popoverContainer: {
      width: popoverContent === 'buttons' ? '220px' : '300px',
      border: '1px solid #DBDBDB',
      boxShadow: '0px 0px 10px #00000019',
      borderRadius: '8px',
    },
  }));

  const classes = useStyles();

  const updateApprovalState = useCallback(() => {
    comment.replace(/\s/g, '').length
      ? onConfirm(approvalState, comment)
      : onConfirm(approvalState);
    setComment('');
    setPopoverContent('buttons');
    setAnchorEl(null);
  }, [approvalState, comment, onConfirm]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setComment('');
    setPopoverContent('buttons');
  }, []);

  return (
    <div>
      <Switch
        checked={merchant.approvalState === ApprovalState.Approved}
        color="primary"
        onChange={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        inputProps={{ 'aria-label': 'approval state' }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl as Element}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popoverContainer }}>
        {popoverContent === 'buttons' ? (
          <Box className={classes.buttonContainer}>
            {merchant.approvalState !== ApprovalState.Rejected && (
              <Button
                className={classes.actionButton}
                onClick={() => {
                  setPopoverContent('approvalMessage');
                  setApprovalState(ApprovalState.Rejected);
                }}>
                Reject
              </Button>
            )}
            {merchant.approvalState !== ApprovalState.ChangesRequired && (
              <Button
                className={classes.actionButton}
                onClick={() => {
                  setPopoverContent('approvalMessage');
                  setApprovalState(ApprovalState.ChangesRequired);
                }}>
                Changes Required
              </Button>
            )}
            {merchant.approvalState !== ApprovalState.Approved && (
              <Button
                gradient
                className={classes.actionButton}
                onClick={() => {
                  setPopoverContent('approvalMessage');
                  setApprovalState(ApprovalState.Approved);
                }}>
                Approve
              </Button>
            )}
          </Box>
        ) : (
          <CommentTextfield
            comment={comment}
            setComment={setComment}
            updateApprovalState={updateApprovalState}
          />
        )}
      </Popover>
    </div>
  );
};

export default ChangeApprovalStatePopover;
