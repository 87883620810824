import React, { useContext } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import { ApprovalState, MerchantSelection } from '../../generated';
import { Actions, AppContext } from '../../store/index';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from '../../App';

interface FilterDrawerProps {
  toggleDrawer: (drawerOpened: boolean) => void;
}

enum ApprovalStateLabels {
  NOT_IN_REVIEW = 'Not In Review',
  REVIEW_PENDING = 'Review Pending',
  CHANGES_REQUIRED = 'Changes Required',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum MerchantSelectionLabels {
  FEATURED = 'Featured',
  POPULAR = 'Popular',
  ALLOWS_DIRECT_PAYMENTS = 'Allow direct payments',
}

export type SelectionFilters = Array<keyof typeof MerchantSelectionLabels>;

const useStyles = makeStyles(() => ({
  approvalStatusFilter: {
    margin: '110px 30px 25px 30px',
    padding: '20px 20px 25px 20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '5px',
  },
  filterTitle: {
    font: 'bold 14px/20px Montserrat',
    color: '#525252',
    marginBottom: '10px',
  },
  filterSelectContainer: {
    width: '100%',
  },
  filterSelect: {
    padding: '8px 14px',
    fontSize: '14px',
  },
  filterMenuItem: {
    fontSize: '14px',
  },
  selectionFilter: {
    margin: '0 30px 25px 30px',
    padding: '20px 20px 25px 20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '5px',
  },
  buttonContainer: {
    margin: '0 30px 25px 30px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  undoFiltersButton: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
}));

const FilterDrawer: React.FC<FilterDrawerProps> = ({ toggleDrawer }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  return (
    <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
      <Box className={classes.approvalStatusFilter}>
        <Typography className={classes.filterTitle}>
          Filter by approval status:
        </Typography>
        <FormControl
          variant="outlined"
          className={classes.filterSelectContainer}>
          <Select
            value={state.merchantsFilterDrawer.selectedApprovalStates}
            multiple
            displayEmpty
            classes={{ root: classes.filterSelect }}
            onChange={(e) => {
              dispatch({
                type: Actions.SET_SELECTED_MERCHANTS_APPROVAL_STATES,
                payload: e.target.value as ApprovalState[],
              });
            }}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Show all</em>;
              }
              return (selected as Array<keyof typeof ApprovalStateLabels>)
                .map((key) => ApprovalStateLabels[key])
                .join(', ');
            }}>
            <MenuItem disabled value="" className={classes.filterMenuItem}>
              <em>Show all</em>
            </MenuItem>
            {(Object.keys(ApprovalStateLabels) as Array<
              keyof typeof ApprovalStateLabels
            >).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={key}
                  className={classes.filterMenuItem}>
                  {ApprovalStateLabels[key]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.selectionFilter}>
        <Typography className={classes.filterTitle}>
          Filter by selection:
        </Typography>
        <FormControl
          variant="outlined"
          className={classes.filterSelectContainer}>
          <Select
            value={state.merchantsFilterDrawer.selectedSelections}
            multiple
            displayEmpty
            classes={{ root: classes.filterSelect }}
            onChange={(e) => {
              dispatch({
                type: Actions.SET_SELECTED_MERCHANTS_SELECTIONS,
                payload: e.target.value as MerchantSelection[],
              });
            }}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Show all</em>;
              }
              return (selected as Array<keyof typeof MerchantSelectionLabels>)
                .map((key) => MerchantSelectionLabels[key])
                .join(', ');
            }}>
            <MenuItem disabled value="" className={classes.filterMenuItem}>
              <em>Show all</em>
            </MenuItem>
            {(Object.keys(MerchantSelectionLabels) as Array<
              keyof typeof MerchantSelectionLabels
            >).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={key}
                  className={classes.filterMenuItem}>
                  {MerchantSelectionLabels[key]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.undoFiltersButton}
          onClick={() => {
            dispatch({
              type: Actions.UNDO_ALL_MERCHANTS_FILTERS,
            });
          }}>
          Undo filter
        </Button>
        <Button
          gradient
          autoFocus
          onClick={() => {
            dispatch({
              type: Actions.SET_MERCHANTS_APPROVAL_STATE_FILTERS,
              payload: state.merchantsFilterDrawer.selectedApprovalStates,
            });
            dispatch({
              type: Actions.SET_MERCHANTS_SELECTION_FILTERS,
              payload: state.merchantsFilterDrawer.selectedSelections,
            });
            navigate(
              `${routes.merchants}?${createSearchParams({
                page: '1',
              }).toString()}`,
            );
            toggleDrawer(false);
          }}>
          Apply
        </Button>
      </Box>
    </div>
  );
};

export default FilterDrawer;
