import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#dc1f5c',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#535353',
      hint: '#9b9b9b',
    },
  },
  typography: {
    fontFamily: `"Montserrat", sans-serif`,
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
