import React, { ReactNode, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Popover,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  HighlightOffOutlined,
  ErrorOutlineOutlined,
} from '@material-ui/icons';
import {
  ApprovalState,
  Maybe,
  MerchantFieldsFragment,
  MerchantSelection,
} from '../../generated';
import { MerchantSelectionLabels } from './FilterDrawer';
import CustomButton from '../../components/common/Button';
import { MerchantSelectionAssignedByAdmin } from './SelectionPopover';
import CommentTextfield from './CommentTextfield';

interface CardProps {
  merchants: MerchantFieldsFragment[];
  merchantsIds: string[];
  updateMerchantApprovalState: (
    id?: Maybe<string>,
    state?: ApprovalState,
    message?: string,
  ) => void;
  setSelectedMechantIDs: (ids: string[]) => void;
  updateMerchant: (id?: Maybe<string>, selection?: MerchantSelection[]) => void;
}

const useStyles = makeStyles(() => ({
  cardContainer: {
    width: '100%',
    height: '56px',
    position: 'absolute',
    top: '80px',
    zIndex: 5,
    boxShadow: '0px 10px 10px #00000015',
  },
  cardContent: {
    padding: '7px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:last-child': {
      paddingBottom: '7px',
    },
  },
  approveButton: {
    color: '#57A200',
    font: 'normal 14px/14px Montserrat',
  },
  rejectButton: {
    color: '#DC1F1F',
    font: 'normal 14px/14px Montserrat',
  },
  changesRequiredButton: {
    color: '#535353',
    font: 'normal 14px/14px Montserrat',
  },
  iconButton: {
    marginRight: '5px',
  },
  selectionCheckboxesContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectionCheckboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addToSelection: {
    paddingRight: '30px',
    font: 'normal normal bold 14px/14px Montserrat',
  },
  checkboxLabel: {
    font: 'normal 14px/14px Montserrat',
  },
  okButton: {
    minWidth: '64px',
    height: '30px',
    marginLeft: '15px',
  },
  popoverContainer: {
    width: '300px',
    border: '1px solid #DBDBDB',
    boxShadow: '0px 0px 10px #00000019',
    borderRadius: '8px',
  },
}));

const ManageMerchantsCard: React.FC<CardProps> = ({
  merchants,
  merchantsIds,
  updateMerchantApprovalState,
  setSelectedMechantIDs,
  updateMerchant,
}) => {
  const classes = useStyles();

  const [selectionState, setSelectionState] = useState<
    Record<MerchantSelection, boolean>
  >(
    (Object.keys(MerchantSelectionLabels) as MerchantSelection[]).reduce(
      (acc, key) => {
        return { ...acc, [key]: false };
      },
      {} as Record<MerchantSelection, boolean>,
    ),
  );
  const [anchorEl, setAnchorEl] = useState<ReactNode | null>(null);
  const [comment, setComment] = useState('');
  const [approvalState, setApprovalState] = useState<ApprovalState | null>(
    null,
  );

  const updateMultipleMerchants = useCallback(
    (selection: MerchantSelection[]) => {
      merchantsIds.map((id) => {
        const currentSelection =
          merchants.find((merchant) => merchant?.id === id)?.selection || [];
        updateMerchant(
          id,
          Array.from(new Set(currentSelection.concat(selection))),
        );
      });
      setSelectedMechantIDs([]);
    },
    [merchants, merchantsIds, updateMerchant, setSelectedMechantIDs],
  );

  const updateMultipleMerchantsApprovalState = useCallback(
    (approvalState: ApprovalState | null, message?: string) => {
      approvalState &&
        merchantsIds.map((id) =>
          updateMerchantApprovalState(id, approvalState, message),
        );
      setSelectedMechantIDs([]);
    },
    [merchantsIds, updateMerchantApprovalState, setSelectedMechantIDs],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectionState({
        ...selectionState,
        [event.target.name]: event.target.checked,
      });
    },
    [setSelectionState, selectionState],
  );

  const updateApprovalState = useCallback(() => {
    comment.replace(/\s/g, '').length
      ? updateMultipleMerchantsApprovalState(approvalState, comment)
      : updateMultipleMerchantsApprovalState(approvalState);
    setComment('');
    setAnchorEl(null);
    setApprovalState(null);
  }, [approvalState, comment, updateMultipleMerchantsApprovalState]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setComment('');
    setApprovalState(null);
  }, []);

  return (
    <Card className={classes.cardContainer} variant="outlined">
      <CardContent classes={{ root: classes.cardContent }}>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl as Element}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.popoverContainer }}>
          <CommentTextfield
            comment={comment}
            setComment={setComment}
            updateApprovalState={updateApprovalState}
          />
        </Popover>
        <Box>
          <Button
            className={classes.approveButton}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setApprovalState(ApprovalState.Approved);
            }}>
            <CheckCircleOutline className={classes.iconButton} />
            Approve
          </Button>
          <Button
            className={classes.rejectButton}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setApprovalState(ApprovalState.Rejected);
            }}>
            <HighlightOffOutlined className={classes.iconButton} />
            Reject
          </Button>
          <Button
            className={classes.changesRequiredButton}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setApprovalState(ApprovalState.ChangesRequired);
            }}>
            <ErrorOutlineOutlined className={classes.iconButton} />
            Changes required
          </Button>
        </Box>
        <Box className={classes.selectionCheckboxesContainer}>
          <Typography className={classes.addToSelection}>
            Add to selection:
          </Typography>
          <FormGroup className={classes.selectionCheckboxes}>
            {(Object.keys(
              MerchantSelectionLabels,
            ) as MerchantSelectionAssignedByAdmin[]).map((key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={selectionState[key]}
                      onChange={handleChange}
                      name={key}
                      color="primary"
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  label={MerchantSelectionLabels[key]}
                />
              );
            })}
          </FormGroup>
          <CustomButton
            gradient
            autoFocus
            size="small"
            classes={{ root: classes.okButton }}
            onClick={() => {
              updateMultipleMerchants(
                (Object.keys(selectionState) as MerchantSelection[]).filter(
                  (selection) => selectionState[selection],
                ),
              );
            }}>
            Ok
          </CustomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ManageMerchantsCard;
