import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  Typography,
  Box,
  IconButton,
  CircularProgress,
  makeStyles,
  Theme,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  EditOutlined,
  Check,
  AddBoxOutlined,
  DeleteOutline,
} from '@material-ui/icons';
import {
  MerchantCategoriesDocument,
  MerchantCategoriesQuery,
  MerchantCategory,
  useAddOrUpdateMerchantCategoryMutation,
  useRemoveMerchantCategoryMutation,
} from '../../generated';
import { useNotification } from '../../utils/notification';
import { useFormik } from 'formik';
import Button from '../../components/common/Button';

interface CategoryItemProps {
  category: MerchantCategory;
  parent?: MerchantCategory;
  isNew?: boolean;
  saveDisabled?: boolean;
  onDelete: () => void;
  onCreate?: () => void;
  expanded?: boolean;
}

interface StyleProps {
  hasParent?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  itemName: {
    paddingLeft: '4px',
    textAlign: 'left',
    overflowWrap: 'anywhere',
  },
  icon: {
    marginRight: '8px',
    [theme.breakpoints.down('xs')]: {
      padding: '3px',
    },
  },
  confirmEditIcon: {
    margin: '0 8px',
  },
  form: {
    width: '100%',
    height: '54px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textfield: {
    margin: 0,
    paddingLeft: '2px',
  },
  textfieldBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '55px',
    paddingLeft: (props: StyleProps) => (props.hasParent ? '40px' : '9px'),
  },
  deleteLoaderBox: {
    display: 'flex',
    marginLeft: '45%',
    marginRight: '55%',
    alignItems: 'center',
  },
  input: {
    padding: '6px 3px',
  },
  folderAndName: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContainer: {
    padding: '40px',
    borderRadius: '10px',
  },
  primaryText: {
    fontWeight: 'bold',
  },
  secondaryText: {
    fontSize: '14px',
    marginTop: '20px',
  },
  dialogActions: {
    marginTop: '40px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const validationSchemaCategory = yup.object().shape({
  name: yup
    .string()
    .required('Name required')
    .trim('The category name cannot include leading and trailing spaces')
    .max(40, 'Category name should not be more than 40 characters'),
});

const validationSchemaSubcategory = yup.object().shape({
  name: yup
    .string()
    .required('Name required')
    .trim('The subcategory name cannot include leading and trailing spaces')
    .max(40, 'Subcategory name should not be more than 40 characters'),
});

const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  parent,
  isNew,
  saveDisabled = false,
  onDelete,
  onCreate,
  expanded,
}) => {
  const isSubcategory = useMemo(() => Boolean(parent?.parent), [
    parent?.parent,
  ]);

  const classes = useStyles({ hasParent: isSubcategory });

  const [editing, setEditing] = useState(!!isNew);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const { openNotification } = useNotification();

  const [
    addOrUpdate,
    { loading: addLoading },
  ] = useAddOrUpdateMerchantCategoryMutation();
  const [
    remove,
    { loading: removeLoading },
  ] = useRemoveMerchantCategoryMutation();

  const validationSchema = !parent
    ? validationSchemaCategory
    : validationSchemaSubcategory;

  const {
    values: { name },
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      name: category.name,
    },
    onSubmit: async () => {
      try {
        await save();
      } catch (e) {
        if (e instanceof Error) {
          openNotification(e.message, 'error');
        }
      }
    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const save = async () => {
    setEditing(false);
    await addOrUpdateMutationAndCacheUpdate();
    openNotification(
      `${isSubcategory ? 'Subcategory' : 'Category'} successfully saved!`,
      'success',
    );
  };

  const addOrUpdateMutationAndCacheUpdate = async () => {
    await addOrUpdate({
      variables: {
        input: {
          name: name.trim(),
          ...(category.id !== '' && { id: category.id }),
          ...(parent?.id && { parentId: parent.id }),
        },
      },
      update: (cache, res) => {
        const categoryId = res.data?.addOrUpdateMerchantCategory.id;
        const cachedCategories = cache.readQuery<MerchantCategoriesQuery>({
          query: MerchantCategoriesDocument,
        })?.merchantCategories;

        if (!categoryId || !cachedCategories) return;

        const updateCategory = (category: MerchantCategory) => {
          if (category.children)
            category.children.map((cat) => updateCategory(cat));

          return category.id === categoryId
            ? { ...category, name: name.trim() }
            : category;
        };

        const createCategory = (categories: MerchantCategory[]) => {
          const newCategory: MerchantCategory = {
            __typename: 'MerchantCategory',
            id: categoryId,
            name: name.trim(),
            image: null,
            children: [],
            parent,
          };

          return !parent?.id
            ? [...categories, newCategory]
            : categories.map((category) => {
                if (category.id === parent?.id)
                  return {
                    ...category,
                    children: category.children
                      ? [...category.children, newCategory]
                      : [newCategory],
                  };
                return {
                  ...category,
                  children: category.children?.map((cat) =>
                    cat.id === parent?.id
                      ? {
                          ...cat,
                          children: cat.children
                            ? [...cat.children, newCategory]
                            : [newCategory],
                        }
                      : cat,
                  ),
                };
              });
        };

        const updatedCategories = category.id
          ? cachedCategories.map(updateCategory)
          : createCategory(cachedCategories);

        cache.writeQuery<MerchantCategoriesQuery>({
          query: MerchantCategoriesDocument,
          data: { merchantCategories: updatedCategories },
        });
      },
    });
  };

  const onRemove = async () => {
    if (category.id === '') {
      // remove category that was not saved yet
      onDelete();
    } else {
      // remove category that was previously saved
      try {
        await removeMutationAndCacheUpdate();
      } catch (e) {
        if (e instanceof Error) {
          openNotification(e.message, 'error');
        }
      }
    }
  };

  const removeMutationAndCacheUpdate = async () => {
    await remove({
      variables: {
        id: category.id,
      },
      update: (cache) => {
        const cachedCategories = cache.readQuery<MerchantCategoriesQuery>({
          query: MerchantCategoriesDocument,
        })?.merchantCategories;

        if (!cachedCategories) return;

        const removeCategory = (
          categories: MerchantCategory[],
        ): MerchantCategory[] => {
          return categories
            .filter((c) => category.id !== c.id)
            .map((c) => {
              return c.children
                ? { ...c, children: removeCategory(c.children) }
                : c;
            });
        };

        cache.writeQuery<MerchantCategoriesQuery>({
          query: MerchantCategoriesDocument,
          data: { merchantCategories: removeCategory(cachedCategories) },
        });
      },
    });
    openNotification(
      `${isSubcategory ? 'Subcategory' : 'Category'} successfully removed!`,
      'success',
    );
  };

  const cancel = () => {
    if (category.id === '') {
      onDelete();
      return;
    }
    setEditing(false);
    void setFieldValue('name', name);
  };

  return (
    <>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}>
        <Box className={classes.dialogContainer}>
          <DialogContent style={{ padding: 0 }}>
            <Typography
              className={
                classes.primaryText
              }>{`Are you sure you want to delete the "${name}" ${
              isSubcategory ? 'subcategory' : 'category'
            }?`}</Typography>
            <Typography className={classes.secondaryText}>
              {`All merchants placed under this ${
                isSubcategory ? 'subcategory' : 'category'
              } will be hidden from the app.`}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button large onClick={() => setOpenConfirmationDialog(false)}>
              Cancel
            </Button>
            <Button
              gradient
              large
              onClick={() => {
                void onRemove();
                setOpenConfirmationDialog(false);
              }}
              autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box className={classes.textfieldBox}>
        {editing ? (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              id="name"
              name="name"
              fullWidth
              className={classes.textfield}
              value={name}
              placeholder={isSubcategory ? 'New subcategory' : 'New category'}
              onChange={handleChange('name')}
              data-cy="textfield"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.stopPropagation();
              }}
              inputProps={{
                className: classes.input,
              }}
              label={saveDisabled && 'Please save the parent category first.'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  !saveDisabled && save();
                } else if (e.key === 'Escape') {
                  e.preventDefault();
                  cancel();
                }
              }}
              error={Boolean(errors.name) || saveDisabled}
              helperText={errors.name}
              autoFocus
            />
            {addLoading ? (
              <CircularProgress />
            ) : (
              <IconButton
                title="Confirm edit"
                className={classes.confirmEditIcon}
                disabled={saveDisabled}
                data-cy="confirm"
                type="submit">
                <Check />
              </IconButton>
            )}
          </form>
        ) : (
          <>
            {removeLoading ? (
              <Box className={classes.deleteLoaderBox}>
                <CircularProgress />{' '}
              </Box>
            ) : (
              <>
                <Box className={classes.folderAndName}>
                  <Typography className={classes.itemName}>{name}</Typography>
                </Box>
                <Box>
                  <IconButton
                    title={isSubcategory ? 'Edit subcategory' : 'Edit category'}
                    className={classes.icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditing(true);
                    }}
                    data-cy="edit">
                    <EditOutlined />
                  </IconButton>
                  {onCreate && (
                    <IconButton
                      title="Add subcategory"
                      className={classes.icon}
                      onClick={(e) => {
                        if (expanded) {
                          e.stopPropagation();
                        }
                        onCreate();
                      }}>
                      <AddBoxOutlined data-cy="create" />
                    </IconButton>
                  )}
                  <IconButton
                    title={
                      isSubcategory
                        ? 'Delete subcategory'
                        : 'Delete category and its subcategories'
                    }
                    className={classes.icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenConfirmationDialog(true);
                    }}>
                    <DeleteOutline data-cy={`delete-${name}`} />
                  </IconButton>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CategoryItem;
