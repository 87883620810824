import React, { createContext, useReducer } from 'react';
import {
  ApprovalState,
  MerchantFieldsFragment,
  MerchantSelection,
  DeliveryStatus,
  DeliveryFieldsFragment,
  MerchantCategory,
} from '../generated';

type AppState = {
  merchants: MerchantFieldsFragment[];
  merchantSearchResults: MerchantFieldsFragment[];
  merchantsTotalCount: number;
  merchantsTableRowsPerPage: number;
  merchantSearchInput: string;
  searchInputToBeCleared: boolean;
  merchantsApprovalStateFilters: ApprovalState[];
  merchantSelectionFilters: MerchantSelection[];
  merchantsFilterDrawer: {
    selectedApprovalStates: ApprovalState[];
    selectedSelections: MerchantSelection[];
  };
  loading: boolean;
  deliveries: DeliveryFieldsFragment[];
  deliveriesTotalCount: number;
  deliveriesTableRowsPerPage: number;
  deliveriesStatusFilters: DeliveryStatus[];
  selectedDeliveriesStatuses: DeliveryStatus[];
  merchantCategories: MerchantCategory[];
};

export enum Actions {
  SET_MERCHANTS = 'SET_MERCHANTS',
  SET_MERCHANTS_SEARCH_RESULTS = 'SET_MERCHANTS_SEARCH_RESULTS',
  SET_MERCHANTS_TOTAL_COUNT = 'SET_MERCHANTS_TOTAL_COUNT',
  SET_MERCHANTS_TABLE_ROW_PER_PAGE = 'SET_MERCHANTS_TABLE_ROW_PER_PAGE',
  SET_MERCHANTS_SEARCH_INPUT = 'SET_MERCHANTS_SEARCH_INPUT',
  CLEAR_SEARCH_INPUT = 'CLEAR_SEARCH_INPUT',
  UNDO_ALL_MERCHANTS_FILTERS = 'UNDO_ALL_MERCHANTS_FILTERS',
  SET_SELECTED_MERCHANTS_APPROVAL_STATES = 'SET_SELECTED_MERCHANTS_APPROVAL_STATES',
  SET_MERCHANTS_APPROVAL_STATE_FILTERS = 'SET_MERCHANTS_APPROVAL_STATE_FILTERS',
  SET_SELECTED_MERCHANTS_SELECTIONS = 'SET_SELECTED_MERCHANTS_SELECTIONS',
  SET_MERCHANTS_SELECTION_FILTERS = 'SET_MERCHANTS_SELECTION_FILTERS',
  SET_LOADING = 'SET_LOADING',
  SET_DELIVERIES = 'SET_DELIVERIES',
  SET_DELIVERIES_TOTAL_COUNT = 'SET_DELIVERIES_TOTAL_COUNT',
  SET_DELIVERIES_TABLE_ROW_PER_PAGE = 'SET_DELIVERIES_TABLE_ROW_PER_PAGE',
  SET_SELECTED_DELIVERIES_STATUS = 'SET_SELECTED_DELIVERIES_STATUS',
  SET_DELIVERIES_STATUS_FILTERS = 'SET_DELIVERIES_STATUS_FILTERS',
  UNDO_ALL_DELIVERIES_FILTERS = 'UNDO_ALL_DELIVERIES_FILTERS',
  SET_MERCHANTS_CATEGORIES = 'SET_MERCHANTS_CATEGORIES',
}

type Action =
  | {
      type: Actions.SET_MERCHANTS;
      payload: MerchantFieldsFragment[];
    }
  | {
      type: Actions.SET_MERCHANTS_SEARCH_INPUT;
      payload: string;
    }
  | {
      type: Actions.CLEAR_SEARCH_INPUT;
      payload: boolean;
    }
  | {
      type: Actions.SET_MERCHANTS_TOTAL_COUNT;
      payload: number;
    }
  | {
      type: Actions.SET_MERCHANTS_TABLE_ROW_PER_PAGE;
      payload: number;
    }
  | {
      type: Actions.UNDO_ALL_MERCHANTS_FILTERS;
    }
  | {
      type: Actions.SET_SELECTED_MERCHANTS_APPROVAL_STATES;
      payload: ApprovalState[];
    }
  | {
      type: Actions.SET_MERCHANTS_APPROVAL_STATE_FILTERS;
      payload: ApprovalState[];
    }
  | {
      type: Actions.SET_SELECTED_MERCHANTS_SELECTIONS;
      payload: MerchantSelection[];
    }
  | {
      type: Actions.SET_MERCHANTS_SELECTION_FILTERS;
      payload: MerchantSelection[];
    }
  | {
      type: Actions.SET_LOADING;
      payload: boolean;
    }
  | {
      type: Actions.SET_MERCHANTS_SEARCH_RESULTS;
      payload: MerchantFieldsFragment[];
    }
  | {
      type: Actions.SET_DELIVERIES;
      payload: DeliveryFieldsFragment[];
    }
  | {
      type: Actions.SET_DELIVERIES_TOTAL_COUNT;
      payload: number;
    }
  | {
      type: Actions.SET_DELIVERIES_TABLE_ROW_PER_PAGE;
      payload: number;
    }
  | {
      type: Actions.SET_SELECTED_DELIVERIES_STATUS;
      payload: DeliveryStatus[];
    }
  | {
      type: Actions.SET_DELIVERIES_STATUS_FILTERS;
      payload: DeliveryStatus[];
    }
  | {
      type: Actions.UNDO_ALL_DELIVERIES_FILTERS;
    }
  | {
      type: Actions.SET_MERCHANTS_CATEGORIES;
      payload: MerchantCategory[];
    };

const initialState: AppState = {
  merchants: [],
  merchantsTotalCount: 0,
  merchantsTableRowsPerPage: 20,
  merchantSearchInput: '',
  searchInputToBeCleared: false,
  merchantsApprovalStateFilters: [],
  merchantSelectionFilters: [],
  merchantsFilterDrawer: {
    selectedApprovalStates: [],
    selectedSelections: [],
  },
  loading: false,
  merchantSearchResults: [],
  deliveries: [],
  deliveriesTotalCount: 0,
  deliveriesTableRowsPerPage: 20,
  deliveriesStatusFilters: [],
  selectedDeliveriesStatuses: [],
  merchantCategories: [],
};

const reducer = (state: AppState, action: Action) => {
  const { merchantsFilterDrawer } = state;

  switch (action.type) {
    case Actions.SET_MERCHANTS:
      return { ...state, merchants: action.payload };
    case Actions.SET_MERCHANTS_SEARCH_RESULTS:
      return { ...state, merchantSearchResults: action.payload };
    case Actions.SET_MERCHANTS_TOTAL_COUNT:
      return { ...state, merchantsTotalCount: action.payload };
    case Actions.SET_MERCHANTS_TABLE_ROW_PER_PAGE:
      return { ...state, merchantsTableRowsPerPage: action.payload };
    case Actions.SET_MERCHANTS_SEARCH_INPUT:
      return { ...state, merchantSearchInput: action.payload };
    case Actions.CLEAR_SEARCH_INPUT:
      return { ...state, searchInputToBeCleared: action.payload };
    case Actions.UNDO_ALL_MERCHANTS_FILTERS:
      return {
        ...state,
        merchantsApprovalStateFilters: [],
        merchantSelectionFilters: [],
        merchantsFilterDrawer: {
          selectedApprovalStates: [],
          selectedSelections: [],
        },
      };
    case Actions.SET_SELECTED_MERCHANTS_APPROVAL_STATES:
      return {
        ...state,
        merchantsFilterDrawer: {
          ...merchantsFilterDrawer,
          selectedApprovalStates: action.payload,
        },
      };
    case Actions.SET_MERCHANTS_APPROVAL_STATE_FILTERS:
      return {
        ...state,
        merchantsApprovalStateFilters: action.payload,
      };
    case Actions.SET_SELECTED_MERCHANTS_SELECTIONS:
      return {
        ...state,
        merchantsFilterDrawer: {
          ...merchantsFilterDrawer,
          selectedSelections: action.payload,
        },
      };
    case Actions.SET_MERCHANTS_SELECTION_FILTERS:
      return {
        ...state,
        merchantSelectionFilters: action.payload,
      };
    case Actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_DELIVERIES:
      return { ...state, deliveries: action.payload };
    case Actions.SET_DELIVERIES_TOTAL_COUNT:
      return { ...state, deliveriesTotalCount: action.payload };
    case Actions.SET_DELIVERIES_TABLE_ROW_PER_PAGE:
      return { ...state, deliveriesTableRowsPerPage: action.payload };
    case Actions.SET_DELIVERIES_STATUS_FILTERS:
      return { ...state, deliveriesStatusFilters: action.payload };
    case Actions.SET_SELECTED_DELIVERIES_STATUS:
      return { ...state, selectedDeliveriesStatuses: action.payload };
    case Actions.UNDO_ALL_DELIVERIES_FILTERS:
      return {
        ...state,
        deliveriesStatusFilters: [],
        selectedDeliveriesStatuses: [],
      };
    case Actions.SET_MERCHANTS_CATEGORIES:
      return { ...state, merchantCategories: action.payload };
    default:
      return state;
  }
};

export const AppContext = createContext<{
  state: AppState;
  dispatch: (action: Action) => void;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
