import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CourierFieldsFragment,
  DeliveryFieldsFragment,
  Maybe,
  useCourierDetailsQuery,
  useDeliveriesQuery,
} from '../../generated';
import AvatarWithBadge from './AvatarWithBadge';
import { useNavigate, useParams } from 'react-router-dom';
import DeliveryStatusTypography from '../Deliveries/DeliveryStatus';
import { getFormattedDate } from '../../utils/utils';

interface HeadCell {
  id: 'date' | 'orderBatchId' | 'pickUp' | 'dropOff' | 'status';
  label: string;
  width?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    height: '90vh',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: 0,
  },
  closeButton: {
    height: '40px',
    padding: '0',
    margin: '8px',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.primary.main,
  },
  title: {
    margin: '28px 0 0 40px',
    alignItems: 'center',
  },
  courierName: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
    marginLeft: '10px',
  },
  progressContainer: {
    width: '100%',
    textAlign: 'center',
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
  },
  paperContainer: {
    margin: '14px 25px',
    boxShadow: 'none',
  },
  table: {
    minWidth: 750,
  },
  tableHeaderCell: {
    padding: '16px 16px 16px 0',
    font: 'bold 14px/14px Montserrat',
    color: '#525252',
    '&:first-child': {
      padding: '16px',
    },
  },
}));

const headCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Date',
    width: '10%',
  },
  {
    id: 'orderBatchId',
    label: 'Order Batch ID',
    width: '10%',
  },
  {
    id: 'pickUp',
    label: 'Picked up from',
    width: '20%',
  },
  {
    id: 'dropOff',
    label: 'Dropped off at',
    width: '20%',
  },
  {
    id: 'status',
    label: 'Delivery Status',
    width: '10%',
  },
];

const TableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
}))(MUITableRow);

const TableCell = withStyles(() => ({
  root: {
    font: 'normal 14px/14px Montserrat',
    padding: '16px 16px 16px 0',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    '&:first-child': {
      padding: '16px',
    },
  },
}))(MUITableCell);

const DeliveriesHistory: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [courier, setCourier] = useState<Maybe<CourierFieldsFragment>>();
  const [deliveries, setDeliveries] = useState<
    DeliveryFieldsFragment[] | undefined
  >([]);
  const [loading, setLoading] = useState(false);

  const { data, loading: courierLoading } = useCourierDetailsQuery({
    variables: { id: params.courierId || '' },
  });

  const {
    data: deliveriesData,
    loading: deliveriesLoading,
  } = useDeliveriesQuery({
    variables: {
      input: {
        page: 1,
        pageSize: 300,
        courierIds: [params.courierId || ''],
      },
    },
  });

  useEffect(() => {
    setCourier(data?.courier);
    setLoading(courierLoading);
  }, [data?.courier, courierLoading]);

  useEffect(() => {
    setDeliveries(deliveriesData?.deliveries?.deliveries);
    setLoading(deliveriesLoading);
  }, [deliveriesData, deliveriesLoading]);

  return (
    <Dialog
      open
      onClose={() => navigate(-1)}
      classes={{ paper: classes.dialog }}>
      <Box className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" className={classes.title}>
              <AvatarWithBadge
                online={courier?.online || false}
                avatar={courier?.avatar?.small}
              />
              <Typography className={classes.courierName}>
                {courier?.user?.name} — Deliveries History
              </Typography>
            </Box>
            <IconButton
              className={classes.closeButton}
              onClick={() => navigate(-1)}>
              <Close className={classes.icon} />
            </IconButton>
          </Box>
        </DialogTitle>
        {loading && (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        )}
        <DialogContent className={classes.dialogContent}>
          {!loading && !!deliveries && (
            <>
              <TableContainer
                component={Paper}
                className={classes.paperContainer}>
                <Table className={classes.table} size="medium">
                  {deliveries.length === 0 && (
                    <caption>There are no deliveries to display</caption>
                  )}
                  <TableHead>
                    <MUITableRow>
                      {headCells.map((headCell) => (
                        <MUITableCell
                          width={headCell.width}
                          key={headCell.id}
                          classes={{ root: classes.tableHeaderCell }}>
                          <Box>{headCell.label}</Box>
                        </MUITableCell>
                      ))}
                    </MUITableRow>
                  </TableHead>
                  <TableBody>
                    {deliveries.map((delivery: DeliveryFieldsFragment) => {
                      if (!delivery?.id) return null;
                      return (
                        <TableRow key={delivery.id}>
                          <TableCell width="10%">
                            <Box display="flex" alignItems="center">
                              {getFormattedDate(
                                delivery.orderBatch.payment?.date,
                              )}
                            </Box>
                          </TableCell>
                          <TableCell width="10%">
                            <Box display="flex" alignItems="center">
                              {delivery.orderBatch?.referenceNumber}
                            </Box>
                          </TableCell>
                          <TableCell width="20%">
                            <Box display="flex" alignItems="center">
                              {delivery.pickUp.location.formattedAddress}
                            </Box>
                          </TableCell>
                          <TableCell width="20%">
                            <Box display="flex" alignItems="center">
                              {delivery.dropOff.location.formattedAddress}
                            </Box>
                          </TableCell>
                          <TableCell width="10%">
                            <DeliveryStatusTypography
                              status={delivery?.status}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeliveriesHistory;
