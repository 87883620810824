import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { DeliveryStatus } from '../../generated';

interface DeliveryStatusProps {
  status: DeliveryStatus;
}

const useStyles = makeStyles(() => ({
  statusUnassigned: {
    color: '#DC1F1F',
    font: 'bold 14px/20px Montserrat',
  },
  statusDefault: {
    color: '#525252',
    font: 'bold 14px/20px Montserrat',
  },
  statusInProgress: {
    color: '#57A200',
    font: 'bold 14px/20px Montserrat',
  },
}));

const DeliveryStatusTypography: React.FC<DeliveryStatusProps> = ({
  status,
}) => {
  const classes = useStyles();

  const deliveryStatuses = useMemo(() => {
    return {
      UNASSIGNED: {
        label: 'Unassigned',
        style: classes.statusUnassigned,
      },
      COURIER_CONFIRMATION_PENDING: {
        label: 'Request sent',
        style: classes.statusDefault,
      },
      PICK_UP: {
        label: 'Courier assigned',
        style: classes.statusDefault,
      },
      DROP_OFF: {
        label: 'In progress',
        style: classes.statusInProgress,
      },
      CANCELLED: {
        label: 'Cancelled',
        style: classes.statusDefault,
      },
      DONE: { label: 'Done', style: classes.statusDefault },
    };
  }, [
    classes.statusUnassigned,
    classes.statusDefault,
    classes.statusInProgress,
  ]);

  return (
    <Typography
      style={{ whiteSpace: 'nowrap' }}
      className={deliveryStatuses[status].style}>
      {deliveryStatuses[status].label}
    </Typography>
  );
};

export default DeliveryStatusTypography;
