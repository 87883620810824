import {
  ActorFieldsFragment,
  MeFieldsFragment,
  UserFieldsFragment,
} from '../../generated';

export const isAdmin = (
  fragment?: ActorFieldsFragment | null,
): fragment is MeFieldsFragment => fragment?.__typename === 'Admin';

export const isUser = (
  fragment?: UserFieldsFragment | null,
): fragment is UserFieldsFragment => fragment?.__typename === 'User';
