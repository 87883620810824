import React, { useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  ClickAwayListener,
  Divider,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import {
  AddressFieldsFragment,
  CourierFieldsFragment,
  CouriersNearDeliveryQuery,
} from '../../generated';
import { ReactComponent as PickUpIcon } from '../../icons/pickup-icon.svg';
import { ReactComponent as CourierLocationIcon } from '../../icons/courier-location-icon.svg';
import Button from '../../components/common/Button';
import { googleMapsKey } from '../../utils/constants';

interface CouriersMapProps {
  couriers:
    | CouriersNearDeliveryQuery['couriersNearDelivery']
    | CourierFieldsFragment[];
  pickUpPoint: AddressFieldsFragment;
  assignDelivery: (courierId: string) => Promise<void>;
}

const useStyles = makeStyles(() => ({
  mapContainer: {
    height: 'calc(90vh - 195px)',
    width: '100%',
  },
  divider: {
    marginBottom: '10px',
    height: '2px',
    color: '#E4E4E4',
  },
  pickupIcon: {
    height: '45px',
    color: '#FE6B01',
    cursor: 'pointer',
  },
  courierIcon: {
    height: '45px',
    color: '#57A200',
  },
  assignButton: {
    float: 'right',
    marginTop: '20px',
  },
  tooltip: {
    width: 'max-content',
    maxWidth: '220px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000019',
    border: '1px solid #C1C1C1',
    borderRadius: '7px',
    color: '#525252',
    font: 'bold 12px/30px Montserrat',
  },
}));

const PickUpPoint: React.FC<{
  lat: number;
  lng: number;
}> = () => {
  const classes = useStyles();
  return <PickUpIcon className={classes.pickupIcon} />;
};

const CourierPoint: React.FC<{
  lat: number;
  lng: number;
  tooltipText: string;
}> = ({ tooltipText }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement="top"
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipText}
          classes={{
            tooltip: classes.tooltip,
          }}>
          <CourierLocationIcon
            onClick={() => setOpen(true)}
            className={classes.courierIcon}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const CouriersMap: React.FC<CouriersMapProps> = ({
  pickUpPoint,
  assignDelivery,
  couriers,
}) => {
  const classes = useStyles();

  const [selectedCourier, setSelectedCourier] = useState('');

  const mapCenterCoord = useMemo(() => {
    return {
      lat: pickUpPoint?.location?.coordinates?.lat,
      lng: pickUpPoint?.location?.coordinates?.long,
    };
  }, [
    pickUpPoint?.location?.coordinates?.lat,
    pickUpPoint?.location?.coordinates?.long,
  ]);
  return (
    <>
      <Divider className={classes.divider} />
      <div className={classes.mapContainer}>
        {googleMapsKey && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsKey }}
            center={mapCenterCoord}
            zoom={15}
            onChildClick={(key) => {
              key && setSelectedCourier(key);
            }}>
            <PickUpPoint
              lat={pickUpPoint?.location?.coordinates?.lat}
              lng={pickUpPoint?.location?.coordinates?.long}
            />
            {couriers.map((data) => {
              const courier =
                'distanceToPickUpInMeters' in data ? data.courier : data;
              return (
                courier?.currentPosition?.lat &&
                courier?.currentPosition?.long && (
                  <CourierPoint
                    key={courier?.id}
                    lat={courier?.currentPosition.lat}
                    lng={courier?.currentPosition.long}
                    tooltipText={courier?.user?.name || ''}
                  />
                )
              );
            })}
          </GoogleMapReact>
        )}
      </div>
      {selectedCourier && (
        <Button
          size="small"
          classes={{ root: classes.assignButton }}
          onClick={() => assignDelivery(selectedCourier)}>
          Assign an order
        </Button>
      )}
    </>
  );
};

export default CouriersMap;
