import React, { useState, useEffect } from 'react';
import { makeStyles, Tabs, Tab, Divider, Box, Avatar } from '@material-ui/core';
import { MerchantDetailsFieldsFragment, Product, Maybe } from '../../generated';
import { InsertPhoto } from '@material-ui/icons';
import ProductView from './ProductView';
import {
  defaultVariant as findDefaultVariant,
  formatMoney,
} from '../../utils/utils';

interface MerchantProductsProps {
  merchant: Maybe<MerchantDetailsFieldsFragment>;
}

const useStyles = makeStyles(() => ({
  infoTitle: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
    height: '20px',
    margin: '0 0 10px 40px',
  },
  tabButton: {
    minHeight: '24px',
    padding: 0,
    minWidth: 'auto',
    margin: '0 30px 3px 0',
  },
  tabsContainer: {
    minHeight: 'auto',
  },
  divider: {
    margin: '10px 40px 3px 40px',
  },
  tabLabel: {
    fontWeight: 'bold',
  },
  productsContainer: {
    display: 'flex',
    marginTop: '30px',
    flexWrap: 'wrap',
  },
  product: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px 40px',
    width: '150px',
  },
  image: {
    width: '150px',
    height: '150px',
    borderRadius: '5px',
    backgroundSize: 'contain',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  productTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '10px 0 0 15px',
  },
  productPrice: {
    fontWeight: 'bold',
    marginLeft: '15px',
    color: '#DC1F5C',
  },
  imagePlaceholder: {
    width: '150px',
    height: '150px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const MerchantProducts: React.FC<MerchantProductsProps> = ({ merchant }) => {
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = useState(0);
  const [products, setProducts] = useState<Maybe<Product>[]>([]);
  const [showSingleProduct, setShowSingleProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Maybe<Product>>();

  useEffect(() => {
    if (!merchant) return;

    const currentSubcategoryId =
      merchant.productCategories?.[selectedCategory]?.children?.[
        selectedSubcategory
      ]?.id;
    setProducts(
      merchant.products?.items?.filter((product) => {
        return product?.categories?.find(
          (cat) => cat?.id === currentSubcategoryId,
        );
      }) as Product[],
    );
  }, [merchant, selectedCategory, selectedSubcategory]);

  return (
    <>
      <span className={classes.infoTitle}>Products</span>
      {!!merchant && !showSingleProduct && (
        <>
          <Box>
            <Tabs
              value={selectedCategory}
              variant="scrollable"
              scrollButtons="on"
              onChange={(event, newValue) => {
                setSelectedCategory(newValue);
                setSelectedSubcategory(0);
              }}
              indicatorColor="primary"
              textColor="primary"
              classes={{
                root: classes.tabsContainer,
              }}>
              {merchant.productCategories?.map((category) => {
                return (
                  <Tab
                    label={category?.name}
                    key={category?.id}
                    classes={{
                      root: classes.tabButton,
                      selected: classes.tabLabel,
                    }}
                  />
                );
              })}
            </Tabs>
            <Divider className={classes.divider} />
            <Tabs
              value={selectedSubcategory}
              variant="scrollable"
              scrollButtons="on"
              onChange={(event, newValue) => setSelectedSubcategory(newValue)}
              indicatorColor="primary"
              textColor="primary"
              classes={{
                root: classes.tabsContainer,
              }}>
              {merchant.productCategories?.[selectedCategory]?.children?.map(
                (subcategory) => {
                  return (
                    <Tab
                      label={subcategory?.name}
                      key={subcategory?.id}
                      classes={{
                        root: classes.tabButton,
                        selected: classes.tabLabel,
                      }}
                    />
                  );
                },
              )}
            </Tabs>
          </Box>
          <Box className={classes.productsContainer}>
            {products.map((product) => {
              const defaultVariant = findDefaultVariant(product?.variants);
              return (
                <Box key={product?.id} className={classes.product}>
                  {defaultVariant?.images?.[0]?.small ? (
                    <Avatar
                      variant="square"
                      className={classes.image}
                      onClick={() => {
                        setShowSingleProduct(true);
                        setSelectedProduct(product);
                      }}
                      src={defaultVariant?.images?.[0]?.small}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      className={classes.imagePlaceholder}
                      onClick={() => {
                        setShowSingleProduct(true);
                        setSelectedProduct(product);
                      }}>
                      <InsertPhoto />
                    </Avatar>
                  )}
                  <span className={classes.productTitle}>{product?.title}</span>
                  {defaultVariant?.price?.amountInCents && (
                    <span className={classes.productPrice}>
                      {defaultVariant?.price?.currencyCode}{' '}
                      {formatMoney(defaultVariant?.price)}
                    </span>
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      )}
      {showSingleProduct && selectedProduct && (
        <ProductView
          product={selectedProduct}
          returnToMerchantsProducts={setShowSingleProduct}
        />
      )}
    </>
  );
};

export default MerchantProducts;
