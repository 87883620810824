import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import { DeliveryFieldsFragment, DeliveryStatus } from '../../generated';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import { ReactComponent as FilterIconSelected } from '../../icons/filter-icon-selected.svg';
import { Order } from '../../utils/types';
import { SortableFields } from './DeliveriesTable';

interface EnhancedTableProps {
  order: Order;
  orderBy: SortableFields;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: SortableFields,
  ) => void;
  onOpenFilterDrawer: (open: boolean) => void;
  selectedStatuses: DeliveryStatus[];
}

interface HeadCell {
  id: keyof DeliveryFieldsFragment | 'details' | 'filter';
  label: ReactNode;
  width?: string;
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
  tableHeaderCell: {
    padding: '16px 16px 16px 0',
    font: 'bold 14px/14px Montserrat',
    color: '#525252',
    '&:first-child': {
      padding: '16px',
    },
  },
  filterIcon: {
    width: '20px',
  },
  filterButton: {
    padding: '0 4px',
  },
  activeSortIcon: {
    opacity: 1,
  },
  inactiveSortIcon: {
    opacity: 0.3,
  },
}));

const sortableFields = ['status'];

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
  onOpenFilterDrawer,
  selectedStatuses,
}) => {
  const classes = useStyles();

  const headCells: HeadCell[] = useMemo(() => {
    return [
      {
        id: 'id',
        label: 'Delivery ID',
        width: '17%',
      },
      { id: 'orderBatch', label: 'Batch Number', width: '17%' },
      {
        id: 'pickUp',
        label: 'Pick Up Address',
        width: '20%',
      },
      { id: 'dropOff', label: 'Drop Off Address', width: '20%' },
      { id: 'status', label: 'Status', width: '10%' },
      { id: 'details', label: 'Details', width: '150px' },
      {
        id: 'filter',
        label: (
          <IconButton
            className={classes.filterButton}
            onClick={() => onOpenFilterDrawer(true)}>
            {selectedStatuses.length > 0 ? (
              <FilterIconSelected className={classes.filterIcon} />
            ) : (
              <FilterIcon className={classes.filterIcon} />
            )}
          </IconButton>
        ),
        width: '50px',
      },
    ];
  }, [
    onOpenFilterDrawer,
    selectedStatuses.length,
    classes.filterButton,
    classes.filterIcon,
  ]);

  const createSortHandler = useCallback(
    (property: SortableFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow classes={{ root: classes.tableHeader }}>
        {headCells.map((headCell) => (
          <TableCell
            width={headCell.width}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.tableHeaderCell }}>
            {sortableFields.includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                classes={{
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon,
                }}
                onClick={createSortHandler(headCell.id as SortableFields)}>
                <Box>{headCell.label}</Box>
              </TableSortLabel>
            ) : (
              <Box>{headCell.label}</Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
