import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { useSearchLazyQuery } from '../generated';
import {
  makeStyles,
  InputAdornment,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { debounce } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import { Actions, AppContext } from '../store/index';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../App';

const useStyles = makeStyles(() => ({
  searchInput: {
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '25px',
    opacity: '0.85',
    padding: '0 14px',
  },
  searchContainer: {
    width: '20%',
  },
  inputIcon: {
    marginRight: '10px',
  },
}));

const SearchInput: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppContext);
  const { page } = useParams<{ page: string }>();

  const [input, setInput] = useState('');
  const [debounceIsActive, setDebounceIsActive] = useState(false);

  const [search, { data, loading }] = useSearchLazyQuery({
    variables: {
      searchQuery: {
        text: input,
        page: page ? parseFloat(page) : 1,
        pageSize: state.merchantsTableRowsPerPage,
      },
    },
  });

  const searchHandler = useCallback(
    (value: string) => {
      setDebounceIsActive(false);
      dispatch({
        type: Actions.SET_MERCHANTS_SEARCH_INPUT,
        payload: value,
      });
      if (value === '') {
        dispatch({
          type: Actions.CLEAR_SEARCH_INPUT,
          payload: true,
        });
        navigate(
          `${routes.merchants}?${createSearchParams({ page: '1' }).toString()}`,
        );
        return;
      }
      dispatch({
        type: Actions.UNDO_ALL_MERCHANTS_FILTERS,
      });
      search();
    },
    [search, dispatch, navigate],
  );

  const debouncedSearchHandler = useMemo(() => debounce(searchHandler, 1000), [
    searchHandler,
  ]);

  useEffect(() => {
    if (!data) return;
    dispatch({
      type: Actions.SET_MERCHANTS_SEARCH_RESULTS,
      payload: data?.search?.merchants || [],
    });
    dispatch({
      type: Actions.SET_MERCHANTS_TOTAL_COUNT,
      payload: data?.search?.totalCount || 0,
    });
  }, [data, dispatch]);

  useEffect(() => {
    input.length && search();
  }, [input.length, page, search]);

  useEffect(() => {
    setInput(state.merchantSearchInput);
  }, [state.merchantSearchInput]);

  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel();
    };
  }, [debouncedSearchHandler]);

  const setValue = useCallback(
    (value: string) => {
      if (input === '') {
        navigate(
          `${routes.merchants}?${createSearchParams({ page: '1' }).toString()}`,
        );
      }
      setInput(value);
      setDebounceIsActive(true);
      debouncedSearchHandler(value);
    },
    [navigate, input, debouncedSearchHandler],
  );

  return (
    <TextField
      variant="outlined"
      className={classes.searchContainer}
      InputProps={{
        className: classes.searchInput,
        endAdornment: (
          <InputAdornment position="end" className={classes.inputIcon}>
            {(loading || debounceIsActive) && input.length !== 0 ? (
              <CircularProgress color={'inherit'} size={'24px'} />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      value={input}
    />
  );
};
export default SearchInput;
