import React, { useState } from 'react';
import {
  makeStyles,
  withStyles,
  ClickAwayListener,
  Tooltip as MUITooltip,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as MessageIcon } from '../../icons/message-icon.svg';

interface CommentTooltipProps {
  message: string;
}

const useStyles = makeStyles(() => ({
  commentIcon: {
    width: '25px',
  },
}));

const Tooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ffffff',
    border: '1px solid #fe6b01',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0px 10px #00000019',
    fontSize: '14px',
    margin: '5px 0',
    padding: '15px 20px',
  },
}))(MUITooltip);

const CommentTooltip: React.FC<CommentTooltipProps> = ({ message }) => {
  const classes = useStyles();
  const [openTooltip, setTooltipOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement="top"
          onClose={() => setTooltipOpen(false)}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={message}>
          <IconButton onClick={() => setTooltipOpen(true)}>
            <MessageIcon className={classes.commentIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CommentTooltip;
