import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Slider,
  Theme,
  Typography,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import {
  useCouriersQuery,
  DeliveryFieldsFragment,
  useCouriersNearDeliveryQuery,
  useSendDeliveryRequestMutation,
  DeliveriesQuery,
} from '../../generated';
import { Close, Refresh } from '@material-ui/icons';
import CouriersTable from './CouriersTable';
import CouriersMap from './CouriersMap';
import { ReactComponent as LocationIcon } from '../../icons/location-icon.svg';
import { useNotification } from '../../utils/notification';
import { ApolloQueryResult } from '@apollo/client';

interface CourierSelectionProps {
  open: boolean;
  delivery: DeliveryFieldsFragment;
  onCancel: () => void;
  refetchDeliveries: () => Promise<ApolloQueryResult<DeliveriesQuery>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    height: '90vh',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: '10px 40px 40px 40px',
  },
  closeButton: {
    height: '40px',
    padding: '0',
    margin: '8px',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.primary.main,
  },
  couriersDialogButton: {
    padding: '4px 20px',
    margin: '28px 20px 0 0',
  },
  title: {
    margin: '28px 0 0 40px',
  },
  deliveryId: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
  },
  deliveryLocation: {
    font: 'normal 14px/20px Montserrat',
    color: '#DC1F5C',
    display: 'flex',
    alignItems: 'center',
  },
  deliveryIcon: {
    marginRight: '6px',
    height: '17px',
    width: '17px',
  },
  divider: {
    margin: '15px 40px',
    height: '2px',
  },
  markLabel: {
    '&:last-child': {
      fontSize: '26px',
      lineHeight: '19px',
      fontWeight: '300',
    },
  },
  progressContainer: {
    width: '100%',
    textAlign: 'center',
  },
  slider: {
    width: '300px',
    marginLeft: '25px',
  },
  sliderContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  sliderLabel: {
    fontSize: '14px',
    lineHeight: '29px',
  },
}));

const CourierSelection: React.FC<CourierSelectionProps> = ({
  open,
  delivery,
  onCancel,
  refetchDeliveries,
}) => {
  const classes = useStyles();
  const { openNotification } = useNotification();

  const [content, setContent] = useState('table');
  const [distance, setDistance] = useState(5000);
  const [showAllCouriers, setShowAllCouriers] = useState(false);

  const [sendDeliveryRequest] = useSendDeliveryRequestMutation();

  const { data, loading, refetch } = useCouriersNearDeliveryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      deliveryId: delivery?.id,
      radiusInMeters: distance,
    },
  });

  const { data: allCouriers, loading: allCouriersLoading } = useCouriersQuery({
    variables: {
      input: {
        page: 1,
        pageSize: 300,
        online: true,
      },
    },
  });

  const closeWindow = useCallback(() => {
    onCancel();
    setContent('table');
  }, [onCancel, setContent]);

  const assignDelivery = useCallback(
    async (courierId: string) => {
      try {
        await sendDeliveryRequest({
          variables: {
            input: {
              courierId,
              deliveryId: delivery?.id,
            },
          },
        });
        openNotification('Delivery request was sent successfully!', 'success');
        refetchDeliveries();
        closeWindow();
      } catch (error) {
        if (error instanceof Error) {
          openNotification(error.message, 'error');
        }
      }
    },
    [
      delivery?.id,
      sendDeliveryRequest,
      openNotification,
      refetchDeliveries,
      closeWindow,
    ],
  );

  const marks = useMemo(() => {
    return {
      1: '1 km',
      5: '5 km',
      10: '10 km',
      20: '20 km',
      30: '∞',
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={closeWindow}
      classes={{ paper: classes.dialog }}>
      <Box className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.title}>
              <Typography className={classes.deliveryId}>
                Assign delivery #{delivery?.id}
              </Typography>
              <Typography className={classes.deliveryLocation}>
                <LocationIcon className={classes.deliveryIcon} />
                {delivery?.pickUp?.location?.formattedAddress}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="small"
                classes={{ root: classes.couriersDialogButton }}
                startIcon={<Refresh />}
                onClick={() => refetch()}>
                Refresh couriers
              </Button>
              <Button
                size="small"
                classes={{ root: classes.couriersDialogButton }}
                onClick={() =>
                  setContent(content === 'table' ? 'map' : 'table')
                }>
                {content === 'table'
                  ? 'View couriers on a map'
                  : 'Couriers list'}
              </Button>
              <IconButton className={classes.closeButton} onClick={closeWindow}>
                <Close className={classes.icon} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider className={classes.divider} />
        <Box className={classes.sliderContainer}>
          <Typography className={classes.sliderLabel}>
            Distance to delivery&apos;s pick-up point:
          </Typography>
          <Box className={classes.slider}>
            <Slider
              defaultValue={5}
              valueLabelFormat={(value) => {
                const typedValue = value as keyof typeof marks;

                return marks[typedValue].split(' km')[0];
              }}
              classes={{
                markLabel: classes.markLabel,
              }}
              aria-labelledby="discrete-slider-restrict"
              step={null}
              valueLabelDisplay="auto"
              marks={Object.entries(marks).map(([value, label]) => {
                return {
                  value: parseInt(value),
                  label,
                };
              })}
              min={1}
              max={30}
              onChangeCommitted={(e, value) => {
                if (!Array.isArray(value) && value !== 30) {
                  setShowAllCouriers(false);
                  setDistance(value * 1000);
                }
                if (value === 30) setShowAllCouriers(true);
              }}
            />
          </Box>
        </Box>
        {loading || allCouriersLoading ? (
          <DialogContent className={classes.dialogContent}>
            <div className={classes.progressContainer}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <DialogContent className={classes.dialogContent}>
            {content === 'table' ? (
              <CouriersTable
                couriers={
                  (showAllCouriers
                    ? allCouriers?.couriers?.couriers
                    : data?.couriersNearDelivery) || []
                }
                assignDelivery={assignDelivery}
              />
            ) : (
              <CouriersMap
                couriers={
                  (showAllCouriers
                    ? allCouriers?.couriers?.couriers
                    : data?.couriersNearDelivery) || []
                }
                pickUpPoint={delivery.pickUp}
                assignDelivery={assignDelivery}
              />
            )}
          </DialogContent>
        )}
      </Box>
    </Dialog>
  );
};

export default CourierSelection;
