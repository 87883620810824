import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead';
import TableBody from './TableBody';
import {
  CustomerFieldsFragment,
  Maybe,
  useChangeCustomerWalletStateMutation,
  useCustomersLazyQuery,
  WalletActivationStatus,
} from '../../generated';
import { Actions, AppContext } from '../../store/index';
import { customerWallet } from '../../utils/utils';
import { Order } from '../../utils/types';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { routes } from '../../App';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
    border: '1px solid #E4E4E4',
  },
  paperContainer: {
    margin: '14px 25px',
    boxShadow: 'none',
    width: 'auto',
  },
}));

export type SortableFields = 'name' | 'createdAt';

const Customers: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { dispatch } = useContext(AppContext);

  const [customers, setCustomers] = useState<CustomerFieldsFragment[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<SortableFields>('createdAt');

  const page = useMemo(() => searchParams.get('page'), [searchParams]);

  const [getCustomers, { loading, data }] = useCustomersLazyQuery({
    variables: {
      input: {
        page: page ? parseFloat(page) : 1,
        pageSize: rowsPerPage,
      },
    },
  });

  const [changeCustomerWalletState] = useChangeCustomerWalletStateMutation();

  useEffect(() => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: loading,
    });
  }, [loading, dispatch]);

  useEffect(() => {
    getCustomers();
    setCustomers(data?.customers?.customers || []);
  }, [data?.customers?.customers, page, rowsPerPage, getCustomers]);

  const updateCustomerWalletStatus = useCallback<{
    (id?: Maybe<string>, state?: WalletActivationStatus): Promise<void>;
  }>(
    async (id, state) => {
      if (!id) return;

      const { data } = await changeCustomerWalletState({
        variables: {
          id,
          state,
        },
      });

      setCustomers(
        customers.map((customer) => {
          if (customer?.id !== id) return customer;
          const updatedWallet = {
            ...customerWallet(customer),
            activation: data?.changeCustomerWalletState,
          };
          return { ...customer, wallets: [updatedWallet] };
        }),
      );
    },
    [customers, changeCustomerWalletState],
  );

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: SortableFields) => {
      setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, order, orderBy],
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      navigate(
        `${routes.customers}?${createSearchParams({
          page: (newPage + 1).toString(),
        }).toString()}`,
      );
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value));
      navigate(
        `${routes.customers}?${createSearchParams({ page: '1' }).toString()}`,
      );
    },
    [setRowsPerPage, navigate],
  );

  return (
    <>
      <TableContainer component={Paper} className={classes.paperContainer}>
        <Table className={classes.table} size="medium">
          {customers.length === 0 && (
            <caption>There are no customers to display</caption>
          )}
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {data?.customers?.totalCount !== 0 && (
            <>
              <TableBody
                data={customers || []}
                order={order}
                orderBy={orderBy}
                updateCustomerWalletStatus={updateCustomerWalletStatus}
              />
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    colSpan={7}
                    count={data?.customers?.totalCount || 0}
                    rowsPerPage={rowsPerPage}
                    page={page ? parseFloat(page) - 1 : 0}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default Customers;
