import React, { useCallback, useMemo } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { CourierFieldsFragment, UserFieldsFragment } from '../../generated';
import { Order, SortableFields } from './CouriersTable';

const useStyles = makeStyles(() =>
  createStyles({
    tableHeader: {
      background: '#F5F5F5 0% 0% no-repeat padding-box',
    },
    tableHeaderCell: {
      padding: '16px 16px 16px 0',
      font: 'bold 14px/14px Montserrat',
      color: '#525252',
      '&:first-child': {
        padding: '16px',
      },
    },
    activeSortIcon: {
      opacity: 1,
    },
    inactiveSortIcon: {
      opacity: 0.3,
    },
  }),
);

export type HeadCellId =
  | keyof CourierFieldsFragment
  | keyof UserFieldsFragment
  | 'online'
  | 'activity'
  | 'deliveries';

interface EnhancedTableProps {
  order: Order;
  orderBy: SortableFields;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: SortableFields,
  ) => void;
}

interface HeadCell {
  id: HeadCellId;
  label: string;
  width?: string;
}

const sortableFields = ['name', 'online'];

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const classes = useStyles();

  const headCells: HeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        label: 'Courier Name',
        width: '20%',
      },
      {
        id: 'phone',
        label: 'Phone',
        width: '10%',
      },
      {
        id: 'online',
        label: 'Status',
        width: '10%',
      },
      // {
      //   id: 'activity',
      //   label: 'Activity',
      //   width: '10%',
      // },
      {
        id: 'deliveries',
        label: 'Deliveries history',
        width: '10%',
      },
    ];
  }, []);

  const createSortHandler = useCallback(
    (property: SortableFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow classes={{ root: classes.tableHeader }}>
        {headCells.map((headCell) => (
          <TableCell
            width={headCell.width}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.tableHeaderCell }}>
            {sortableFields.includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                classes={{
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon,
                }}
                onClick={createSortHandler(headCell.id as SortableFields)}>
                <Box>{headCell.label}</Box>
              </TableSortLabel>
            ) : (
              <Box>{headCell.label}</Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
