import React, { useMemo, ReactNode, useState, useCallback } from 'react';
import {
  Popover,
  makeStyles,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { MerchantSelectionLabels } from './FilterDrawer';
import Button from '../../components/common/Button';
import {
  Maybe,
  MerchantFieldsFragment,
  MerchantSelection,
} from '../../generated';

interface SelectionPopoverProps {
  merchant: MerchantFieldsFragment;
  updateMerchant: (id?: Maybe<string>, selection?: MerchantSelection[]) => void;
}

export enum MerchantSelectionAssignedByAdmin {
  Featured = 'FEATURED',
  Popular = 'POPULAR',
  AllowsDirectPayments = 'ALLOWS_DIRECT_PAYMENTS',
}

const useStyles = makeStyles(() => ({
  selectionPopoverContainer: {
    width: '330px',
    border: '1px solid #DBDBDB',
    boxShadow: '0px 0px 10px #00000019',
    borderRadius: '8px',
  },
  selectionCheckboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
  },
  selectionCheckboxes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  checkboxLabel: {
    font: 'normal 14px/14px Montserrat',
    width: '100px',
  },
  saveButton: {
    minWidth: '64px',
    height: '30px',
    marginTop: '10px',
  },
  selectionChip: {
    margin: '0 5px 5px 0',
  },
  selectionContainer: {
    display: 'inline',
  },
}));

const SelectionPopover: React.FC<SelectionPopoverProps> = ({
  merchant,
  updateMerchant,
}) => {
  const classes = useStyles();

  const defaultSelections = useMemo(() => {
    return (Object.keys(MerchantSelectionLabels) as MerchantSelection[]).reduce(
      (acc, key) => {
        return { ...acc, [key]: false };
      },
      {} as Record<MerchantSelection, boolean>,
    );
  }, []);

  const currentSelections = useMemo(() => {
    return (Object.keys(MerchantSelectionLabels) as MerchantSelection[]).reduce(
      (acc, key) => {
        return merchant?.selection
          ? { ...acc, [key]: merchant?.selection?.includes(key) }
          : defaultSelections;
      },
      {} as Record<MerchantSelection, boolean>,
    );
  }, [merchant?.selection, defaultSelections]);

  const [anchorEl, setAnchorEl] = useState<ReactNode | null>(null);
  const [selectionState, setSelectionState] = useState<
    Record<MerchantSelection, boolean>
  >(currentSelections);

  const handleSelectionPopoverClose = useCallback(() => {
    setAnchorEl(null);
    setSelectionState(currentSelections);
  }, [currentSelections]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectionState({
        ...selectionState,
        [event.target.name]: event.target.checked,
      });
    },
    [setSelectionState, selectionState],
  );

  return (
    <div className={classes.selectionContainer}>
      <Chip
        variant="outlined"
        size="small"
        label="+"
        className={classes.selectionChip}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setSelectionState(currentSelections);
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl as Element}
        onClose={handleSelectionPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.selectionPopoverContainer,
        }}>
        <Box className={classes.selectionCheckboxesContainer}>
          <FormGroup className={classes.selectionCheckboxes}>
            {(Object.keys(
              MerchantSelectionLabels,
            ) as MerchantSelectionAssignedByAdmin[]).map((key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={selectionState[key]}
                      onChange={handleChange}
                      name={key}
                      color="primary"
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  label={MerchantSelectionLabels[key]}
                />
              );
            })}
          </FormGroup>
          <Button
            gradient
            autoFocus
            size="small"
            classes={{ root: classes.saveButton }}
            onClick={() => {
              updateMerchant(
                merchant?.id,
                (Object.keys(selectionState) as MerchantSelection[]).filter(
                  (selection) => selectionState[selection],
                ),
              );
              handleSelectionPopoverClose();
            }}>
            Save
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

export default SelectionPopover;
