import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

export interface RESTAuthResponse {
  data: {
    access_token: string;
    refresh_token: string;
    user: {
      email?: string;
      handle?: string;
      id: string;
      name?: string;
      phone?: string;
    };
  };
}

interface LoginRequest {
  identity: string;
  password: string;
}

interface LoginResponse {
  data?: RESTAuthResponse['data'];
  error?: Error;
}

export default {
  login: async ({
    identity,
    password,
  }: LoginRequest): Promise<LoginResponse> => {
    const response = await axios.post<RESTAuthResponse>(`${backendUrl}/login`, {
      login: {
        app: 'admin',
        identity_type: 'email',
        identity,
        password,
      },
    });

    if (response.status === 200) {
      const res = response.data;
      return { data: res.data };
    } else {
      return {
        error: new Error(response.statusText),
      };
    }
  },
};
