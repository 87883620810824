import { Avatar, Badge, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { InsertPhoto } from '@material-ui/icons';
import { Maybe } from '../../generated';

interface AvatarWithBadgeProps {
  online: boolean;
  avatar?: Maybe<string>;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: '10px',
    minWidth: '10px',
    borderRadius: '5px',
    right: '11px',
    bottom: '4px',
  },
  greenBadge: {
    backgroundColor: '#44b700',
    color: '#44b700',
  },
  redBadge: {
    backgroundColor: '#FF0000',
    color: '#FF0000',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: '10px',
    backgroundSize: 'contain',
    marginRight: '10px',
  },
}));

const AvatarWithBadge: React.FC<AvatarWithBadgeProps> = ({
  online,
  avatar,
}) => {
  const classes = useStyles();

  return (
    <Badge
      overlap="rectangular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        badge: classNames(
          classes.badge,
          online ? classes.greenBadge : classes.redBadge,
        ),
      }}
      variant="dot">
      {avatar ? (
        <Avatar variant="square" className={classes.image} src={avatar} />
      ) : (
        <Avatar variant="square" className={classes.image}>
          <InsertPhoto />
        </Avatar>
      )}
    </Badge>
  );
};

export default AvatarWithBadge;
