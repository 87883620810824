import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  Paper,
  Divider,
  Avatar,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Product,
  Maybe,
  SelectedOption,
  ProductVariant,
} from '../../generated';
import Button from '../../components/common/Button';
import {
  variantSelected,
  addOrSubstituteItemByIndex,
  findVariantByOptions,
} from '../../utils/manageProductVariants';
import { InsertPhoto } from '@material-ui/icons';
import {
  defaultVariant as findDefaultVariant,
  formatMoney,
} from '../../utils/utils';

interface ProductViewProps {
  product: Maybe<Product>;
  returnToMerchantsProducts: (state: boolean) => void;
}

const useStyles = makeStyles(() => ({
  infoTitle: {
    font: 'normal normal bold 14px/24px Montserrat',
    color: '#525252',
    height: '20px',
    margin: '0 0 10px 40px',
  },
  iconButton: {
    width: '10%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  productTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  productImg: {
    width: '80%',
  },
  imagePlaceholder: {
    position: 'relative',
    width: '80%',
    height: 0,
    padding: '40% 0',
  },
  paperContainer: {
    border: '1px solid #e4e4e4',
    borderRadius: '5px',
    margin: '50px 10%',
    paddingBottom: '10px',
    boxShadow: 'none',
  },
  priceContainer: {
    margin: '16px 16px 6px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  descriptionContainer: {
    margin: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  productPrice: {
    fontSize: '20px',
    color: '#DC1F5C',
    fontWeight: 'bold',
  },
  productDetailsTitle: {
    font: 'normal normal bold 15px/24px Montserrat',
    fontWeight: 'bold',
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
  },
  optionVariant: {
    margin: '10px 10px 0 0',
  },
}));

const ProductView: React.FC<ProductViewProps> = ({
  product,
  returnToMerchantsProducts,
}) => {
  const classes = useStyles();

  const defaultVariant = useMemo(() => {
    return findDefaultVariant(product?.variants);
  }, [product?.variants]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [variant, setVariant] = useState<ProductVariant | undefined>(
    defaultVariant || undefined,
  );
  const [selectedOptions, setSelectedOptions] = useState(
    defaultVariant?.option || [],
  );

  const images = useMemo(() => {
    return (
      defaultVariant?.images?.flatMap((image) =>
        image?.medium ? image?.medium : [],
      ) || []
    );
  }, [defaultVariant?.images]);

  const updateSelectedOptions = useCallback(
    (option: SelectedOption) => {
      const newOptionIndex = selectedOptions?.findIndex(
        (item) => item?.name === option.name,
      );

      setSelectedOptions(
        addOrSubstituteItemByIndex(newOptionIndex, option, selectedOptions),
      );
    },
    [selectedOptions],
  );

  useEffect(() => {
    if (!product?.variants || !selectedOptions) return;
    const selectedVariant = findVariantByOptions(
      product.variants,
      selectedOptions.filter(Boolean) as SelectedOption[],
    );
    setVariant(selectedVariant || undefined);
  }, [selectedOptions, product?.variants]);

  return (
    <>
      {!!product && (
        <>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.iconButton}
              onClick={() => returnToMerchantsProducts(false)}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography className={classes.productTitle}>
              {product?.title}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              disabled={images.length < 2}
              className={classes.iconButton}
              color="secondary"
              onClick={() => {
                setCurrentImageIndex(
                  currentImageIndex === 0
                    ? images.length - 1
                    : currentImageIndex - 1,
                );
              }}>
              <ArrowBackIosIcon />
            </IconButton>
            {images.length ? (
              <img
                className={classes.productImg}
                src={images[currentImageIndex]}
              />
            ) : (
              <Avatar variant="square" className={classes.imagePlaceholder}>
                <InsertPhoto />
              </Avatar>
            )}
            <IconButton
              disabled={images.length < 2}
              className={classes.iconButton}
              color="secondary"
              onClick={() => {
                setCurrentImageIndex(
                  currentImageIndex === images.length - 1
                    ? 0
                    : currentImageIndex + 1,
                );
              }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Paper className={classes.paperContainer}>
            <Box className={classes.priceContainer}>
              <Typography className={classes.productDetailsTitle}>
                Price:
              </Typography>
              <span className={classes.productPrice}>
                {variant?.price?.amountInCents ? (
                  <span>
                    {variant?.price?.currencyCode} {formatMoney(variant?.price)}
                  </span>
                ) : (
                  'N/A'
                )}
              </span>
            </Box>
            {variant?.description && (
              <>
                <Divider />
                <Box className={classes.descriptionContainer}>
                  <Typography className={classes.productDetailsTitle}>
                    Description:
                  </Typography>
                  <Typography className={classes.description}>
                    {variant?.description}
                  </Typography>
                </Box>
              </>
            )}
            {!variant?.price && !variant?.id && (
              <>
                <Divider />
                <Box className={classes.descriptionContainer}>
                  <Typography className={classes.description}>
                    This variant of the product is currently unavailable
                  </Typography>
                </Box>
              </>
            )}
            {product?.options && (
              <>
                <Divider />
                {product?.options.map((option) => {
                  return (
                    <Box
                      key={option?.id}
                      className={classes.descriptionContainer}>
                      <Typography className={classes.productDetailsTitle}>
                        {option?.name}:
                      </Typography>
                      <Box display="flex" flexWrap="wrap">
                        {option?.values?.map((value) => {
                          return (
                            <Button
                              key={value}
                              gradient={variantSelected(
                                selectedOptions,
                                option?.name,
                                value,
                              )}
                              className={classes.optionVariant}
                              onClick={() =>
                                updateSelectedOptions({
                                  __typename: 'SelectedOption',
                                  name: option.name,
                                  value,
                                })
                              }>
                              {value}
                            </Button>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Paper>
        </>
      )}
    </>
  );
};

export default ProductView;
