import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '../../components/common/Button';
import { MerchantCategory } from '../../generated';
import { AppContext } from '../../store';

interface ManageCategoriesDialogProps {
  open: boolean;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    height: '245px',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: '25px 40px 40px 40px',
  },
  title: {
    margin: '28px 0 0 40px',
  },
  manageCategoryTitle: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
  },
  closeButton: {
    height: '40px',
    padding: '0',
    margin: '8px',
  },
  icon: {
    fontSize: '40px',
    color: '#525252',
  },
  formControlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },
  formControl: {
    width: '-webkit-fill-available',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '28px',
    gap: '20px',
  },
}));

const ManageCategoriesDialog: React.FC<ManageCategoriesDialogProps> = ({
  open,
  onCancel,
}) => {
  const classes = useStyles();

  const { state } = useContext(AppContext);

  const [, setCategories] = useState<MerchantCategory[]>(
    state.merchantCategories || [],
  );

  useEffect(() => {
    setCategories(state.merchantCategories);
  }, [state.merchantCategories]);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {};

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialog }}>
      <Box className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.title}>
              <Typography className={classes.manageCategoryTitle}>
                Manage merchant&apos;s category
              </Typography>
            </Box>
            <IconButton className={classes.closeButton} onClick={onCancel}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.formControlContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>App Category</InputLabel>
              <Select label="App Category">
                <MenuItem value="Marketplace">Marketplace</MenuItem>
                <MenuItem value="Insurance">Insurance</MenuItem>
                <MenuItem value="Tickets">Tickets</MenuItem>
                <MenuItem value="Booking">Booking</MenuItem>
              </Select>
            </FormControl>
            {/* <KeyboardArrowRightIcon className={classes.icon} />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Shop Category</InputLabel>
              <Select
                value={shopCategory}
                onChange={handleChange}
                label="Shop Category">
                <MenuItem value="Shop">Shop</MenuItem>
                <MenuItem value="Restaurants">Restaurants</MenuItem>
                <MenuItem value="Supermarkets">Supermarkets</MenuItem>
                <MenuItem value="Pharmacy">Pharmacy</MenuItem>
                <MenuItem value="Sherehe">Sherehe</MenuItem>
              </Select>
            </FormControl>
            <KeyboardArrowRightIcon className={classes.icon} />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Merchant Category</InputLabel>
              <Select
                value={merchantCategory}
                onChange={handleChange}
                label="Merchant Category">
                <MenuItem value="Electronics">Electronics</MenuItem>
                <MenuItem value="Toys">Toys</MenuItem>
                <MenuItem value="Home">Home</MenuItem>
                <MenuItem value="Fashion">Fashion</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
          <Box className={classes.buttonContainer}>
            <Button color="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              gradient
              onClick={() => {
                onCancel;
              }}>
              Ok
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ManageCategoriesDialog;
