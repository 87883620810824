import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  Drawer,
  Box,
} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead';
import TableBody from './TableBody';
import FilterDrawer from './FilterDrawer';
import {
  ApprovalState,
  Maybe,
  MerchantSelection,
  useChangeMerchantApprovalStateMutation,
  useMerchantCategoriesLazyQuery,
  useMerchantsLazyQuery,
  useUpdateMerchantMutation,
} from '../../generated';
import { Actions, AppContext } from '../../store/index';
import ManageMerchantsCard from './ManageMerchantsCard';
import { Order } from '../../utils/types';
import { routes } from '../../App';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Button from '../../components/common/Button';
import AddOrUpdateCategoriesDialog from './AddOrUpdateCategoriesDialog';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
    border: '1px solid #E4E4E4',
  },
  paperContainer: {
    margin: '14px 25px',
    boxShadow: 'none',
    width: 'auto',
  },
  drawer: {
    width: '27%',
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
  addOrUpdateCategoriesButton: {
    width: '20%',
    height: 'fit-content',
    fontSize: '14px',
    alignSelf: 'end',
  },
}));

export type SortableFields = 'name' | 'createdAt' | 'approvalState';

const Merchants: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { state, dispatch } = useContext(AppContext);

  const [selectedMechantIDs, setSelectedMechantIDs] = useState<string[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<SortableFields>('createdAt');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [
    addOrUpdateCategoriesDialogOpen,
    setAddOrUpdateCategoriesDialogOpen,
  ] = useState(false);

  const page = useMemo(() => searchParams.get('page'), [searchParams]);

  const [getMerchants, { loading, data }] = useMerchantsLazyQuery({
    variables: {
      input: {
        page: page ? parseFloat(page) : 1,
        pageSize: state.merchantsTableRowsPerPage,
        approvalStates: state.merchantsApprovalStateFilters,
        selection: state.merchantSelectionFilters,
      },
    },
  });

  const [
    getMerchantsCategories,
    merchantCategories,
  ] = useMerchantCategoriesLazyQuery();

  const [
    changeMerchantApprovalState,
  ] = useChangeMerchantApprovalStateMutation();

  const [updateMerchant] = useUpdateMerchantMutation();

  const isSelected = useCallback(
    (id: string) => selectedMechantIDs.includes(id),
    [selectedMechantIDs],
  );

  const handleClick = useCallback(
    (_e: unknown, id: string) => {
      const selectedMechantIndex = selectedMechantIDs.indexOf(id);
      let newSelected: string[];

      if (selectedMechantIndex === -1) {
        newSelected = [...selectedMechantIDs, id];
      } else {
        newSelected = [
          ...selectedMechantIDs.slice(0, selectedMechantIndex),
          ...selectedMechantIDs.slice(
            selectedMechantIndex + 1,
            selectedMechantIDs.length,
          ),
        ];
      }

      setSelectedMechantIDs(newSelected);
    },
    [selectedMechantIDs],
  );

  const onSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setSelectedMechantIDs([]);
        return;
      }

      const newSelected: string[] =
        state.merchants
          ?.map((merchant) => merchant?.id || '')
          .filter((id) => id !== '') || [];

      if (newSelected) {
        setSelectedMechantIDs(newSelected);
      }
    },
    [state.merchants],
  );

  const updateMerchantApprovalState = useCallback<{
    (
      id?: Maybe<string>,
      state?: ApprovalState,
      message?: string,
    ): Promise<void>;
  }>(
    async (
      id,
      approvalState = ApprovalState.ReviewPending,
      approvalMessage = '',
    ) => {
      if (!id) return;

      await changeMerchantApprovalState({
        variables: {
          id,
          changeMerchantApprovalStateInput: {
            state: approvalState,
            message: approvalMessage,
          },
        },
      });
    },
    [changeMerchantApprovalState],
  );

  const updateMerchantState = useCallback<{
    (id?: Maybe<string>, selection?: MerchantSelection[]): Promise<void>;
  }>(
    async (id, selection) => {
      if (!id) return;

      await updateMerchant({
        variables: {
          id,
          merchantUpdateInput: {
            selection,
          },
        },
      });
    },
    [updateMerchant],
  );

  useEffect(() => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: loading,
    });
  }, [loading, dispatch]);

  useEffect(() => {
    if (!state.merchantSearchInput?.length) {
      getMerchants();
      dispatch({
        type: Actions.SET_MERCHANTS,
        payload: data?.merchants?.merchants || [],
      });
      dispatch({
        type: Actions.SET_MERCHANTS_TOTAL_COUNT,
        payload: data?.merchants?.totalCount || 0,
      });
      dispatch({
        type: Actions.CLEAR_SEARCH_INPUT,
        payload: false,
      });
    }
  }, [
    page,
    data?.merchants?.merchants,
    data?.merchants?.totalCount,
    state.merchantsTableRowsPerPage,
    getMerchants,
    dispatch,
    state.searchInputToBeCleared,
    state.merchantSearchInput?.length,
  ]);

  useEffect(() => {
    getMerchantsCategories();
    dispatch({
      type: Actions.SET_MERCHANTS_CATEGORIES,
      payload: merchantCategories.data?.merchantCategories || [],
    });
  }, [
    getMerchantsCategories,
    dispatch,
    merchantCategories.data?.merchantCategories,
  ]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      navigate(
        `${routes.merchants}?${createSearchParams({
          page: (newPage + 1).toString(),
        }).toString()}`,
      );
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      navigate(
        `${routes.merchants}?${createSearchParams({ page: '1' }).toString()}`,
      );
      dispatch({
        type: Actions.SET_MERCHANTS_TABLE_ROW_PER_PAGE,
        payload: parseInt(event.target.value),
      });
    },
    [navigate, dispatch],
  );

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: SortableFields) => {
      setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, order, orderBy],
  );

  return (
    <>
      {selectedMechantIDs.length > 0 && (
        <ManageMerchantsCard
          merchants={state.merchants || []}
          merchantsIds={selectedMechantIDs}
          updateMerchantApprovalState={updateMerchantApprovalState}
          setSelectedMechantIDs={setSelectedMechantIDs}
          updateMerchant={updateMerchantState}
        />
      )}
      <AddOrUpdateCategoriesDialog
        open={addOrUpdateCategoriesDialogOpen}
        onCancel={() => {
          setAddOrUpdateCategoriesDialogOpen(false);
        }}
      />
      <Box mx={3} display="flex" justifyContent="flex-end">
        <Button
          size="small"
          classes={{ root: classes.addOrUpdateCategoriesButton }}
          onClick={() => {
            setAddOrUpdateCategoriesDialogOpen(true);
          }}>
          Manage merchants categories
        </Button>
      </Box>

      <TableContainer component={Paper} className={classes.paperContainer}>
        <Table className={classes.table} size="medium">
          {state.merchants?.length === 0 && (
            <caption>There are no merchants to display</caption>
          )}
          <EnhancedTableHead
            numSelected={selectedMechantIDs.length}
            rowCount={state.merchants?.length || 0}
            onSelectAllClick={onSelectAllClick}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onOpenFilterDrawer={setFilterDrawerOpen}
            selectedApprovalStates={
              state.merchantsFilterDrawer.selectedApprovalStates
            }
            selectedSelections={state.merchantsFilterDrawer.selectedSelections}
          />
          {state.merchantsTotalCount !== 0 && (
            <>
              <TableBody
                data={state.merchants || []}
                order={order}
                orderBy={orderBy}
                isSelected={isSelected}
                handleClick={handleClick}
                updateMerchant={updateMerchantState}
                updateMerchantApprovalState={(
                  merchant,
                  approvalState,
                  message,
                ) => {
                  updateMerchantApprovalState(
                    merchant?.id,
                    approvalState,
                    message,
                  );
                }}
              />
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    colSpan={8}
                    count={state.merchantsTotalCount}
                    rowsPerPage={state.merchantsTableRowsPerPage}
                    page={page ? parseFloat(page) - 1 : 0}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </TableContainer>
      <Drawer
        anchor="right"
        open={filterDrawerOpen}
        PaperProps={{ className: classes.drawer }}
        onClose={() => setFilterDrawerOpen(false)}>
        <FilterDrawer toggleDrawer={setFilterDrawerOpen} />
      </Drawer>
    </>
  );
};

export default Merchants;
