import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { SortableFields } from './MerchantTable';
import {
  ApprovalState,
  MerchantFieldsFragment,
  MerchantSelection,
} from '../../generated';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import { ReactComponent as FilterIconSelected } from '../../icons/filter-icon-selected.svg';
import { Order } from '../../utils/types';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  order: Order;
  orderBy: SortableFields;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: SortableFields,
  ) => void;
  onOpenFilterDrawer: (open: boolean) => void;
  selectedApprovalStates: ApprovalState[];
  selectedSelections: MerchantSelection[];
}

interface HeadCell {
  id: keyof MerchantFieldsFragment | 'actions' | 'details' | 'filter';
  label: ReactNode;
  width?: string;
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
  tableHeaderCell: {
    padding: '16px 16px 16px 0',
    font: 'bold 14px/14px Montserrat',
    color: '#525252',
  },
  filterIcon: {
    width: '20px',
  },
  filterButton: {
    padding: '0 4px',
  },
  activeSortIcon: {
    opacity: 1,
  },
  inactiveSortIcon: {
    opacity: 0.3,
  },
}));

const sortableFields = ['name', 'createdAt', 'approvalState'];

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  orderBy,
  order,
  onRequestSort,
  onOpenFilterDrawer,
  selectedApprovalStates,
  selectedSelections,
}) => {
  const classes = useStyles();

  const headCells: HeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        label: 'Merchant Name',
        width: '15%',
      },
      // { id: 'merchantCategories', label: 'Category', width: '15%' },
      { id: 'selection', label: 'Selections', width: '15%' },
      {
        id: 'shortDescription',
        label: 'Short description',
        width: '15%',
      },
      { id: 'createdAt', label: 'Created at', width: '10%' },
      { id: 'actions', label: 'Actions', width: '10%' },
      { id: 'approvalState', label: 'Status', width: '10%' },
      { id: 'details', label: 'Details', width: '100px' },
      {
        id: 'filter',
        label: (
          <IconButton
            className={classes.filterButton}
            onClick={() => onOpenFilterDrawer(true)}>
            {selectedApprovalStates.length > 0 ||
            selectedSelections.length > 0 ? (
              <FilterIconSelected className={classes.filterIcon} />
            ) : (
              <FilterIcon className={classes.filterIcon} />
            )}
          </IconButton>
        ),
        width: '50px',
      },
    ];
  }, [
    classes.filterButton,
    classes.filterIcon,
    selectedApprovalStates.length,
    selectedSelections.length,
    onOpenFilterDrawer,
  ]);

  const createSortHandler = useCallback(
    (property: SortableFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow classes={{ root: classes.tableHeader }}>
        <TableCell width="42px" padding="none">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all merchants' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            width={headCell.width}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.tableHeaderCell }}>
            {sortableFields.includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                classes={{
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon,
                }}
                onClick={createSortHandler(headCell.id as SortableFields)}>
                <Box>{headCell.label}</Box>
              </TableSortLabel>
            ) : (
              <Box>{headCell.label}</Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
