import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useDeliveriesLazyQuery } from '../../generated';
import { Actions, AppContext } from '../../store/index';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableBody from './TableBody';
import { Order } from '../../utils/types';
import FilterDrawer from './FilterDrawer';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { routes } from '../../App';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
    border: '1px solid #E4E4E4',
  },
  paperContainer: {
    margin: '14px 25px',
    boxShadow: 'none',
    width: 'auto',
  },
  drawer: {
    width: '27%',
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
}));

export type SortableFields = 'status';

const Deliveries: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { state, dispatch } = useContext(AppContext);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<SortableFields>('status');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const page = useMemo(() => searchParams.get('page'), [searchParams]);

  const [getDeliveries, { loading, data, refetch }] = useDeliveriesLazyQuery({
    variables: {
      input: {
        status: state.deliveriesStatusFilters,
        page: page ? parseFloat(page) : 1,
        pageSize: state.deliveriesTableRowsPerPage,
      },
    },
  });

  useEffect(() => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: loading,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    getDeliveries();
    dispatch({
      type: Actions.SET_DELIVERIES,
      payload: data?.deliveries?.deliveries || [],
    });
    dispatch({
      type: Actions.SET_DELIVERIES_TOTAL_COUNT,
      payload: data?.deliveries?.totalCount || 0,
    });
  }, [
    dispatch,
    getDeliveries,
    page,
    data?.deliveries?.deliveries,
    data?.deliveries?.totalCount,
    state.deliveriesTableRowsPerPage,
  ]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: SortableFields) => {
      setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, order, orderBy],
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      navigate(
        `${routes.deliveries}?${createSearchParams({
          page: (newPage + 1).toString(),
        }).toString()}`,
      );
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      navigate(
        `${routes.deliveries}?${createSearchParams({ page: '1' }).toString()}`,
      );
      dispatch({
        type: Actions.SET_DELIVERIES_TABLE_ROW_PER_PAGE,
        payload: parseInt(event.target.value),
      });
    },
    [dispatch, navigate],
  );

  return (
    <>
      <TableContainer component={Paper} className={classes.paperContainer}>
        <Table className={classes.table} size="medium">
          {state.deliveries.length === 0 && (
            <caption>There are no deliveries to display</caption>
          )}
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onOpenFilterDrawer={setFilterDrawerOpen}
            selectedStatuses={state.selectedDeliveriesStatuses}
          />
          {state.deliveriesTotalCount !== 0 && (
            <>
              <EnhancedTableBody
                data={state.deliveries}
                order={order}
                orderBy={orderBy}
                refetchDeliveries={refetch}
              />
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    colSpan={6}
                    count={state.deliveriesTotalCount}
                    rowsPerPage={state.deliveriesTableRowsPerPage}
                    page={page ? parseFloat(page) - 1 : 0}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </TableContainer>
      <Drawer
        anchor="right"
        open={filterDrawerOpen}
        PaperProps={{ className: classes.drawer }}
        onClose={() => setFilterDrawerOpen(false)}>
        <FilterDrawer toggleDrawer={setFilterDrawerOpen} />
      </Drawer>
    </>
  );
};

export default Deliveries;
