import { Maybe } from 'graphql/jsutils/Maybe';
import { ProductVariant, SelectedOption } from '../generated';

export const addOrSubstituteItemByIndex = <T>(
  index: number,
  item: T,
  array: Array<T>,
): Array<T> =>
  index === -1 ? [...array, item] : Object.assign([], array, { [index]: item });

export const variantSelected: (
  selectedOptions: Maybe<SelectedOption>[],
  optionName: Maybe<string> | undefined,
  value: Maybe<string>,
) => boolean = (selectedOptions, optionName, value) => {
  return (
    selectedOptions.find(
      (option) => option?.name === optionName && option?.value === value,
    ) !== undefined
  );
};

const createOptionsMap = (options: SelectedOption[]) =>
  options.reduce((prev, curr) => {
    if (curr.name && curr.value) {
      return prev.set(curr.name, curr.value);
    }
    return prev;
  }, new Map<string, string>());

export const findVariantByOptions: (
  variants: ProductVariant[],
  options: SelectedOption[],
) => Maybe<ProductVariant> = (variants, options) => {
  const optionsMap = createOptionsMap(options);

  return variants.find((v) => {
    if (!v.option) return;
    const variantOptionsMap = createOptionsMap(
      v.option.filter(Boolean) as SelectedOption[],
    );
    for (const [key, value] of variantOptionsMap.entries()) {
      if (optionsMap.get(key) !== value) {
        return false;
      }
    }
    return true;
  });
};
