import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} &
  { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  NaiveDateTime: any;
  TimeInHm: any;
  Upload: any;
};

export type AcceptPaymentRequestInput = {
  id: Scalars['ID'];
  type: AuthenticationType;
  /**
   * When type is SIGNATURE, this should be the user's id signed with user's biometric key.
   * When it's VERIFICATION_CODE, the wallet PIN should be sent.
   */
  authenticationCode: Scalars['String'];
};

export type Actor = Customer | Merchant | Courier | Admin;

export type AddProductToWishlistInput = {
  wishlistId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  /** Name is the person name to whom the delivery should be done to */
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  location: Location;
  remark?: Maybe<Scalars['String']>;
  pickup?: Maybe<Scalars['Boolean']>;
  estateName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  floorNumber?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  coordinates: GeoCoordinatesInput;
  remark?: Maybe<Scalars['String']>;
  pickup?: Maybe<Scalars['Boolean']>;
  estateName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  floorNumber?: Maybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  /** @deprecated Field no longer supported */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  email?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  tag?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  phone?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field no longer supported */
  locked: Scalars['Boolean'];
};

export enum ApprovalState {
  NotInReview = 'NOT_IN_REVIEW',
  ReviewPending = 'REVIEW_PENDING',
  ChangesRequired = 'CHANGES_REQUIRED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum AuthProvider {
  Google = 'GOOGLE',
  Apple = 'APPLE',
}

export enum AuthenticationType {
  Signature = 'SIGNATURE',
  VerificationCode = 'VERIFICATION_CODE',
}

export type Bill = {
  __typename?: 'Bill';
  accountNumber: Scalars['String'];
  amount: Money;
  name: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
};

export type BillTransaction = Transaction & {
  __typename?: 'BillTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** Should be a phone payment method */
  issuer?: Maybe<Actor>;
  /** The accountNumber that the user is paying for */
  externalAccount?: Maybe<ExternalAccount>;
  biller: Biller;
};

export enum Biller {
  Dstv = 'DSTV',
  Gotv = 'GOTV',
  NairobiWater = 'NAIROBI_WATER',
  Zukutv = 'ZUKUTV',
  ZukuInternet = 'ZUKU_INTERNET',
}

export type BillingInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  locality: Scalars['String'];
  administrativeArea: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type BiometricsTokenInput = {
  token: Scalars['String'];
};

export type CallbackTransaction = {
  __typename?: 'CallbackTransaction';
  transaction: Transaction;
  url: Scalars['String'];
};

export enum CardType {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Discover = 'DISCOVER',
  AmericanExpress = 'AMERICAN_EXPRESS',
  DinersClub = 'DINERS_CLUB',
  CarteBlanche = 'CARTE_BLANCHE',
  Jcb = 'JCB',
  Enroute = 'ENROUTE',
  Jal = 'JAL',
  MaestroUkDomestic = 'MAESTRO_UK_DOMESTIC',
  Delta = 'DELTA',
  VisaElectron = 'VISA_ELECTRON',
  Dankort = 'DANKORT',
  CartesBancaires = 'CARTES_BANCAIRES',
  Carta = 'CARTA',
  EncodedAccountNumber = 'ENCODED_ACCOUNT_NUMBER',
  Uatp = 'UATP',
  MaestroInternational = 'MAESTRO_INTERNATIONAL',
  Hipercard = 'HIPERCARD',
  Aura = 'AURA',
  Elo = 'ELO',
  ChinaUnionpay = 'CHINA_UNIONPAY',
}

export type CategorizedMerchants = {
  __typename?: 'CategorizedMerchants';
  category: MerchantCategory;
  merchants?: Maybe<Array<Merchant>>;
};

export type ChangeMerchantApprovalStateInput = {
  state?: Maybe<ApprovalState>;
  message?: Maybe<Scalars['String']>;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['ID'];
  chatType: ChatType;
  members: Array<Actor>;
  lastMessage?: Maybe<Message>;
  unreadMessagesCount: Scalars['Int'];
};

export type ChatEdge = {
  __typename?: 'ChatEdge';
  cursor: Scalars['ID'];
  node: Chat;
};

export enum ChatType {
  Group = 'GROUP',
  Private = 'PRIVATE',
  Support = 'SUPPORT',
}

export type ChatsCursor = {
  __typename?: 'ChatsCursor';
  edges: Array<ChatEdge>;
  pageInfo: PageInfo;
};

/** Identity document country */
export enum Country {
  Kenya = 'KENYA',
  Ethiopia = 'ETHIOPIA',
  Ghana = 'GHANA',
  Nigeria = 'NIGERIA',
}

export type Courier = {
  __typename?: 'Courier';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  /** @deprecated Field no longer supported */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  email?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  phone?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  wallet?: Maybe<Wallet>;
  addresses?: Maybe<Array<Address>>;
  online: Scalars['Boolean'];
  onlineStatusChangedAt?: Maybe<Scalars['DateTime']>;
  currentPosition?: Maybe<GeoCoordinates>;
  requireBiometrics: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  avatar?: Maybe<Image>;
  currentDelivery?: Maybe<Delivery>;
  assignedDeliveries?: Maybe<Array<Delivery>>;
  /** @deprecated Field no longer supported */
  locked: Scalars['Boolean'];
};

export type CourierLastLocationInput = {
  currentPosition?: Maybe<GeoCoordinatesInput>;
};

export type CourierSelectionInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  deliveryInProgress?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
  /**
   * Filters all couriers that have logged availability within
   * the following since -
   * (now -  since) <= availabilityStatusChangedAt
   */
  onlineSince?: Maybe<Scalars['Int']>;
};

export type CourierWithDistance = {
  __typename?: 'CourierWithDistance';
  courier: Courier;
  distanceToPickUpInMeters: Scalars['Int'];
};

export type CouriersList = {
  __typename?: 'CouriersList';
  couriers: Array<Courier>;
  totalCount: Scalars['Int'];
};

export type CouriersSearchResult = {
  __typename?: 'CouriersSearchResult';
  couriers: Array<Courier>;
  totalCount: Scalars['Int'];
};

export type CreateInviteInput = {
  /** This is required for now, but once more ways to invite a person are created it should be optional */
  transfer: TransferCreditInput;
};

export type CreateWishlistInput = {
  name: Scalars['String'];
};

export type CreditCard = PaymentMethod & {
  __typename?: 'CreditCard';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  last4Digits: Scalars['String'];
  provider?: Maybe<PaymentProvider>;
  type: CardType;
};

/** Card details. */
export type CreditCardInput = {
  cardNumber: Scalars['String'];
  type: CardType;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  cvv: Scalars['String'];
};

export enum CurrencyCode {
  /** Kenyan Shilling (KES). */
  Kes = 'KES',
}

export type CursorInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  /** @deprecated Field no longer supported */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  email?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  phone?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  wallet?: Maybe<Wallet>;
  avatar?: Maybe<Image>;
  addresses?: Maybe<Array<Address>>;
  currentAddress?: Maybe<Address>;
  /** Require biometrics for operations like sending money and buying things */
  requireBiometrics: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field no longer supported */
  locked: Scalars['Boolean'];
  settings: Settings;
};

export type CustomerSelectionInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CustomerUpdateInput = {
  avatar?: Maybe<Scalars['ID']>;
  currentAddressId?: Maybe<Scalars['ID']>;
  requireBiometrics?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<SettingsInput>;
};

export type CustomersList = {
  __typename?: 'CustomersList';
  customers: Array<Customer>;
  totalCount: Scalars['Int'];
};

export type CustomersSearchResult = {
  __typename?: 'CustomersSearchResult';
  customers: Array<Customer>;
  totalCount: Scalars['Int'];
};

export type CybersourceDepositInput = {
  creditCard: CreditCardInput;
  billingInfo: BillingInfoInput;
  money: MoneyInput;
};

export type CybersourceDeviceCollectionJwt = CybersourceJwt & {
  __typename?: 'CybersourceDeviceCollectionJWT';
  ref: Scalars['ID'];
  jwt: Scalars['String'];
};

export type CybersourceJwt = {
  ref: Scalars['ID'];
  jwt: Scalars['String'];
};

export type CybersourceStepupJwt = CybersourceJwt & {
  __typename?: 'CybersourceStepupJWT';
  ref: Scalars['ID'];
  jwt: Scalars['String'];
  md: Scalars['ID'];
};

export type DateInterval = {
  start: Scalars['NaiveDateTime'];
  end: Scalars['NaiveDateTime'];
};

export type DeliveriesList = {
  __typename?: 'DeliveriesList';
  deliveries: Array<Delivery>;
  totalCount: Scalars['Int'];
};

export type DeliveriesSearchResult = {
  __typename?: 'DeliveriesSearchResult';
  deliveries: Array<Delivery>;
  totalCount: Scalars['Int'];
};

export type Delivery = {
  __typename?: 'Delivery';
  id: Scalars['ID'];
  courier?: Maybe<Courier>;
  customer: Customer;
  orderBatch: OrderBatch;
  pickUp: Address;
  dropOff: Address;
  status: DeliveryStatus;
};

export type DeliveryFilterInput = {
  courierIds?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<Array<DeliveryStatus>>;
};

export type DeliveryRequest = {
  __typename?: 'DeliveryRequest';
  id: Scalars['ID'];
  delivery: Delivery;
  courier: Courier;
  /** User that assigned the request to the courier. */
  admin: Admin;
  availableUntil: Scalars['DateTime'];
};

export type DeliveryRequestInput = {
  courierId: Scalars['ID'];
  deliveryId: Scalars['ID'];
};

export type DeliverySelectionInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  /** Filter by delivery status */
  status?: Maybe<Array<DeliveryStatus>>;
  /** Filter by couriers */
  courierIds?: Maybe<Array<Scalars['ID']>>;
};

export enum DeliveryStatus {
  Unassigned = 'UNASSIGNED',
  CourierConfirmationPending = 'COURIER_CONFIRMATION_PENDING',
  DropOff = 'DROP_OFF',
  PickUp = 'PICK_UP',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
}

export type DeliveryStatusUpdate = Event & {
  __typename?: 'DeliveryStatusUpdate';
  id: Scalars['ID'];
  type: EventType;
  date: Scalars['DateTime'];
  orderBatch: OrderBatch;
};

export type DepositInput = {
  /** Use this field if you need to access a saved payment method. */
  savedPaymentMethodId?: Maybe<Scalars['ID']>;
  /** Use this field if you don't want to save the payment method. */
  paymentMethod?: Maybe<PaymentMethodInput>;
  money: MoneyInput;
};

export type DistanceFilterInput = {
  radiusInMetres?: Maybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Error = {
  type: ErrorTypes;
};

export enum ErrorTypes {
  ValidationError = 'VALIDATION_ERROR',
  TokenInvalid = 'TOKEN_INVALID',
  TokenExpired = 'TOKEN_EXPIRED',
  NotRegistered = 'NOT_REGISTERED',
  NotAuthorized = 'NOT_AUTHORIZED',
  InsufficientCredit = 'INSUFFICIENT_CREDIT',
  AccountLocked = 'ACCOUNT_LOCKED',
  NotFound = 'NOT_FOUND',
  /** Service error, please retry */
  ServerError = 'SERVER_ERROR',
  /** Used during purchase, this relates to product not being available for purchase */
  NotAvailable = 'NOT_AVAILABLE',
  /** Something wrong happened during a transaction */
  TransactionFailed = 'TRANSACTION_FAILED',
  MultipleMerchantProduct = 'MULTIPLE_MERCHANT_PRODUCT',
  BiometricsVerificationFailed = 'BIOMETRICS_VERIFICATION_FAILED',
  /** Used when user enters a wrong wallet PIN */
  WrongWalletPin = 'WRONG_WALLET_PIN',
  /** Used when user has not activated their wallet */
  WalletInactive = 'WALLET_INACTIVE',
  InvalidOtp = 'INVALID_OTP',
  /** Used when depositing or withdrawing using MPESA, if the number is not registered on MPESA mobile money platform */
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  /** Returned when a wishlist name is duplicated */
  DuplicateWishlistName = 'DUPLICATE_WISHLIST_NAME',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  PaymentMethodProcessingFailed = 'PAYMENT_METHOD_PROCESSING_FAILED',
  DuplicateAddressLocation = 'DUPLICATE_ADDRESS_LOCATION',
  InvalidAmount = 'INVALID_AMOUNT',
  DeliveryCreationFailed = 'DELIVERY_CREATION_FAILED',
  InvalidSameReceipient = 'INVALID_SAME_RECEIPIENT',
  /** Used when a transaction results in excess of a wallet limit */
  AmountLimitExceeded = 'AMOUNT_LIMIT_EXCEEDED',
  WalletLimitExceeded = 'WALLET_LIMIT_EXCEEDED',
  DailyLimitExceeded = 'DAILY_LIMIT_EXCEEDED',
}

export type Event = {
  id: Scalars['ID'];
  type: EventType;
  date: Scalars['DateTime'];
};

export enum EventType {
  SystemNotification = 'SYSTEM_NOTIFICATION',
  Transaction = 'TRANSACTION',
  PaymentRequest = 'PAYMENT_REQUEST',
  DeliveryStatusUpdate = 'DELIVERY_STATUS_UPDATE',
}

export type EventsInput = {
  /** If this is provided, only return events of types included here. */
  type?: Maybe<Array<Maybe<EventType>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type EventsList = {
  __typename?: 'EventsList';
  events: Array<Event>;
  totalCount: Scalars['Int'];
};

export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  accountNumber: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
};

export type FetchBillInput = {
  accountNumber: Scalars['String'];
  biller: Biller;
};

export type FreeMoneyDev = PaymentMethod & {
  __typename?: 'FreeMoneyDev';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum Fulfillment {
  Delivery = 'DELIVERY',
  None = 'NONE',
}

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates';
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type GeoCoordinatesInput = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export enum IdentityType {
  PhoneNumber = 'PHONE_NUMBER',
  Email = 'EMAIL',
}

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']>;
  small?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  large?: Maybe<Scalars['String']>;
};

export type Individual = {
  __typename?: 'Individual';
  kraPin: Media;
};

export type IndividualInput = {
  kraPinId: Scalars['ID'];
};

/** Just details about a bank provider */
export type InterswitchBank = {
  __typename?: 'InterswitchBank';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Image>;
};

/** Respresents a stored bank account */
export type InterswitchBankAccount = PaymentMethod & {
  __typename?: 'InterswitchBankAccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  bankAccountNumber: Scalars['String'];
  interswitchBank?: Maybe<InterswitchBank>;
};

export type InterswitchBankAccountInput = {
  name: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  interswitchBankId: Scalars['ID'];
};

export type Invitation = {
  __typename?: 'Invitation';
  code: Scalars['String'];
  sender: Customer;
};

export type LimitedCompany = {
  __typename?: 'LimitedCompany';
  kraPin: Media;
  incorporationCertificate: Media;
  directorsIds: Array<Media>;
  cr12: Media;
};

export type LimitedCompanyInput = {
  kraPinId: Scalars['ID'];
  incorporationCertificateId: Scalars['ID'];
  directorsIds: Array<Scalars['ID']>;
  cr12Id: Scalars['ID'];
};

export type LoadCreditTransaction = Transaction & {
  __typename?: 'LoadCreditTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  paymentMethod: PaymentMethod;
  receiver?: Maybe<Actor>;
};

export type LoadWalletInput = {
  money: MoneyInput;
  paymentMethodId: Scalars['ID'];
};

/**
 * This is a type in case we want to segment the location into
 * multiple fields like city, street, apartment
 */
export type Location = {
  __typename?: 'Location';
  formattedAddress: Scalars['String'];
  coordinates: GeoCoordinates;
};

export type Login = {
  __typename?: 'Login';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<Actor>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginWithCodeInput = {
  code: Scalars['String'];
  identity: Scalars['String'];
  identityType: IdentityType;
};

export type LoginWithProviderInput = {
  token: Scalars['String'];
  provider: AuthProvider;
  invitationToken?: Maybe<Scalars['String']>;
};

export type LogoutInput = {
  refreshToken: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  type?: Maybe<MerchantType>;
  name?: Maybe<Scalars['String']>;
  wallet?: Maybe<Wallet>;
  merchantCategories?: Maybe<MerchantCategory>;
  productCategories?: Maybe<Array<ProductCategory>>;
  rating?: Maybe<Scalars['Float']>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  products: ProductList;
  operatingTime?: Maybe<OperatingTime>;
  favorite?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  allowPayingDirectly?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Image>;
  coverImage?: Maybe<Image>;
  website?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Address>>;
  tag?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** Merchant KYC Fields */
  idType?: Maybe<VerificationId>;
  idNumber?: Maybe<Scalars['String']>;
  applicantsName?: Maybe<Scalars['String']>;
  approvalState?: Maybe<ApprovalState>;
  approvalMessage?: Maybe<Scalars['String']>;
  showTutorial?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  selection?: Maybe<Array<MerchantSelection>>;
  /** @deprecated Field no longer supported */
  locked: Scalars['Boolean'];
  settings: Settings;
};

export type MerchantProductsArgs = {
  input?: Maybe<ProductSelectionInput>;
};

export type MerchantCategory = {
  __typename?: 'MerchantCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<MerchantCategory>;
  children?: Maybe<Array<MerchantCategory>>;
  image?: Maybe<Image>;
};

export type MerchantCategoryInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  imageId?: Maybe<Scalars['ID']>;
};

export type MerchantFilterInput = {
  merchantSelection?: Maybe<Array<MerchantSelection>>;
};

export enum MerchantSelection {
  Nearby = 'NEARBY',
  Discounts = 'DISCOUNTS',
  Featured = 'FEATURED',
  Popular = 'POPULAR',
  HighRated = 'HIGH_RATED',
  AllowsDirectPayments = 'ALLOWS_DIRECT_PAYMENTS',
}

export type MerchantSelectionInput = {
  /** Filter by name */
  name?: Maybe<Scalars['String']>;
  /** Filter by product category */
  productCategoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter by merchant category */
  merchantCategoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter by selection */
  selection?: Maybe<Array<MerchantSelection>>;
  /** Filter by approval state */
  approvalStates?: Maybe<Array<ApprovalState>>;
  /** Pagination */
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Type of Business. Merchant should only select one business type */
export type MerchantType =
  | Individual
  | SoleProprietor
  | LimitedCompany
  | Partnership;

export type MerchantTypeInput = {
  individual?: Maybe<IndividualInput>;
  soleProprietor?: Maybe<SoleProprietorInput>;
  limitedCompany?: Maybe<LimitedCompanyInput>;
  partnership?: Maybe<PartnershipInput>;
};

export type MerchantUpdateInput = {
  name?: Maybe<Scalars['String']>;
  operatingTime?: Maybe<OperatingTimeInput>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  allowPayingDirectly?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['ID']>;
  coverImage?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  showTutorial?: Maybe<Scalars['Boolean']>;
  selection?: Maybe<Array<MerchantSelection>>;
  settings?: Maybe<SettingsInput>;
  merchantCategories?: Maybe<Scalars['ID']>;
  merchantType?: Maybe<MerchantTypeInput>;
  idType?: Maybe<VerificationId>;
  idNumber?: Maybe<Scalars['String']>;
  applicantsName?: Maybe<Scalars['String']>;
};

export type MerchantWithDistance = {
  __typename?: 'MerchantWithDistance';
  merchant: Merchant;
  distanceInMeters: Scalars['Int'];
};

export type MerchantsList = {
  __typename?: 'MerchantsList';
  merchants: Array<Merchant>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MerchantsSearchResult = {
  __typename?: 'MerchantsSearchResult';
  merchants: Array<Merchant>;
  totalCount: Scalars['Int'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  author: Actor;
  text: Scalars['String'];
  media?: Maybe<Media>;
  date: Scalars['DateTime'];
  edited: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['ID'];
  node: Message;
};

export type MessagesCursor = {
  __typename?: 'MessagesCursor';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type Money = {
  __typename?: 'Money';
  amountInCents: Scalars['Int'];
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  amountInCents: Scalars['Int'];
  currencyCode: CurrencyCode;
};

export type NewMessage = {
  __typename?: 'NewMessage';
  chatId: Scalars['ID'];
  message: Message;
};

export type NotificationChannel = {
  __typename?: 'NotificationChannel';
  email: Scalars['Boolean'];
  pushNotification: Scalars['Boolean'];
  textMessage: Scalars['Boolean'];
  phoneCall: Scalars['Boolean'];
};

export type NotificationChannelInput = {
  email?: Maybe<Scalars['Boolean']>;
  pushNotification?: Maybe<Scalars['Boolean']>;
  textMessage?: Maybe<Scalars['Boolean']>;
  phoneCall?: Maybe<Scalars['Boolean']>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  general: NotificationChannel;
  reminders: NotificationChannel;
  promotionalMessages: NotificationChannel;
  account: NotificationChannel;
};

export type NotificationSettingsInput = {
  general?: Maybe<NotificationChannelInput>;
  reminders?: Maybe<NotificationChannelInput>;
  promotionalMessages?: Maybe<NotificationChannelInput>;
  account?: Maybe<NotificationChannelInput>;
};

export type OperatingTime = {
  __typename?: 'OperatingTime';
  mo?: Maybe<Array<Maybe<TimeInterval>>>;
  tu?: Maybe<Array<Maybe<TimeInterval>>>;
  we?: Maybe<Array<Maybe<TimeInterval>>>;
  th?: Maybe<Array<Maybe<TimeInterval>>>;
  fr?: Maybe<Array<Maybe<TimeInterval>>>;
  sa?: Maybe<Array<Maybe<TimeInterval>>>;
  su?: Maybe<Array<Maybe<TimeInterval>>>;
};

export type OperatingTimeInput = {
  mo?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  tu?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  we?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  th?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  fr?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  sa?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  su?: Maybe<Array<Maybe<TimeIntervalInput>>>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  referenceNumber: Scalars['String'];
  fulfillment: OrderFulfillment;
  batches: Array<OrderBatch>;
  date: Scalars['DateTime'];
  deliveryDeadline?: Maybe<Scalars['DateTime']>;
  buyer: Customer;
};

export type OrderBatch = {
  __typename?: 'OrderBatch';
  id: Scalars['ID'];
  /** Human-readable number that orders can be referenced with. Unique for each merchant. */
  referenceNumber: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  merchant: Merchant;
  items: Array<OrderListItem>;
  totalCost: Money;
  /** Last updated status */
  status: OrderStatus;
  /** Detailed tracking information */
  tracking: Array<OrderTracking>;
  delivery?: Maybe<Delivery>;
  pickup?: Maybe<Pickup>;
  /** Payment details */
  payment?: Maybe<OrderBatchTransaction>;
};

export type OrderBatchTransaction = Transaction & {
  __typename?: 'OrderBatchTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  orderBatch: OrderBatch;
};

export type OrderFilterInput = {
  delivery?: Maybe<DeliveryFilterInput>;
  pickup?: Maybe<PickupFilterInput>;
  /** Enable filtering by fulfillment type */
  fulfillment?: Maybe<OrderFulfillment>;
};

export enum OrderFulfillment {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
}

export type OrderInput = {
  items: Array<OrderListItemInput>;
  remark?: Maybe<Scalars['String']>;
  fulfillment: OrderFulfillment;
  address: AddressInput;
  deliveryDeadline?: Maybe<Scalars['DateTime']>;
  type: AuthenticationType;
  /**
   * When type is SIGNATURE, this should be the user's id signed with user's biometric key.
   * When it's VERIFICATION_CODE, the wallet PIN should be sent.
   */
  authenticationCode: Scalars['String'];
};

export type OrderListItem = {
  __typename?: 'OrderListItem';
  count: Scalars['Int'];
  variant: ProductVariant;
};

export type OrderListItemInput = {
  count: Scalars['Int'];
  variantId: Scalars['ID'];
};

export enum OrderStatus {
  New = 'NEW',
  InDelivery = 'IN_DELIVERY',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export type OrderTracking = {
  __typename?: 'OrderTracking';
  status?: Maybe<DeliveryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnProductsSearchResult = {
  __typename?: 'OwnProductsSearchResult';
  products: Array<Product>;
  totalCount: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
};

export type Partnership = {
  __typename?: 'Partnership';
  kraPin: Media;
  registrationCertificate: Media;
  directorsIds: Array<Media>;
  partnershipDeed: Media;
};

export type PartnershipInput = {
  kraPinId: Scalars['ID'];
  registrationCertificateId: Scalars['ID'];
  directorsIds: Array<Scalars['ID']>;
  partnershipDeedId: Scalars['ID'];
};

export type PayBillInput = {
  biller: Biller;
  accountNumber: Scalars['String'];
  amount: MoneyInput;
};

export type PaymentMethod = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/**
 * Exactly one of phone or creditCard or interswitchBankAccount should always be set.
 * When input type unions arrive into GraphQL, it should be changed.
 */
export type PaymentMethodInput = {
  name?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
  phone?: Maybe<Scalars['String']>;
  creditCard?: Maybe<CreditCardInput>;
  interswitchBankAccount?: Maybe<InterswitchBankAccountInput>;
};

export enum PaymentMethodType {
  Phone = 'PHONE',
  CreditCard = 'CREDIT_CARD',
  FreeMoneyDev = 'FREE_MONEY_DEV',
  InterswitchBank = 'INTERSWITCH_BANK',
  Cybersource = 'CYBERSOURCE',
}

export enum PaymentProvider {
  Interswitch = 'INTERSWITCH',
  Cybersource = 'CYBERSOURCE',
}

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  id: Scalars['ID'];
  issuer: Actor;
  receiver: Actor;
  amount: Money;
  remark?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fulfilled: Scalars['Boolean'];
  revoked: Scalars['Boolean'];
  transaction?: Maybe<Transaction>;
};

export type PaymentRequestEvent = Event & {
  __typename?: 'PaymentRequestEvent';
  id: Scalars['ID'];
  type: EventType;
  date: Scalars['DateTime'];
  paymentRequest: PaymentRequest;
};

export type PhoneNumber = PaymentMethod & {
  __typename?: 'PhoneNumber';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type Pickup = {
  __typename?: 'Pickup';
  status: PickupStatus;
  address: Address;
};

export type PickupFilterInput = {
  status?: Maybe<Array<PickupStatus>>;
  address?: Maybe<AddressInput>;
};

export enum PickupStatus {
  Created = 'CREATED',
  Collecting = 'COLLECTING',
  Ready = 'READY',
  Done = 'DONE',
}

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<Merchant>;
  fulfillment?: Maybe<Fulfillment>;
  categories: Array<ProductCategory>;
  /** @deprecated Field no longer supported */
  images?: Maybe<Array<Maybe<Image>>>;
  options?: Maybe<Array<Maybe<ProductOption>>>;
  variants?: Maybe<Array<ProductVariant>>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<ProductCategory>;
  children?: Maybe<Array<ProductCategory>>;
  products?: Maybe<ProductList>;
};

export type ProductCategoryInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type ProductFilterInput = {
  minimumPrice?: Maybe<MoneyInput>;
  maximumPrice?: Maybe<MoneyInput>;
  minimumReviewScore?: Maybe<Scalars['Int']>;
  sortOptions?: Maybe<ProductSortOptionsInput>;
};

export type ProductInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  fulfillment?: Maybe<Fulfillment>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<Scalars['ID']>>;
  options?: Maybe<Array<ProductOptionInput>>;
  variants?: Maybe<Array<ProductVariantInput>>;
};

export type ProductList = {
  __typename?: 'ProductList';
  total: Scalars['Int'];
  items: Array<Maybe<Product>>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  /** id will be 1, 2, 3 (based on options) */
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['Int']>;
};

export type ProductOptionInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['Int']>;
};

export type ProductSelectionInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  /** Show products for specified categories */
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};

export type ProductSortOptionsInput = {
  price?: Maybe<SortByDirection>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
  price?: Maybe<Money>;
  title?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Image>>;
  sku?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  option?: Maybe<Array<Maybe<SelectedOption>>>;
  description?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductVariantInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  price?: Maybe<MoneyInput>;
  sku?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<SelectedOptionInput>>>;
  images?: Maybe<Array<Scalars['ID']>>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type PushNotificationTokenInput = {
  token: Scalars['String'];
  platform: Platform;
};

export type ReactToDeliveryRequestInput = {
  deliveryId: Scalars['ID'];
  accept: Scalars['Boolean'];
};

export type RecentTransactionsInput = {
  /** Filter by dates */
  date?: Maybe<DateInterval>;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RefundTransaction = Transaction & {
  __typename?: 'RefundTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  orderBatch: OrderBatch;
};

export enum RegistrationStatusType {
  Phone = 'PHONE',
  Tag = 'TAG',
}

export type RemoveProductFromWishlistInput = {
  wishlistId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type RequestLoginCodeInput = {
  identity: Scalars['String'];
  identityType: IdentityType;
};

export type RequestPaymentInput = {
  target: UserIdentifier;
  amount: MoneyInput;
  remark?: Maybe<Scalars['String']>;
};

export type RequestWalletActivationForMerchantInput = {
  phoneNumber: Scalars['String'];
  cashtag: Scalars['String'];
};

export type RequestWalletActivationInput = {
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  country: Country;
  cashtag: Scalars['String'];
  idType: VerificationId;
  idNumber: Scalars['String'];
  /** Image ids */
  idPhotos: Array<Scalars['ID']>;
};

export type Review = {
  __typename?: 'Review';
  id?: Maybe<Scalars['ID']>;
  author?: Maybe<Customer>;
  score?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewInput = {
  score: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  orderBatchId: Scalars['ID'];
};

export enum Role {
  /** Default Role */
  Customer = 'CUSTOMER',
  /** Merchant */
  Merchant = 'MERCHANT',
  Courier = 'COURIER',
  Admin = 'ADMIN',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept and process payment request */
  acceptPaymentRequest?: Maybe<Transaction>;
  /**
   * Returns a url which needs to be loaded in the client (in mobile as a webview) to add a new card.
   * Once the external form is filled, submitted to Interswitch, and the payment transaction is successful,
   * the Interswitch FE sends the response (containing the card details) to the backend for saving.
   * The customer card is credited with 1 KES which will be refunded later.
   */
  addCardWithInterswitch: Scalars['String'];
  addOrUpdateAddresses: Array<Address>;
  addOrUpdateBiometricsToken?: Maybe<Scalars['Boolean']>;
  /** Add or update a category, based on whether its ID is provided */
  addOrUpdateMerchantCategory: MerchantCategory;
  /** Add or update product */
  addOrUpdateProduct?: Maybe<Product>;
  /** Add or update a category, based on whether its ID is provided */
  addOrUpdateProductCategory: ProductCategory;
  addOrUpdatePushNotificationToken?: Maybe<Scalars['Boolean']>;
  /** Adds a new payment method */
  addPaymentMethod?: Maybe<PaymentMethod>;
  /** Add a product to a wish list */
  addProductToWishlist?: Maybe<Scalars['Boolean']>;
  /** Write review */
  addReview?: Maybe<Review>;
  changeCustomerWalletState?: Maybe<WalletActivation>;
  /** Can only be used if you have admin privileges */
  changeMerchantApprovalState?: Maybe<Merchant>;
  changeMultipleMerchantsApprovalState: Array<Merchant>;
  /** Change status for multiple orders */
  changeOrdersStatus?: Maybe<Scalars['Boolean']>;
  checkCardEnrollment: CybersourceStepupJwt;
  createChat: Chat;
  /** Create an invite. Only supports creating one with a transfer input for now. */
  createInvite: Invitation;
  /** Make a purchase from a merchant */
  createOrder?: Maybe<Order>;
  /** Create a new wish list to save a product */
  createWishlist?: Maybe<Wishlist>;
  deleteMessage: Message;
  deleteWishlist?: Maybe<Scalars['Boolean']>;
  /** Add credit using a payment method */
  deposit: Transaction;
  depositWithCybersource: CybersourceDeviceCollectionJwt;
  /**
   * Returns a url and the transaction (with pending status), url needs to be loaded in the client (in mobile as a webview) to load the interswitch UI.
   * Once the external form is filled the data is send to the our backend.
   * FE should query that transaction by id to know if the transaction was successfull.
   */
  depositWithInterswitch: CallbackTransaction;
  editMessage: Message;
  /**
   * Allows favoriting a merchant. Favorite merchants show up
   * first in selections that they are eligible for.
   */
  favoriteMerchant?: Maybe<Scalars['Boolean']>;
  inviteCouriers: Scalars['Boolean'];
  /** Load wallet */
  loadWallet?: Maybe<Scalars['Boolean']>;
  /** Lock or unlock merchant/customer/courier */
  lockUsers?: Maybe<Scalars['Boolean']>;
  /** User login */
  login?: Maybe<Login>;
  /** Login with code */
  loginWithCode?: Maybe<Login>;
  loginWithCodeForCourier?: Maybe<Login>;
  loginWithCodeForMerchant?: Maybe<Login>;
  /** Login or register with External Provider */
  loginWithProvider?: Maybe<Login>;
  /** Logout */
  logout?: Maybe<Scalars['Boolean']>;
  /** Merchant login or registration with external provider */
  merchantLoginWithProvider?: Maybe<Login>;
  /** Merchant registration */
  merchantSignup?: Maybe<Actor>;
  /** Customers can get the merchants closest to them */
  nearbyMerchants: Array<MerchantWithDistance>;
  /** Pay a bill */
  payBill: Transaction;
  reactToDeliveryRequest?: Maybe<Scalars['Boolean']>;
  readMessages: Array<Message>;
  /** Refresh Token */
  refreshToken?: Maybe<Login>;
  removeAddresses?: Maybe<Scalars['Boolean']>;
  /** Remove a category */
  removeMerchantCategory: Scalars['Boolean'];
  /** Removes a payement method */
  removePaymentMethod?: Maybe<Scalars['Boolean']>;
  /** Remove a product */
  removeProduct?: Maybe<Scalars['Boolean']>;
  /** Remove a category */
  removeProductCategory?: Maybe<Scalars['Boolean']>;
  /** Removes a product from a wish list */
  removeProductFromWishlist?: Maybe<Scalars['Boolean']>;
  /** Delete review */
  removeReview?: Maybe<Scalars['Boolean']>;
  /** Request login code */
  requestLoginCode?: Maybe<Scalars['Boolean']>;
  requestLoginCodeForCourier?: Maybe<Scalars['Boolean']>;
  requestLoginCodeForMerchant?: Maybe<Scalars['Boolean']>;
  requestOtpForWithdraw: Scalars['Boolean'];
  /** Request a payment from a user. */
  requestPayment?: Maybe<Scalars['Boolean']>;
  /** Request activation of wallet */
  requestWalletActivation?: Maybe<WalletActivation>;
  /** Request activation of wallet for merchant */
  requestWalletActivationForMerchant?: Maybe<WalletActivation>;
  /** Revoke one of your requests. */
  revokeRequest?: Maybe<Scalars['Boolean']>;
  /** Send a delivery request to a courier. Only available for admins. */
  sendDeliveryRequest?: Maybe<Scalars['Boolean']>;
  sendMessage: Message;
  /** Sends a verification code to the linked phone number */
  sendVerificationCode: Scalars['Boolean'];
  /** Sets the activation PIN */
  setWalletPin?: Maybe<Scalars['Boolean']>;
  /** User registration */
  signup?: Maybe<Actor>;
  startDelivery: Scalars['Boolean'];
  /** Transfer credit to another user */
  transferCredit: TransferTransaction;
  updateChatMembers: Chat;
  /** Update the last location position of the courier */
  updateCourierLastLocation?: Maybe<Scalars['Boolean']>;
  updateCourierOnlineStatus: Scalars['Boolean'];
  /** Update the delivery status */
  updateDeliveryStatus?: Maybe<Scalars['Boolean']>;
  updateMerchant?: Maybe<Merchant>;
  updateMultipleMerchantsSelections: Array<Merchant>;
  /** Updates a payment method */
  updatePaymentMethod?: Maybe<Scalars['Boolean']>;
  updatePickupStatus: Scalars['Boolean'];
  /** Update review */
  updateReview?: Maybe<Scalars['Boolean']>;
  /**
   * Update user info. If the user sending the request
   * has sufficient privileges (cashia admin), he can update
   * other users' info by supplying the ID here, otherwise
   * it just updates the sender's info.
   */
  updateUser?: Maybe<Actor>;
  /** Update admins's password. Only admins can use this mutation */
  updateUserPassword?: Maybe<Actor>;
  uploadMedia?: Maybe<Media>;
  /** Verifies a phone number */
  verifyPhone?: Maybe<Scalars['Boolean']>;
  /** Verifies a user's wallet PIN */
  verifyPinOrSignature?: Maybe<Scalars['Boolean']>;
  /** Withdraw credit from a wallet to a phone number */
  withdraw: Transaction;
};

export type RootMutationTypeAcceptPaymentRequestArgs = {
  input: AcceptPaymentRequestInput;
};

export type RootMutationTypeAddOrUpdateAddressesArgs = {
  input: Array<AddressInput>;
};

export type RootMutationTypeAddOrUpdateBiometricsTokenArgs = {
  input: BiometricsTokenInput;
};

export type RootMutationTypeAddOrUpdateMerchantCategoryArgs = {
  input?: Maybe<MerchantCategoryInput>;
};

export type RootMutationTypeAddOrUpdateProductArgs = {
  input?: Maybe<ProductInput>;
};

export type RootMutationTypeAddOrUpdateProductCategoryArgs = {
  input?: Maybe<ProductCategoryInput>;
};

export type RootMutationTypeAddOrUpdatePushNotificationTokenArgs = {
  input: PushNotificationTokenInput;
};

export type RootMutationTypeAddPaymentMethodArgs = {
  input: PaymentMethodInput;
};

export type RootMutationTypeAddProductToWishlistArgs = {
  input: AddProductToWishlistInput;
};

export type RootMutationTypeAddReviewArgs = {
  input: ReviewInput;
};

export type RootMutationTypeChangeCustomerWalletStateArgs = {
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<WalletActivationStatus>;
};

export type RootMutationTypeChangeMerchantApprovalStateArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ChangeMerchantApprovalStateInput>;
};

export type RootMutationTypeChangeMultipleMerchantsApprovalStateArgs = {
  ids: Array<Scalars['ID']>;
  input?: Maybe<ChangeMerchantApprovalStateInput>;
};

export type RootMutationTypeChangeOrdersStatusArgs = {
  ids: Array<Scalars['ID']>;
  status: OrderStatus;
};

export type RootMutationTypeCheckCardEnrollmentArgs = {
  ref: Scalars['ID'];
};

export type RootMutationTypeCreateChatArgs = {
  memberIds: Array<Scalars['ID']>;
};

export type RootMutationTypeCreateInviteArgs = {
  input: CreateInviteInput;
};

export type RootMutationTypeCreateOrderArgs = {
  input: OrderInput;
};

export type RootMutationTypeCreateWishlistArgs = {
  input: CreateWishlistInput;
};

export type RootMutationTypeDeleteMessageArgs = {
  messageId: Scalars['ID'];
};

export type RootMutationTypeDeleteWishlistArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDepositArgs = {
  input?: Maybe<DepositInput>;
};

export type RootMutationTypeDepositWithCybersourceArgs = {
  input?: Maybe<CybersourceDepositInput>;
};

export type RootMutationTypeDepositWithInterswitchArgs = {
  input?: Maybe<DepositInput>;
};

export type RootMutationTypeEditMessageArgs = {
  messageId: Scalars['ID'];
  text: Scalars['String'];
};

export type RootMutationTypeFavoriteMerchantArgs = {
  merchantId: Scalars['ID'];
  favorite: Scalars['Boolean'];
};

export type RootMutationTypeInviteCouriersArgs = {
  emails: Array<Scalars['String']>;
};

export type RootMutationTypeLoadWalletArgs = {
  walletId: Scalars['ID'];
  input: LoadWalletInput;
};

export type RootMutationTypeLockUsersArgs = {
  ids: Array<Scalars['ID']>;
  locked: Scalars['Boolean'];
};

export type RootMutationTypeLoginArgs = {
  input?: Maybe<LoginInput>;
};

export type RootMutationTypeLoginWithCodeArgs = {
  input?: Maybe<LoginWithCodeInput>;
};

export type RootMutationTypeLoginWithCodeForCourierArgs = {
  input?: Maybe<LoginWithCodeInput>;
};

export type RootMutationTypeLoginWithCodeForMerchantArgs = {
  input?: Maybe<LoginWithCodeInput>;
};

export type RootMutationTypeLoginWithProviderArgs = {
  input?: Maybe<LoginWithProviderInput>;
};

export type RootMutationTypeLogoutArgs = {
  input?: Maybe<LogoutInput>;
};

export type RootMutationTypeMerchantLoginWithProviderArgs = {
  input?: Maybe<LoginWithProviderInput>;
};

export type RootMutationTypeMerchantSignupArgs = {
  input?: Maybe<SignupInput>;
};

export type RootMutationTypeNearbyMerchantsArgs = {
  currentPosition: GeoCoordinatesInput;
};

export type RootMutationTypePayBillArgs = {
  input: PayBillInput;
};

export type RootMutationTypeReactToDeliveryRequestArgs = {
  input: ReactToDeliveryRequestInput;
};

export type RootMutationTypeReadMessagesArgs = {
  messageIds: Array<Scalars['ID']>;
};

export type RootMutationTypeRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>;
};

export type RootMutationTypeRemoveAddressesArgs = {
  ids: Array<Scalars['ID']>;
};

export type RootMutationTypeRemoveMerchantCategoryArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemovePaymentMethodArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemoveProductArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemoveProductCategoryArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemoveProductFromWishlistArgs = {
  input: RemoveProductFromWishlistInput;
};

export type RootMutationTypeRemoveReviewArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRequestLoginCodeArgs = {
  input?: Maybe<RequestLoginCodeInput>;
};

export type RootMutationTypeRequestLoginCodeForCourierArgs = {
  input?: Maybe<RequestLoginCodeInput>;
};

export type RootMutationTypeRequestLoginCodeForMerchantArgs = {
  input?: Maybe<RequestLoginCodeInput>;
};

export type RootMutationTypeRequestPaymentArgs = {
  input: RequestPaymentInput;
};

export type RootMutationTypeRequestWalletActivationArgs = {
  input: RequestWalletActivationInput;
};

export type RootMutationTypeRequestWalletActivationForMerchantArgs = {
  input: RequestWalletActivationForMerchantInput;
};

export type RootMutationTypeRevokeRequestArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeSendDeliveryRequestArgs = {
  input: DeliveryRequestInput;
};

export type RootMutationTypeSendMessageArgs = {
  input: SendMessageInput;
};

export type RootMutationTypeSendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
};

export type RootMutationTypeSetWalletPinArgs = {
  input: SetWalletPinInput;
};

export type RootMutationTypeSignupArgs = {
  input?: Maybe<SignupInput>;
};

export type RootMutationTypeStartDeliveryArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeTransferCreditArgs = {
  input: TransferCreditInput;
};

export type RootMutationTypeUpdateChatMembersArgs = {
  input: UpdateChatMembersInput;
};

export type RootMutationTypeUpdateCourierLastLocationArgs = {
  input: CourierLastLocationInput;
};

export type RootMutationTypeUpdateCourierOnlineStatusArgs = {
  online: Scalars['Boolean'];
};

export type RootMutationTypeUpdateDeliveryStatusArgs = {
  id: Scalars['ID'];
  status: DeliveryStatus;
};

export type RootMutationTypeUpdateMerchantArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<MerchantUpdateInput>;
};

export type RootMutationTypeUpdateMultipleMerchantsSelectionsArgs = {
  ids: Array<Scalars['ID']>;
  selections: Array<MerchantSelection>;
};

export type RootMutationTypeUpdatePaymentMethodArgs = {
  id: Scalars['ID'];
  input?: Maybe<PaymentMethodInput>;
};

export type RootMutationTypeUpdatePickupStatusArgs = {
  id: Scalars['ID'];
  status: PickupStatus;
};

export type RootMutationTypeUpdateReviewArgs = {
  id: Scalars['ID'];
  input: ReviewInput;
};

export type RootMutationTypeUpdateUserArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<UpdateUserInput>;
};

export type RootMutationTypeUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

export type RootMutationTypeUploadMediaArgs = {
  file: Scalars['Upload'];
};

export type RootMutationTypeVerifyPhoneArgs = {
  input: VerifyPhoneInput;
};

export type RootMutationTypeVerifyPinOrSignatureArgs = {
  input: VerifyPinOrSignatureInput;
};

export type RootMutationTypeWithdrawArgs = {
  input?: Maybe<WithdrawInput>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Show current balance */
  balance: Array<Maybe<Money>>;
  /**
   * Get availible chats for user
   * https://graphql.org/learn/pagination/
   */
  chats: ChatsCursor;
  courier: Courier;
  /** Load couriers search results */
  courierSearch?: Maybe<CouriersSearchResult>;
  /** Gets all couriers */
  couriers: CouriersList;
  /**
   * Get couriers closest to delivery pick up point.
   * Query takes delivery ID and radius in meters as arguments.
   */
  couriersNearDelivery: Array<CourierWithDistance>;
  /** Load customers search results */
  customerSearch?: Maybe<CustomersSearchResult>;
  /** Get all customers */
  customers?: Maybe<CustomersList>;
  /** Get all deliveries */
  deliveries: DeliveriesList;
  /** Get a specific delivery details */
  delivery: Delivery;
  deliveryByOrderId: Delivery;
  /** Get delivery requests that are sent to you (the courier) */
  deliveryRequests: Array<DeliveryRequest>;
  /** Load deliveries search results */
  deliverySearch?: Maybe<DeliveriesSearchResult>;
  /** Payments that the user is asked to do. */
  duePaymentRequests: Array<PaymentRequest>;
  /** This is needed to import the error type into the Graphql docs [for Absinthee] */
  error?: Maybe<ValidationError>;
  events: EventsList;
  /** FetchBill, gets the bills details i.e */
  fetchBill: Bill;
  geoCoordinatesToLocation: Array<Location>;
  /** Provides a complete list of all bank providers that we support under Interswitch */
  interswitchBankProviders: Array<InterswitchBank>;
  /** Get an invitation by code */
  invitation: Invitation;
  isCashtagAvailable: Scalars['Boolean'];
  /** Payments that were issued by the user. */
  issuedPaymentRequests: Array<Maybe<PaymentRequest>>;
  /** Get current user */
  me?: Maybe<Actor>;
  /** Get a single merchant */
  merchant?: Maybe<Merchant>;
  /** List all available merchant categories */
  merchantCategories: Array<MerchantCategory>;
  /** Load merchants search results */
  merchantSearch?: Maybe<MerchantsSearchResult>;
  /** Get merchants */
  merchants?: Maybe<MerchantsList>;
  /** Same as merchants but the results are grouped by merchant's category. */
  merchantsByCategory?: Maybe<Array<CategorizedMerchants>>;
  messages: MessagesCursor;
  order: Order;
  /** Load 20 most recent purchases */
  orders: Array<Maybe<Order>>;
  /** Load search results of merchant's own products */
  ownProductsSearch?: Maybe<OwnProductsSearchResult>;
  /** Currently active payment methods */
  paymentMethods: Array<Maybe<PaymentMethod>>;
  /** Load single product */
  product?: Maybe<Product>;
  /** List all available product categories */
  productCategories: Array<ProductCategory>;
  /** Load all active products */
  products: ProductList;
  /** Load filtered transactions */
  recentTransactions: Array<Transaction>;
  /** Load search results */
  search?: Maybe<SearchResult>;
  /** Search order by ID, merchant name or products */
  searchOrders: SearchOrdersResult;
  transaction?: Maybe<Transaction>;
  transactions: TransactionsList;
  /** Check if a user is a registered Cashia user */
  userRegistrationStatus?: Maybe<Actor>;
  /** Get all users */
  users?: Maybe<Array<Maybe<Actor>>>;
  /** Load a user's wish lists */
  wishlists: Array<Wishlist>;
};

export type RootQueryTypeChatsArgs = {
  input?: Maybe<CursorInput>;
};

export type RootQueryTypeCourierArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeCourierSearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeCouriersArgs = {
  input?: Maybe<CourierSelectionInput>;
};

export type RootQueryTypeCouriersNearDeliveryArgs = {
  deliveryId: Scalars['ID'];
  radiusInMeters: Scalars['Int'];
};

export type RootQueryTypeCustomerSearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeCustomersArgs = {
  input?: Maybe<CustomerSelectionInput>;
};

export type RootQueryTypeDeliveriesArgs = {
  input?: Maybe<DeliverySelectionInput>;
};

export type RootQueryTypeDeliveryArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeDeliveryByOrderIdArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeDeliverySearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeEventsArgs = {
  input?: Maybe<EventsInput>;
};

export type RootQueryTypeFetchBillArgs = {
  input: FetchBillInput;
};

export type RootQueryTypeGeoCoordinatesToLocationArgs = {
  input: GeoCoordinatesInput;
};

export type RootQueryTypeInvitationArgs = {
  code: Scalars['String'];
};

export type RootQueryTypeIsCashtagAvailableArgs = {
  cashtag: Scalars['String'];
};

export type RootQueryTypeMerchantArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeMerchantSearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeMerchantsArgs = {
  input?: Maybe<MerchantSelectionInput>;
};

export type RootQueryTypeMerchantsByCategoryArgs = {
  input?: Maybe<MerchantSelectionInput>;
};

export type RootQueryTypeMessagesArgs = {
  input?: Maybe<CursorInput>;
  chatId: Scalars['ID'];
};

export type RootQueryTypeOrderArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeOrdersArgs = {
  filter?: Maybe<OrderFilterInput>;
};

export type RootQueryTypeOwnProductsSearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeProductArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeProductsArgs = {
  input?: Maybe<ProductSelectionInput>;
};

export type RootQueryTypeRecentTransactionsArgs = {
  input?: Maybe<RecentTransactionsInput>;
};

export type RootQueryTypeSearchArgs = {
  searchQuery: SearchQueryInput;
};

export type RootQueryTypeSearchOrdersArgs = {
  input: SearchOrderInput;
};

export type RootQueryTypeTransactionArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeTransactionsArgs = {
  input?: Maybe<TransactionSelectionInput>;
};

export type RootQueryTypeUserRegistrationStatusArgs = {
  input: UserRegistrationStatusInput;
};

export type RootQueryTypeWishlistsArgs = {
  productId?: Maybe<Scalars['ID']>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /**
   * User will subscribe to this one subscription
   * and get all the new messages from multiple chats
   */
  newMessage: NewMessage;
};

export type SearchOrderInput = {
  /** Search by merchant name, order ID or products */
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type SearchOrdersResult = {
  __typename?: 'SearchOrdersResult';
  orders: Array<Order>;
  totalCount: Scalars['Int'];
};

export type SearchQueryInput = {
  /** Search for all text occurrences like the name, description, etc. */
  text?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  distance?: Maybe<DistanceFilterInput>;
  productFilter?: Maybe<ProductFilterInput>;
  merchantFilter?: Maybe<MerchantFilterInput>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  products: Array<Product>;
  merchants: Array<Merchant>;
  totalCount: Scalars['Int'];
};

export type SelectedOption = {
  __typename?: 'SelectedOption';
  /** name refers to the ProductOption name */
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SelectedOptionInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SendMessageInput = {
  chatId: Scalars['ID'];
  text: Scalars['String'];
  mediaId?: Maybe<Scalars['ID']>;
};

export type SendVerificationCodeInput = {
  phoneNumber: Scalars['String'];
};

export type SetWalletPinInput = {
  pin: Scalars['String'];
  idType: VerificationId;
  idNumber: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  notifications?: Maybe<NotificationSettings>;
};

export type SettingsInput = {
  notifications?: Maybe<NotificationSettingsInput>;
};

export type SignupInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type SoleProprietor = {
  __typename?: 'SoleProprietor';
  kraPin: Media;
  registrationCertificate: Media;
};

export type SoleProprietorInput = {
  kraPinId: Scalars['ID'];
  registrationCertificateId: Scalars['ID'];
};

export enum SortByDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type SystemNotification = Event & {
  __typename?: 'SystemNotification';
  id: Scalars['ID'];
  type: EventType;
  date: Scalars['DateTime'];
  message: Scalars['String'];
};

export type TimeInterval = {
  __typename?: 'TimeInterval';
  from?: Maybe<Scalars['TimeInHm']>;
  to?: Maybe<Scalars['TimeInHm']>;
};

export type TimeIntervalInput = {
  from?: Maybe<Scalars['TimeInHm']>;
  to?: Maybe<Scalars['TimeInHm']>;
};

export type Transaction = {
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
};

export type TransactionError = Error & {
  __typename?: 'TransactionError';
  type: ErrorTypes;
  reason: Scalars['String'];
};

export type TransactionEvent = Event & {
  __typename?: 'TransactionEvent';
  id: Scalars['ID'];
  type: EventType;
  date: Scalars['DateTime'];
  transaction: Transaction;
};

export type TransactionSelectionInput = {
  /** Filter by dates and status */
  date?: Maybe<DateInterval>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<TransactionStatus>;
};

export enum TransactionStatus {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Failed = 'FAILED',
}

export type TransactionsList = {
  __typename?: 'TransactionsList';
  transactions: Array<Transaction>;
  totalCount: Scalars['Int'];
};

export type TransferCreditInput = {
  credit: MoneyInput;
  note?: Maybe<Scalars['String']>;
  receiver: UserIdentifier;
  externalAccount?: Maybe<ExternalAccountInput>;
  type: AuthenticationType;
  /**
   * When type is SIGNATURE, this should be the user's id signed with user's biometric key.
   * When it's VERIFICATION_CODE, the wallet PIN should be sent.
   */
  authenticationCode: Scalars['String'];
};

export type TransferTransaction = Transaction & {
  __typename?: 'TransferTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccount>;
  status: TransactionStatus;
  issuer: Actor;
  receiver: Actor;
};

export type UpdateChatMembersInput = {
  memberIds: Array<Scalars['ID']>;
  chatId: Scalars['ID'];
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerUpdateInput>;
};

export type UpdateUserPasswordInput = {
  /** Only admins can change their password */
  password: Scalars['String'];
  currentPassword: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

/**
 * This type is used to identify the user in the system.
 * Exactly one of the parameters should always be set.
 * Once union input types arrive into GraphQL it should be replaced.
 */
export type UserIdentifier = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

/**
 * This type is used to check if a user is a registered Cashia user.
 * Either one of a phone number or cashia tag should always be set.
 */
export type UserRegistrationStatusInput = {
  type: RegistrationStatusType;
  identifier: Scalars['String'];
};

export type ValidationError = Error & {
  __typename?: 'ValidationError';
  type: ErrorTypes;
  key: Scalars['String'];
  /** Should give details sometimes. Example: a @cashia tag is already taken. */
  message?: Maybe<Scalars['String']>;
};

/** Type of identity verification document */
export enum VerificationId {
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT',
  AlienCard = 'ALIEN_CARD',
}

export type VerifyPhoneInput = {
  code: Scalars['String'];
};

export type VerifyPinOrSignatureInput = {
  pin?: Maybe<Scalars['String']>;
  /** This is required when the user logs in with set biometrics */
  signature?: Maybe<Scalars['String']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  id?: Maybe<Scalars['ID']>;
  credit?: Maybe<Money>;
  holds: Array<WalletHold>;
  totalHeld?: Maybe<Money>;
  activation?: Maybe<WalletActivation>;
};

/** WalletActivation is an Entity for a wallet activation request */
export type WalletActivation = {
  __typename?: 'WalletActivation';
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  status: WalletActivationStatus;
  country: Country;
  cashtag: Scalars['String'];
  idType: VerificationId;
  idNumber: Scalars['String'];
  idPhotos: Array<Scalars['ID']>;
};

/** This defines the status of an activation request */
export enum WalletActivationStatus {
  /** The default status */
  Pending = 'PENDING',
  /** Status when either verification starts, phone verification or identity */
  InProgress = 'IN_PROGRESS',
  /** Status when all forms of verifications are completed */
  Verified = 'VERIFIED',
}

export type WalletHold = {
  __typename?: 'WalletHold';
  /**
   * This can be used for the reason of why the money are held.
   * It's just a string for now, but can be improved later if we have UI for it planned.
   */
  remark?: Maybe<Scalars['String']>;
  credit: Money;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Products saved to this wish list */
  products: Array<Product>;
};

export type WithdrawInput = {
  /** Use this field if you need to access a saved payment method. Should be used in isolation */
  savedPaymentMethodId?: Maybe<Scalars['ID']>;
  /** Use this field if you don't want to save the payment method. */
  paymentMethod?: Maybe<PaymentMethodInput>;
  money: MoneyInput;
  type: AuthenticationType;
  /**
   * When type is SIGNATURE, this should be the user's id signed with user's biometric key.
   * When it's VERIFICATION_CODE, the wallet PIN should be sent.
   */
  authenticationCode: Scalars['String'];
};

export type WithdrawTransaction = Transaction & {
  __typename?: 'WithdrawTransaction';
  id: Scalars['ID'];
  /** Human-readable number that transactions can be referenced with */
  referenceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Money;
  /** From system */
  message?: Maybe<Scalars['String']>;
  /** From customer */
  note?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  /** Should be a phone payment method */
  paymentMethod: PaymentMethod;
  issuer?: Maybe<Actor>;
};

type ActorFields_Customer_Fragment = {
  __typename?: 'Customer';
} & CustomerFieldsFragment;

type ActorFields_Merchant_Fragment = {
  __typename?: 'Merchant';
} & MerchantFieldsFragment;

type ActorFields_Courier_Fragment = {
  __typename?: 'Courier';
} & CourierFieldsFragment;

type ActorFields_Admin_Fragment = { __typename?: 'Admin' } & MeFieldsFragment;

export type ActorFieldsFragment =
  | ActorFields_Customer_Fragment
  | ActorFields_Merchant_Fragment
  | ActorFields_Courier_Fragment
  | ActorFields_Admin_Fragment;

export type AddressFieldsFragment = { __typename?: 'Address' } & Pick<
  Address,
  'id' | 'pickup'
> & {
    location: { __typename?: 'Location' } & Pick<
      Location,
      'formattedAddress'
    > & {
        coordinates: { __typename?: 'GeoCoordinates' } & Pick<
          GeoCoordinates,
          'lat' | 'long'
        >;
      };
  };

export type CourierFieldsFragment = { __typename?: 'Courier' } & Pick<
  Courier,
  'id' | 'online' | 'onlineStatusChangedAt' | 'createdAt'
> & {
    user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
    currentPosition?: Maybe<
      { __typename?: 'GeoCoordinates' } & Pick<GeoCoordinates, 'lat' | 'long'>
    >;
    avatar?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    currentDelivery?: Maybe<
      { __typename?: 'Delivery' } & CourierDeliveryFieldsFragment
    >;
    assignedDeliveries?: Maybe<
      Array<{ __typename?: 'Delivery' } & CourierDeliveryFieldsFragment>
    >;
  };

export type CourierDeliveryFieldsFragment = { __typename?: 'Delivery' } & Pick<
  Delivery,
  'id' | 'status'
> & {
    orderBatch: { __typename?: 'OrderBatch' } & OrderBatchFieldsFragment;
    pickUp: { __typename?: 'Address' } & AddressFieldsFragment;
    dropOff: { __typename?: 'Address' } & AddressFieldsFragment;
  };

export type CourierDetailsFieldsFragment = { __typename?: 'Courier' } & Pick<
  Courier,
  'id' | 'name' | 'online'
> & { avatar?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment> };

export type CustomerFieldsFragment = { __typename?: 'Customer' } & Pick<
  Customer,
  'id' | 'tag' | 'createdAt'
> & {
    user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
    wallet?: Maybe<{ __typename?: 'Wallet' } & WalletFieldsFragment>;
    avatar?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    addresses?: Maybe<
      Array<{ __typename?: 'Address' } & AddressFieldsFragment>
    >;
    currentAddress?: Maybe<{ __typename?: 'Address' } & AddressFieldsFragment>;
  };

export type DeliveryFieldsFragment = { __typename?: 'Delivery' } & Pick<
  Delivery,
  'id' | 'status'
> & {
    courier?: Maybe<{ __typename?: 'Courier' } & CourierFieldsFragment>;
    orderBatch: { __typename?: 'OrderBatch' } & OrderBatchFieldsFragment;
    pickUp: { __typename?: 'Address' } & AddressFieldsFragment;
    dropOff: { __typename?: 'Address' } & AddressFieldsFragment;
  };

export type ImageFieldsFragment = { __typename?: 'Image' } & Pick<
  Image,
  'id' | 'small' | 'medium' | 'large'
>;

export type MeFieldsFragment = { __typename?: 'Admin' } & Pick<Admin, 'id'> & {
    user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
  };

export type MerchantFieldsFragment = { __typename?: 'Merchant' } & Pick<
  Merchant,
  | 'id'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'website'
  | 'approvalState'
  | 'approvalMessage'
  | 'createdAt'
  | 'selection'
> & {
    user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
    addresses?: Maybe<
      Array<{ __typename?: 'Address' } & AddressFieldsFragment>
    >;
    operatingTime?: Maybe<
      { __typename?: 'OperatingTime' } & OperatingTimeFieldsFragment
    >;
    logo?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    coverImage?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    productCategories?: Maybe<
      Array<{ __typename?: 'ProductCategory' } & ProductCategoryFieldsFragment>
    >;
    merchantCategories?: Maybe<
      { __typename?: 'MerchantCategory' } & MerchantCategoryFieldsFragment
    >;
  };

export type MerchantCategoryFieldsFragment = {
  __typename?: 'MerchantCategory';
} & Pick<MerchantCategory, 'id' | 'name'> & {
    image?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    children?: Maybe<
      Array<
        { __typename?: 'MerchantCategory' } & Pick<
          MerchantCategory,
          'id' | 'name'
        > & {
            image?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
            parent?: Maybe<
              { __typename?: 'MerchantCategory' } & Pick<
                MerchantCategory,
                'id' | 'name'
              > & {
                  image?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
                }
            >;
            children?: Maybe<
              Array<
                { __typename?: 'MerchantCategory' } & Pick<
                  MerchantCategory,
                  'id' | 'name'
                > & {
                    image?: Maybe<
                      { __typename?: 'Image' } & ImageFieldsFragment
                    >;
                    parent?: Maybe<
                      { __typename?: 'MerchantCategory' } & Pick<
                        MerchantCategory,
                        'id' | 'name'
                      > & {
                          image?: Maybe<
                            { __typename?: 'Image' } & ImageFieldsFragment
                          >;
                          parent?: Maybe<
                            { __typename?: 'MerchantCategory' } & Pick<
                              MerchantCategory,
                              'id' | 'name'
                            > & {
                                image?: Maybe<
                                  { __typename?: 'Image' } & ImageFieldsFragment
                                >;
                              }
                          >;
                        }
                    >;
                  }
              >
            >;
          }
      >
    >;
  };

export type MerchantDetailsFieldsFragment = { __typename?: 'Merchant' } & Pick<
  Merchant,
  'id' | 'name' | 'description' | 'website' | 'phone' | 'email'
> & {
    addresses?: Maybe<
      Array<{ __typename?: 'Address' } & AddressFieldsFragment>
    >;
    operatingTime?: Maybe<
      { __typename?: 'OperatingTime' } & OperatingTimeFieldsFragment
    >;
    logo?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    coverImage?: Maybe<{ __typename?: 'Image' } & ImageFieldsFragment>;
    productCategories?: Maybe<
      Array<{ __typename?: 'ProductCategory' } & ProductCategoryFieldsFragment>
    >;
    merchantCategories?: Maybe<
      { __typename?: 'MerchantCategory' } & MerchantCategoryFieldsFragment
    >;
    products: { __typename?: 'ProductList' } & ProductListFieldsFragment;
  };

export type MoneyFieldsFragment = { __typename?: 'Money' } & Pick<
  Money,
  'amountInCents' | 'currencyCode'
>;

export type OperatingTimeFieldsFragment = { __typename?: 'OperatingTime' } & {
  mo?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  tu?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  we?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  th?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  fr?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  sa?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
  su?: Maybe<
    Array<Maybe<{ __typename?: 'TimeInterval' } & TimeIntervalFieldsFragment>>
  >;
};

export type OrderBatchFieldsFragment = { __typename?: 'OrderBatch' } & Pick<
  OrderBatch,
  'id' | 'referenceNumber'
> & {
    payment?: Maybe<
      { __typename?: 'OrderBatchTransaction' } & Pick<
        OrderBatchTransaction,
        'date'
      >
    >;
  };

export type ProductFieldsFragment = { __typename?: 'Product' } & Pick<
  Product,
  'id' | 'title' | 'active' | 'fulfillment' | 'deleted' | 'deletedAt'
> & {
    options?: Maybe<
      Array<
        Maybe<{ __typename?: 'ProductOption' } & ProductOptionsFieldsFragment>
      >
    >;
    variants?: Maybe<
      Array<{ __typename?: 'ProductVariant' } & ProductVariantFieldsFragment>
    >;
    categories: Array<
      { __typename?: 'ProductCategory' } & ProductCategoryFieldsFragment
    >;
  };

export type ProductCategoryFieldsFragment = {
  __typename?: 'ProductCategory';
} & Pick<ProductCategory, 'id' | 'name'> & {
    children?: Maybe<
      Array<
        { __typename?: 'ProductCategory' } & Pick<
          ProductCategory,
          'id' | 'name'
        >
      >
    >;
  };

export type ProductListFieldsFragment = { __typename?: 'ProductList' } & Pick<
  ProductList,
  'total'
> & { items: Array<Maybe<{ __typename?: 'Product' } & ProductFieldsFragment>> };

export type ProductOptionsFieldsFragment = {
  __typename?: 'ProductOption';
} & Pick<ProductOption, 'id' | 'name' | 'values' | 'position'>;

export type ProductVariantFieldsFragment = {
  __typename?: 'ProductVariant';
} & Pick<ProductVariant, 'id' | 'title' | 'sku' | 'default' | 'description'> & {
    price?: Maybe<{ __typename?: 'Money' } & MoneyFieldsFragment>;
    images?: Maybe<Array<{ __typename?: 'Image' } & ImageFieldsFragment>>;
    option?: Maybe<
      Array<
        Maybe<
          { __typename?: 'SelectedOption' } & Pick<
            SelectedOption,
            'name' | 'value'
          >
        >
      >
    >;
  };

export type SearchResultFieldsFragment = { __typename?: 'SearchResult' } & Pick<
  SearchResult,
  'totalCount'
> & { merchants: Array<{ __typename?: 'Merchant' } & MerchantFieldsFragment> };

export type TimeIntervalFieldsFragment = { __typename?: 'TimeInterval' } & Pick<
  TimeInterval,
  'from' | 'to'
>;

export type UserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'name' | 'email' | 'phone' | 'locked' | 'createdAt'
>;

export type WalletFieldsFragment = { __typename?: 'Wallet' } & Pick<
  Wallet,
  'id'
> & {
    activation?: Maybe<
      { __typename?: 'WalletActivation' } & WalletActivationFieldsFragment
    >;
    credit?: Maybe<{ __typename?: 'Money' } & MoneyFieldsFragment>;
  };

export type WalletActivationFieldsFragment = {
  __typename?: 'WalletActivation';
} & Pick<
  WalletActivation,
  | 'name'
  | 'phoneNumber'
  | 'status'
  | 'country'
  | 'cashtag'
  | 'idType'
  | 'idNumber'
  | 'idPhotos'
>;

export type AddOrUpdateMerchantCategoryMutationVariables = Exact<{
  input?: Maybe<MerchantCategoryInput>;
}>;

export type AddOrUpdateMerchantCategoryMutation = {
  __typename?: 'RootMutationType';
} & {
  addOrUpdateMerchantCategory: {
    __typename?: 'MerchantCategory';
  } & MerchantCategoryFieldsFragment;
};

export type ChangeCustomerWalletStateMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<WalletActivationStatus>;
}>;

export type ChangeCustomerWalletStateMutation = {
  __typename?: 'RootMutationType';
} & {
  changeCustomerWalletState?: Maybe<
    { __typename?: 'WalletActivation' } & WalletActivationFieldsFragment
  >;
};

export type ChangeMerchantApprovalStateMutationVariables = Exact<{
  id: Scalars['ID'];
  changeMerchantApprovalStateInput?: Maybe<ChangeMerchantApprovalStateInput>;
}>;

export type ChangeMerchantApprovalStateMutation = {
  __typename?: 'RootMutationType';
} & {
  changeMerchantApprovalState?: Maybe<
    { __typename?: 'Merchant' } & MerchantFieldsFragment
  >;
};

export type RemoveMerchantCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveMerchantCategoryMutation = {
  __typename?: 'RootMutationType';
} & Pick<RootMutationType, 'removeMerchantCategory'>;

export type SendDeliveryRequestMutationVariables = Exact<{
  input: DeliveryRequestInput;
}>;

export type SendDeliveryRequestMutation = {
  __typename?: 'RootMutationType';
} & Pick<RootMutationType, 'sendDeliveryRequest'>;

export type UpdateMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
  merchantUpdateInput?: Maybe<MerchantUpdateInput>;
}>;

export type UpdateMerchantMutation = { __typename?: 'RootMutationType' } & {
  updateMerchant?: Maybe<{ __typename?: 'Merchant' } & MerchantFieldsFragment>;
};

export type UpdateUserMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<UpdateUserInput>;
}>;

export type UpdateUserMutation = { __typename?: 'RootMutationType' } & {
  updateUser?: Maybe<
    | ({ __typename?: 'Customer' } & ActorFields_Customer_Fragment)
    | ({ __typename?: 'Merchant' } & ActorFields_Merchant_Fragment)
    | ({ __typename?: 'Courier' } & ActorFields_Courier_Fragment)
    | ({ __typename?: 'Admin' } & ActorFields_Admin_Fragment)
  >;
};

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput;
}>;

export type UpdateUserPasswordMutation = { __typename?: 'RootMutationType' } & {
  updateUserPassword?: Maybe<
    | ({ __typename?: 'Customer' } & ActorFields_Customer_Fragment)
    | ({ __typename?: 'Merchant' } & ActorFields_Merchant_Fragment)
    | ({ __typename?: 'Courier' } & ActorFields_Courier_Fragment)
    | ({ __typename?: 'Admin' } & ActorFields_Admin_Fragment)
  >;
};

export type CourierDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CourierDetailsQuery = { __typename?: 'RootQueryType' } & {
  courier: { __typename?: 'Courier' } & CourierDetailsFieldsFragment;
};

export type CouriersQueryVariables = Exact<{
  input?: Maybe<CourierSelectionInput>;
}>;

export type CouriersQuery = { __typename?: 'RootQueryType' } & {
  couriers: { __typename?: 'CouriersList' } & Pick<
    CouriersList,
    'totalCount'
  > & { couriers: Array<{ __typename?: 'Courier' } & CourierFieldsFragment> };
};

export type CouriersNearDeliveryQueryVariables = Exact<{
  deliveryId: Scalars['ID'];
  radiusInMeters: Scalars['Int'];
}>;

export type CouriersNearDeliveryQuery = { __typename?: 'RootQueryType' } & {
  couriersNearDelivery: Array<
    { __typename?: 'CourierWithDistance' } & Pick<
      CourierWithDistance,
      'distanceToPickUpInMeters'
    > & { courier: { __typename?: 'Courier' } & CourierFieldsFragment }
  >;
};

export type CustomersQueryVariables = Exact<{
  input?: Maybe<CustomerSelectionInput>;
}>;

export type CustomersQuery = { __typename?: 'RootQueryType' } & {
  customers?: Maybe<
    { __typename?: 'CustomersList' } & Pick<CustomersList, 'totalCount'> & {
        customers: Array<{ __typename?: 'Customer' } & CustomerFieldsFragment>;
      }
  >;
};

export type DeliveriesQueryVariables = Exact<{
  input?: Maybe<DeliverySelectionInput>;
}>;

export type DeliveriesQuery = { __typename?: 'RootQueryType' } & {
  deliveries: { __typename?: 'DeliveriesList' } & Pick<
    DeliveriesList,
    'totalCount'
  > & {
      deliveries: Array<{ __typename?: 'Delivery' } & DeliveryFieldsFragment>;
    };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'RootQueryType' } & {
  me?: Maybe<
    | ({ __typename?: 'Customer' } & ActorFields_Customer_Fragment)
    | ({ __typename?: 'Merchant' } & ActorFields_Merchant_Fragment)
    | ({ __typename?: 'Courier' } & ActorFields_Courier_Fragment)
    | ({ __typename?: 'Admin' } & ActorFields_Admin_Fragment)
  >;
};

export type MerchantCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type MerchantCategoriesQuery = { __typename?: 'RootQueryType' } & {
  merchantCategories: Array<
    { __typename?: 'MerchantCategory' } & MerchantCategoryFieldsFragment
  >;
};

export type MerchantDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MerchantDetailsQuery = { __typename?: 'RootQueryType' } & {
  merchant?: Maybe<{ __typename?: 'Merchant' } & MerchantDetailsFieldsFragment>;
};

export type MerchantsQueryVariables = Exact<{
  input?: Maybe<MerchantSelectionInput>;
}>;

export type MerchantsQuery = { __typename?: 'RootQueryType' } & {
  merchants?: Maybe<
    { __typename?: 'MerchantsList' } & Pick<MerchantsList, 'totalCount'> & {
        merchants: Array<{ __typename?: 'Merchant' } & MerchantFieldsFragment>;
      }
  >;
};

export type SearchQueryVariables = Exact<{
  searchQuery: SearchQueryInput;
}>;

export type SearchQuery = { __typename?: 'RootQueryType' } & {
  search?: Maybe<{ __typename?: 'SearchResult' } & SearchResultFieldsFragment>;
};

export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    id
    name
    email
    phone
    locked
    createdAt
  }
`;
export const WalletActivationFieldsFragmentDoc = gql`
  fragment walletActivationFields on WalletActivation {
    name
    phoneNumber
    status
    country
    cashtag
    idType
    idNumber
    idPhotos
  }
`;
export const MoneyFieldsFragmentDoc = gql`
  fragment moneyFields on Money {
    amountInCents
    currencyCode
  }
`;
export const WalletFieldsFragmentDoc = gql`
  fragment walletFields on Wallet {
    id
    activation {
      ...walletActivationFields
    }
    credit {
      ...moneyFields
    }
  }
  ${WalletActivationFieldsFragmentDoc}
  ${MoneyFieldsFragmentDoc}
`;
export const ImageFieldsFragmentDoc = gql`
  fragment imageFields on Image {
    id
    small
    medium
    large
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment addressFields on Address {
    id
    location {
      formattedAddress
      coordinates {
        lat
        long
      }
    }
    pickup
  }
`;
export const CustomerFieldsFragmentDoc = gql`
  fragment customerFields on Customer {
    id
    tag
    user {
      ...userFields
    }
    wallet {
      ...walletFields
    }
    avatar {
      ...imageFields
    }
    addresses {
      ...addressFields
    }
    currentAddress {
      ...addressFields
    }
    createdAt
  }
  ${UserFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const MeFieldsFragmentDoc = gql`
  fragment meFields on Admin {
    id
    user {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const TimeIntervalFieldsFragmentDoc = gql`
  fragment timeIntervalFields on TimeInterval {
    from
    to
  }
`;
export const OperatingTimeFieldsFragmentDoc = gql`
  fragment operatingTimeFields on OperatingTime {
    mo {
      ...timeIntervalFields
    }
    tu {
      ...timeIntervalFields
    }
    we {
      ...timeIntervalFields
    }
    th {
      ...timeIntervalFields
    }
    fr {
      ...timeIntervalFields
    }
    sa {
      ...timeIntervalFields
    }
    su {
      ...timeIntervalFields
    }
  }
  ${TimeIntervalFieldsFragmentDoc}
`;
export const ProductCategoryFieldsFragmentDoc = gql`
  fragment productCategoryFields on ProductCategory {
    id
    name
    children {
      id
      name
    }
  }
`;
export const MerchantCategoryFieldsFragmentDoc = gql`
  fragment merchantCategoryFields on MerchantCategory {
    id
    name
    image {
      ...imageFields
    }
    children {
      id
      name
      image {
        ...imageFields
      }
      parent {
        id
        name
        image {
          ...imageFields
        }
      }
      children {
        id
        name
        image {
          ...imageFields
        }
        parent {
          id
          name
          image {
            ...imageFields
          }
          parent {
            id
            name
            image {
              ...imageFields
            }
          }
        }
      }
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const MerchantFieldsFragmentDoc = gql`
  fragment merchantFields on Merchant {
    id
    name
    description
    shortDescription
    website
    user {
      ...userFields
    }
    approvalState
    approvalMessage
    createdAt
    selection
    addresses {
      ...addressFields
    }
    operatingTime {
      ...operatingTimeFields
    }
    logo {
      ...imageFields
    }
    coverImage {
      ...imageFields
    }
    productCategories {
      ...productCategoryFields
    }
    merchantCategories {
      ...merchantCategoryFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${OperatingTimeFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${ProductCategoryFieldsFragmentDoc}
  ${MerchantCategoryFieldsFragmentDoc}
`;
export const OrderBatchFieldsFragmentDoc = gql`
  fragment orderBatchFields on OrderBatch {
    id
    referenceNumber
    payment {
      date
    }
  }
`;
export const CourierDeliveryFieldsFragmentDoc = gql`
  fragment courierDeliveryFields on Delivery {
    id
    orderBatch {
      ...orderBatchFields
    }
    pickUp {
      ...addressFields
    }
    dropOff {
      ...addressFields
    }
    status
  }
  ${OrderBatchFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const CourierFieldsFragmentDoc = gql`
  fragment courierFields on Courier {
    id
    user {
      ...userFields
    }
    online
    onlineStatusChangedAt
    currentPosition {
      lat
      long
    }
    createdAt
    avatar {
      ...imageFields
    }
    currentDelivery {
      ...courierDeliveryFields
    }
    assignedDeliveries {
      ...courierDeliveryFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${CourierDeliveryFieldsFragmentDoc}
`;
export const ActorFieldsFragmentDoc = gql`
  fragment actorFields on Actor {
    ...customerFields
    ...meFields
    ...merchantFields
    ...courierFields
  }
  ${CustomerFieldsFragmentDoc}
  ${MeFieldsFragmentDoc}
  ${MerchantFieldsFragmentDoc}
  ${CourierFieldsFragmentDoc}
`;
export const CourierDetailsFieldsFragmentDoc = gql`
  fragment courierDetailsFields on Courier {
    id
    name
    online
    avatar {
      ...imageFields
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const DeliveryFieldsFragmentDoc = gql`
  fragment deliveryFields on Delivery {
    id
    courier {
      ...courierFields
    }
    orderBatch {
      ...orderBatchFields
    }
    pickUp {
      ...addressFields
    }
    dropOff {
      ...addressFields
    }
    status
  }
  ${CourierFieldsFragmentDoc}
  ${OrderBatchFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const ProductOptionsFieldsFragmentDoc = gql`
  fragment productOptionsFields on ProductOption {
    id
    name
    values
    position
  }
`;
export const ProductVariantFieldsFragmentDoc = gql`
  fragment productVariantFields on ProductVariant {
    id
    price {
      ...moneyFields
    }
    title
    images {
      ...imageFields
    }
    sku
    default
    option {
      name
      value
    }
    description
  }
  ${MoneyFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment productFields on Product {
    id
    title
    active
    fulfillment
    options {
      ...productOptionsFields
    }
    variants {
      ...productVariantFields
    }
    categories {
      ...productCategoryFields
    }
    deleted
    deletedAt
  }
  ${ProductOptionsFieldsFragmentDoc}
  ${ProductVariantFieldsFragmentDoc}
  ${ProductCategoryFieldsFragmentDoc}
`;
export const ProductListFieldsFragmentDoc = gql`
  fragment productListFields on ProductList {
    total
    items {
      ...productFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;
export const MerchantDetailsFieldsFragmentDoc = gql`
  fragment merchantDetailsFields on Merchant {
    id
    name
    description
    website
    phone
    email
    addresses {
      ...addressFields
    }
    operatingTime {
      ...operatingTimeFields
    }
    logo {
      ...imageFields
    }
    coverImage {
      ...imageFields
    }
    productCategories {
      ...productCategoryFields
    }
    merchantCategories {
      ...merchantCategoryFields
    }
    products {
      ...productListFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${OperatingTimeFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${ProductCategoryFieldsFragmentDoc}
  ${MerchantCategoryFieldsFragmentDoc}
  ${ProductListFieldsFragmentDoc}
`;
export const SearchResultFieldsFragmentDoc = gql`
  fragment searchResultFields on SearchResult {
    merchants {
      ...merchantFields
    }
    totalCount
  }
  ${MerchantFieldsFragmentDoc}
`;
export const AddOrUpdateMerchantCategoryDocument = gql`
  mutation AddOrUpdateMerchantCategory($input: MerchantCategoryInput) {
    addOrUpdateMerchantCategory(input: $input) {
      ...merchantCategoryFields
    }
  }
  ${MerchantCategoryFieldsFragmentDoc}
`;
export type AddOrUpdateMerchantCategoryMutationFn = Apollo.MutationFunction<
  AddOrUpdateMerchantCategoryMutation,
  AddOrUpdateMerchantCategoryMutationVariables
>;

/**
 * __useAddOrUpdateMerchantCategoryMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateMerchantCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateMerchantCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateMerchantCategoryMutation, { data, loading, error }] = useAddOrUpdateMerchantCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateMerchantCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrUpdateMerchantCategoryMutation,
    AddOrUpdateMerchantCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrUpdateMerchantCategoryMutation,
    AddOrUpdateMerchantCategoryMutationVariables
  >(AddOrUpdateMerchantCategoryDocument, options);
}
export type AddOrUpdateMerchantCategoryMutationHookResult = ReturnType<
  typeof useAddOrUpdateMerchantCategoryMutation
>;
export type AddOrUpdateMerchantCategoryMutationResult = Apollo.MutationResult<AddOrUpdateMerchantCategoryMutation>;
export type AddOrUpdateMerchantCategoryMutationOptions = Apollo.BaseMutationOptions<
  AddOrUpdateMerchantCategoryMutation,
  AddOrUpdateMerchantCategoryMutationVariables
>;
export const ChangeCustomerWalletStateDocument = gql`
  mutation ChangeCustomerWalletState($id: ID, $state: WalletActivationStatus) {
    changeCustomerWalletState(id: $id, state: $state) {
      ...walletActivationFields
    }
  }
  ${WalletActivationFieldsFragmentDoc}
`;
export type ChangeCustomerWalletStateMutationFn = Apollo.MutationFunction<
  ChangeCustomerWalletStateMutation,
  ChangeCustomerWalletStateMutationVariables
>;

/**
 * __useChangeCustomerWalletStateMutation__
 *
 * To run a mutation, you first call `useChangeCustomerWalletStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCustomerWalletStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCustomerWalletStateMutation, { data, loading, error }] = useChangeCustomerWalletStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useChangeCustomerWalletStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeCustomerWalletStateMutation,
    ChangeCustomerWalletStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeCustomerWalletStateMutation,
    ChangeCustomerWalletStateMutationVariables
  >(ChangeCustomerWalletStateDocument, options);
}
export type ChangeCustomerWalletStateMutationHookResult = ReturnType<
  typeof useChangeCustomerWalletStateMutation
>;
export type ChangeCustomerWalletStateMutationResult = Apollo.MutationResult<ChangeCustomerWalletStateMutation>;
export type ChangeCustomerWalletStateMutationOptions = Apollo.BaseMutationOptions<
  ChangeCustomerWalletStateMutation,
  ChangeCustomerWalletStateMutationVariables
>;
export const ChangeMerchantApprovalStateDocument = gql`
  mutation ChangeMerchantApprovalState(
    $id: ID!
    $changeMerchantApprovalStateInput: ChangeMerchantApprovalStateInput
  ) {
    changeMerchantApprovalState(
      id: $id
      input: $changeMerchantApprovalStateInput
    ) {
      ...merchantFields
    }
  }
  ${MerchantFieldsFragmentDoc}
`;
export type ChangeMerchantApprovalStateMutationFn = Apollo.MutationFunction<
  ChangeMerchantApprovalStateMutation,
  ChangeMerchantApprovalStateMutationVariables
>;

/**
 * __useChangeMerchantApprovalStateMutation__
 *
 * To run a mutation, you first call `useChangeMerchantApprovalStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMerchantApprovalStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMerchantApprovalStateMutation, { data, loading, error }] = useChangeMerchantApprovalStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changeMerchantApprovalStateInput: // value for 'changeMerchantApprovalStateInput'
 *   },
 * });
 */
export function useChangeMerchantApprovalStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeMerchantApprovalStateMutation,
    ChangeMerchantApprovalStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeMerchantApprovalStateMutation,
    ChangeMerchantApprovalStateMutationVariables
  >(ChangeMerchantApprovalStateDocument, options);
}
export type ChangeMerchantApprovalStateMutationHookResult = ReturnType<
  typeof useChangeMerchantApprovalStateMutation
>;
export type ChangeMerchantApprovalStateMutationResult = Apollo.MutationResult<ChangeMerchantApprovalStateMutation>;
export type ChangeMerchantApprovalStateMutationOptions = Apollo.BaseMutationOptions<
  ChangeMerchantApprovalStateMutation,
  ChangeMerchantApprovalStateMutationVariables
>;
export const RemoveMerchantCategoryDocument = gql`
  mutation RemoveMerchantCategory($id: ID!) {
    removeMerchantCategory(id: $id)
  }
`;
export type RemoveMerchantCategoryMutationFn = Apollo.MutationFunction<
  RemoveMerchantCategoryMutation,
  RemoveMerchantCategoryMutationVariables
>;

/**
 * __useRemoveMerchantCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveMerchantCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMerchantCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMerchantCategoryMutation, { data, loading, error }] = useRemoveMerchantCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMerchantCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMerchantCategoryMutation,
    RemoveMerchantCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMerchantCategoryMutation,
    RemoveMerchantCategoryMutationVariables
  >(RemoveMerchantCategoryDocument, options);
}
export type RemoveMerchantCategoryMutationHookResult = ReturnType<
  typeof useRemoveMerchantCategoryMutation
>;
export type RemoveMerchantCategoryMutationResult = Apollo.MutationResult<RemoveMerchantCategoryMutation>;
export type RemoveMerchantCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveMerchantCategoryMutation,
  RemoveMerchantCategoryMutationVariables
>;
export const SendDeliveryRequestDocument = gql`
  mutation SendDeliveryRequest($input: DeliveryRequestInput!) {
    sendDeliveryRequest(input: $input)
  }
`;
export type SendDeliveryRequestMutationFn = Apollo.MutationFunction<
  SendDeliveryRequestMutation,
  SendDeliveryRequestMutationVariables
>;

/**
 * __useSendDeliveryRequestMutation__
 *
 * To run a mutation, you first call `useSendDeliveryRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeliveryRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeliveryRequestMutation, { data, loading, error }] = useSendDeliveryRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDeliveryRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDeliveryRequestMutation,
    SendDeliveryRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendDeliveryRequestMutation,
    SendDeliveryRequestMutationVariables
  >(SendDeliveryRequestDocument, options);
}
export type SendDeliveryRequestMutationHookResult = ReturnType<
  typeof useSendDeliveryRequestMutation
>;
export type SendDeliveryRequestMutationResult = Apollo.MutationResult<SendDeliveryRequestMutation>;
export type SendDeliveryRequestMutationOptions = Apollo.BaseMutationOptions<
  SendDeliveryRequestMutation,
  SendDeliveryRequestMutationVariables
>;
export const UpdateMerchantDocument = gql`
  mutation UpdateMerchant($id: ID!, $merchantUpdateInput: MerchantUpdateInput) {
    updateMerchant(id: $id, input: $merchantUpdateInput) {
      ...merchantFields
    }
  }
  ${MerchantFieldsFragmentDoc}
`;
export type UpdateMerchantMutationFn = Apollo.MutationFunction<
  UpdateMerchantMutation,
  UpdateMerchantMutationVariables
>;

/**
 * __useUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantMutation, { data, loading, error }] = useUpdateMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      merchantUpdateInput: // value for 'merchantUpdateInput'
 *   },
 * });
 */
export function useUpdateMerchantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMerchantMutation,
    UpdateMerchantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMerchantMutation,
    UpdateMerchantMutationVariables
  >(UpdateMerchantDocument, options);
}
export type UpdateMerchantMutationHookResult = ReturnType<
  typeof useUpdateMerchantMutation
>;
export type UpdateMerchantMutationResult = Apollo.MutationResult<UpdateMerchantMutation>;
export type UpdateMerchantMutationOptions = Apollo.BaseMutationOptions<
  UpdateMerchantMutation,
  UpdateMerchantMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID, $input: UpdateUserInput) {
    updateUser(id: $id, input: $input) {
      ...actorFields
    }
  }
  ${ActorFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      ...actorFields
    }
  }
  ${ActorFieldsFragmentDoc}
`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument, options);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
  typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const CourierDetailsDocument = gql`
  query CourierDetails($id: ID!) {
    courier(id: $id) {
      ...courierDetailsFields
    }
  }
  ${CourierDetailsFieldsFragmentDoc}
`;

/**
 * __useCourierDetailsQuery__
 *
 * To run a query within a React component, call `useCourierDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourierDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierDetailsQuery,
    CourierDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourierDetailsQuery, CourierDetailsQueryVariables>(
    CourierDetailsDocument,
    options,
  );
}
export function useCourierDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierDetailsQuery,
    CourierDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourierDetailsQuery, CourierDetailsQueryVariables>(
    CourierDetailsDocument,
    options,
  );
}
export type CourierDetailsQueryHookResult = ReturnType<
  typeof useCourierDetailsQuery
>;
export type CourierDetailsLazyQueryHookResult = ReturnType<
  typeof useCourierDetailsLazyQuery
>;
export type CourierDetailsQueryResult = Apollo.QueryResult<
  CourierDetailsQuery,
  CourierDetailsQueryVariables
>;
export const CouriersDocument = gql`
  query Couriers($input: CourierSelectionInput) {
    couriers(input: $input) {
      couriers {
        ...courierFields
      }
      totalCount
    }
  }
  ${CourierFieldsFragmentDoc}
`;

/**
 * __useCouriersQuery__
 *
 * To run a query within a React component, call `useCouriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouriersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCouriersQuery(
  baseOptions?: Apollo.QueryHookOptions<CouriersQuery, CouriersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouriersQuery, CouriersQueryVariables>(
    CouriersDocument,
    options,
  );
}
export function useCouriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouriersQuery,
    CouriersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouriersQuery, CouriersQueryVariables>(
    CouriersDocument,
    options,
  );
}
export type CouriersQueryHookResult = ReturnType<typeof useCouriersQuery>;
export type CouriersLazyQueryHookResult = ReturnType<
  typeof useCouriersLazyQuery
>;
export type CouriersQueryResult = Apollo.QueryResult<
  CouriersQuery,
  CouriersQueryVariables
>;
export const CouriersNearDeliveryDocument = gql`
  query CouriersNearDelivery($deliveryId: ID!, $radiusInMeters: Int!) {
    couriersNearDelivery(
      deliveryId: $deliveryId
      radiusInMeters: $radiusInMeters
    ) {
      courier {
        ...courierFields
      }
      distanceToPickUpInMeters
    }
  }
  ${CourierFieldsFragmentDoc}
`;

/**
 * __useCouriersNearDeliveryQuery__
 *
 * To run a query within a React component, call `useCouriersNearDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouriersNearDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouriersNearDeliveryQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *      radiusInMeters: // value for 'radiusInMeters'
 *   },
 * });
 */
export function useCouriersNearDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CouriersNearDeliveryQuery,
    CouriersNearDeliveryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CouriersNearDeliveryQuery,
    CouriersNearDeliveryQueryVariables
  >(CouriersNearDeliveryDocument, options);
}
export function useCouriersNearDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouriersNearDeliveryQuery,
    CouriersNearDeliveryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CouriersNearDeliveryQuery,
    CouriersNearDeliveryQueryVariables
  >(CouriersNearDeliveryDocument, options);
}
export type CouriersNearDeliveryQueryHookResult = ReturnType<
  typeof useCouriersNearDeliveryQuery
>;
export type CouriersNearDeliveryLazyQueryHookResult = ReturnType<
  typeof useCouriersNearDeliveryLazyQuery
>;
export type CouriersNearDeliveryQueryResult = Apollo.QueryResult<
  CouriersNearDeliveryQuery,
  CouriersNearDeliveryQueryVariables
>;
export const CustomersDocument = gql`
  query Customers($input: CustomerSelectionInput) {
    customers(input: $input) {
      customers {
        ...customerFields
      }
      totalCount
    }
  }
  ${CustomerFieldsFragmentDoc}
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options,
  );
}
export function useCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options,
  );
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<
  typeof useCustomersLazyQuery
>;
export type CustomersQueryResult = Apollo.QueryResult<
  CustomersQuery,
  CustomersQueryVariables
>;
export const DeliveriesDocument = gql`
  query Deliveries($input: DeliverySelectionInput) {
    deliveries(input: $input) {
      deliveries {
        ...deliveryFields
      }
      totalCount
    }
  }
  ${DeliveryFieldsFragmentDoc}
`;

/**
 * __useDeliveriesQuery__
 *
 * To run a query within a React component, call `useDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliveriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeliveriesQuery,
    DeliveriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeliveriesQuery, DeliveriesQueryVariables>(
    DeliveriesDocument,
    options,
  );
}
export function useDeliveriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliveriesQuery,
    DeliveriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeliveriesQuery, DeliveriesQueryVariables>(
    DeliveriesDocument,
    options,
  );
}
export type DeliveriesQueryHookResult = ReturnType<typeof useDeliveriesQuery>;
export type DeliveriesLazyQueryHookResult = ReturnType<
  typeof useDeliveriesLazyQuery
>;
export type DeliveriesQueryResult = Apollo.QueryResult<
  DeliveriesQuery,
  DeliveriesQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...actorFields
    }
  }
  ${ActorFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MerchantCategoriesDocument = gql`
  query MerchantCategories {
    merchantCategories {
      ...merchantCategoryFields
    }
  }
  ${MerchantCategoryFieldsFragmentDoc}
`;

/**
 * __useMerchantCategoriesQuery__
 *
 * To run a query within a React component, call `useMerchantCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MerchantCategoriesQuery,
    MerchantCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MerchantCategoriesQuery,
    MerchantCategoriesQueryVariables
  >(MerchantCategoriesDocument, options);
}
export function useMerchantCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantCategoriesQuery,
    MerchantCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MerchantCategoriesQuery,
    MerchantCategoriesQueryVariables
  >(MerchantCategoriesDocument, options);
}
export type MerchantCategoriesQueryHookResult = ReturnType<
  typeof useMerchantCategoriesQuery
>;
export type MerchantCategoriesLazyQueryHookResult = ReturnType<
  typeof useMerchantCategoriesLazyQuery
>;
export type MerchantCategoriesQueryResult = Apollo.QueryResult<
  MerchantCategoriesQuery,
  MerchantCategoriesQueryVariables
>;
export const MerchantDetailsDocument = gql`
  query MerchantDetails($id: ID!) {
    merchant(id: $id) {
      ...merchantDetailsFields
    }
  }
  ${MerchantDetailsFieldsFragmentDoc}
`;

/**
 * __useMerchantDetailsQuery__
 *
 * To run a query within a React component, call `useMerchantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MerchantDetailsQuery,
    MerchantDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MerchantDetailsQuery, MerchantDetailsQueryVariables>(
    MerchantDetailsDocument,
    options,
  );
}
export function useMerchantDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantDetailsQuery,
    MerchantDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MerchantDetailsQuery,
    MerchantDetailsQueryVariables
  >(MerchantDetailsDocument, options);
}
export type MerchantDetailsQueryHookResult = ReturnType<
  typeof useMerchantDetailsQuery
>;
export type MerchantDetailsLazyQueryHookResult = ReturnType<
  typeof useMerchantDetailsLazyQuery
>;
export type MerchantDetailsQueryResult = Apollo.QueryResult<
  MerchantDetailsQuery,
  MerchantDetailsQueryVariables
>;
export const MerchantsDocument = gql`
  query Merchants($input: MerchantSelectionInput) {
    merchants(input: $input) {
      merchants {
        ...merchantFields
      }
      totalCount
    }
  }
  ${MerchantFieldsFragmentDoc}
`;

/**
 * __useMerchantsQuery__
 *
 * To run a query within a React component, call `useMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMerchantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MerchantsQuery,
    MerchantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MerchantsQuery, MerchantsQueryVariables>(
    MerchantsDocument,
    options,
  );
}
export function useMerchantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantsQuery,
    MerchantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MerchantsQuery, MerchantsQueryVariables>(
    MerchantsDocument,
    options,
  );
}
export type MerchantsQueryHookResult = ReturnType<typeof useMerchantsQuery>;
export type MerchantsLazyQueryHookResult = ReturnType<
  typeof useMerchantsLazyQuery
>;
export type MerchantsQueryResult = Apollo.QueryResult<
  MerchantsQuery,
  MerchantsQueryVariables
>;
export const SearchDocument = gql`
  query Search($searchQuery: SearchQueryInput!) {
    search(searchQuery: $searchQuery) {
      ...searchResultFields
    }
  }
  ${SearchResultFieldsFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options,
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options,
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AcceptPaymentRequestInput: AcceptPaymentRequestInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Actor:
    | ResolversTypes['Customer']
    | ResolversTypes['Merchant']
    | ResolversTypes['Courier']
    | ResolversTypes['Admin'];
  AddProductToWishlistInput: AddProductToWishlistInput;
  Address: ResolverTypeWrapper<Address>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AddressInput: AddressInput;
  Admin: ResolverTypeWrapper<Admin>;
  ApprovalState: ApprovalState;
  AuthProvider: AuthProvider;
  AuthenticationType: AuthenticationType;
  Bill: ResolverTypeWrapper<Bill>;
  BillTransaction: ResolverTypeWrapper<
    Omit<BillTransaction, 'issuer'> & {
      issuer?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  Biller: Biller;
  BillingInfoInput: BillingInfoInput;
  BiometricsTokenInput: BiometricsTokenInput;
  CallbackTransaction: ResolverTypeWrapper<CallbackTransaction>;
  CardType: CardType;
  CategorizedMerchants: ResolverTypeWrapper<CategorizedMerchants>;
  ChangeMerchantApprovalStateInput: ChangeMerchantApprovalStateInput;
  Chat: ResolverTypeWrapper<
    Omit<Chat, 'members'> & { members: Array<ResolversTypes['Actor']> }
  >;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ChatEdge: ResolverTypeWrapper<ChatEdge>;
  ChatType: ChatType;
  ChatsCursor: ResolverTypeWrapper<ChatsCursor>;
  Country: Country;
  Courier: ResolverTypeWrapper<Courier>;
  CourierLastLocationInput: CourierLastLocationInput;
  CourierSelectionInput: CourierSelectionInput;
  CourierWithDistance: ResolverTypeWrapper<CourierWithDistance>;
  CouriersList: ResolverTypeWrapper<CouriersList>;
  CouriersSearchResult: ResolverTypeWrapper<CouriersSearchResult>;
  CreateInviteInput: CreateInviteInput;
  CreateWishlistInput: CreateWishlistInput;
  CreditCard: ResolverTypeWrapper<CreditCard>;
  CreditCardInput: CreditCardInput;
  CurrencyCode: CurrencyCode;
  CursorInput: CursorInput;
  Customer: ResolverTypeWrapper<Customer>;
  CustomerSelectionInput: CustomerSelectionInput;
  CustomerUpdateInput: CustomerUpdateInput;
  CustomersList: ResolverTypeWrapper<CustomersList>;
  CustomersSearchResult: ResolverTypeWrapper<CustomersSearchResult>;
  CybersourceDepositInput: CybersourceDepositInput;
  CybersourceDeviceCollectionJWT: ResolverTypeWrapper<CybersourceDeviceCollectionJwt>;
  CybersourceJWT:
    | ResolversTypes['CybersourceDeviceCollectionJWT']
    | ResolversTypes['CybersourceStepupJWT'];
  CybersourceStepupJWT: ResolverTypeWrapper<CybersourceStepupJwt>;
  DateInterval: DateInterval;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeliveriesList: ResolverTypeWrapper<DeliveriesList>;
  DeliveriesSearchResult: ResolverTypeWrapper<DeliveriesSearchResult>;
  Delivery: ResolverTypeWrapper<Delivery>;
  DeliveryFilterInput: DeliveryFilterInput;
  DeliveryRequest: ResolverTypeWrapper<DeliveryRequest>;
  DeliveryRequestInput: DeliveryRequestInput;
  DeliverySelectionInput: DeliverySelectionInput;
  DeliveryStatus: DeliveryStatus;
  DeliveryStatusUpdate: ResolverTypeWrapper<DeliveryStatusUpdate>;
  DepositInput: DepositInput;
  DistanceFilterInput: DistanceFilterInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Error: ResolversTypes['TransactionError'] | ResolversTypes['ValidationError'];
  ErrorTypes: ErrorTypes;
  Event:
    | ResolversTypes['DeliveryStatusUpdate']
    | ResolversTypes['PaymentRequestEvent']
    | ResolversTypes['SystemNotification']
    | ResolversTypes['TransactionEvent'];
  EventType: EventType;
  EventsInput: EventsInput;
  EventsList: ResolverTypeWrapper<EventsList>;
  ExternalAccount: ResolverTypeWrapper<ExternalAccount>;
  ExternalAccountInput: ExternalAccountInput;
  FetchBillInput: FetchBillInput;
  FreeMoneyDev: ResolverTypeWrapper<FreeMoneyDev>;
  Fulfillment: Fulfillment;
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>;
  GeoCoordinatesInput: GeoCoordinatesInput;
  IdentityType: IdentityType;
  Image: ResolverTypeWrapper<Image>;
  Individual: ResolverTypeWrapper<Individual>;
  IndividualInput: IndividualInput;
  InterswitchBank: ResolverTypeWrapper<InterswitchBank>;
  InterswitchBankAccount: ResolverTypeWrapper<InterswitchBankAccount>;
  InterswitchBankAccountInput: InterswitchBankAccountInput;
  Invitation: ResolverTypeWrapper<Invitation>;
  LimitedCompany: ResolverTypeWrapper<LimitedCompany>;
  LimitedCompanyInput: LimitedCompanyInput;
  LoadCreditTransaction: ResolverTypeWrapper<
    Omit<LoadCreditTransaction, 'receiver'> & {
      receiver?: Maybe<ResolversTypes['Actor']>;
    }
  >;
  LoadWalletInput: LoadWalletInput;
  Location: ResolverTypeWrapper<Location>;
  Login: ResolverTypeWrapper<
    Omit<Login, 'user'> & { user?: Maybe<ResolversTypes['Actor']> }
  >;
  LoginInput: LoginInput;
  LoginWithCodeInput: LoginWithCodeInput;
  LoginWithProviderInput: LoginWithProviderInput;
  LogoutInput: LogoutInput;
  Media: ResolverTypeWrapper<Media>;
  Merchant: ResolverTypeWrapper<
    Omit<Merchant, 'type'> & { type?: Maybe<ResolversTypes['MerchantType']> }
  >;
  MerchantCategory: ResolverTypeWrapper<MerchantCategory>;
  MerchantCategoryInput: MerchantCategoryInput;
  MerchantFilterInput: MerchantFilterInput;
  MerchantSelection: MerchantSelection;
  MerchantSelectionInput: MerchantSelectionInput;
  MerchantType:
    | ResolversTypes['Individual']
    | ResolversTypes['SoleProprietor']
    | ResolversTypes['LimitedCompany']
    | ResolversTypes['Partnership'];
  MerchantTypeInput: MerchantTypeInput;
  MerchantUpdateInput: MerchantUpdateInput;
  MerchantWithDistance: ResolverTypeWrapper<MerchantWithDistance>;
  MerchantsList: ResolverTypeWrapper<MerchantsList>;
  MerchantsSearchResult: ResolverTypeWrapper<MerchantsSearchResult>;
  Message: ResolverTypeWrapper<
    Omit<Message, 'author'> & { author: ResolversTypes['Actor'] }
  >;
  MessageEdge: ResolverTypeWrapper<MessageEdge>;
  MessagesCursor: ResolverTypeWrapper<MessagesCursor>;
  Money: ResolverTypeWrapper<Money>;
  MoneyInput: MoneyInput;
  NaiveDateTime: ResolverTypeWrapper<Scalars['NaiveDateTime']>;
  NewMessage: ResolverTypeWrapper<NewMessage>;
  NotificationChannel: ResolverTypeWrapper<NotificationChannel>;
  NotificationChannelInput: NotificationChannelInput;
  NotificationSettings: ResolverTypeWrapper<NotificationSettings>;
  NotificationSettingsInput: NotificationSettingsInput;
  OperatingTime: ResolverTypeWrapper<OperatingTime>;
  OperatingTimeInput: OperatingTimeInput;
  Order: ResolverTypeWrapper<Order>;
  OrderBatch: ResolverTypeWrapper<OrderBatch>;
  OrderBatchTransaction: ResolverTypeWrapper<OrderBatchTransaction>;
  OrderFilterInput: OrderFilterInput;
  OrderFulfillment: OrderFulfillment;
  OrderInput: OrderInput;
  OrderListItem: ResolverTypeWrapper<OrderListItem>;
  OrderListItemInput: OrderListItemInput;
  OrderStatus: OrderStatus;
  OrderTracking: ResolverTypeWrapper<OrderTracking>;
  OwnProductsSearchResult: ResolverTypeWrapper<OwnProductsSearchResult>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Partnership: ResolverTypeWrapper<Partnership>;
  PartnershipInput: PartnershipInput;
  PayBillInput: PayBillInput;
  PaymentMethod:
    | ResolversTypes['CreditCard']
    | ResolversTypes['FreeMoneyDev']
    | ResolversTypes['InterswitchBankAccount']
    | ResolversTypes['PhoneNumber'];
  PaymentMethodInput: PaymentMethodInput;
  PaymentMethodType: PaymentMethodType;
  PaymentProvider: PaymentProvider;
  PaymentRequest: ResolverTypeWrapper<
    Omit<PaymentRequest, 'issuer' | 'receiver'> & {
      issuer: ResolversTypes['Actor'];
      receiver: ResolversTypes['Actor'];
    }
  >;
  PaymentRequestEvent: ResolverTypeWrapper<PaymentRequestEvent>;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  Pickup: ResolverTypeWrapper<Pickup>;
  PickupFilterInput: PickupFilterInput;
  PickupStatus: PickupStatus;
  Platform: Platform;
  Product: ResolverTypeWrapper<Product>;
  ProductCategory: ResolverTypeWrapper<ProductCategory>;
  ProductCategoryInput: ProductCategoryInput;
  ProductFilterInput: ProductFilterInput;
  ProductInput: ProductInput;
  ProductList: ResolverTypeWrapper<ProductList>;
  ProductOption: ResolverTypeWrapper<ProductOption>;
  ProductOptionInput: ProductOptionInput;
  ProductSelectionInput: ProductSelectionInput;
  ProductSortOptionsInput: ProductSortOptionsInput;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  ProductVariantInput: ProductVariantInput;
  PushNotificationTokenInput: PushNotificationTokenInput;
  ReactToDeliveryRequestInput: ReactToDeliveryRequestInput;
  RecentTransactionsInput: RecentTransactionsInput;
  RefreshTokenInput: RefreshTokenInput;
  RefundTransaction: ResolverTypeWrapper<RefundTransaction>;
  RegistrationStatusType: RegistrationStatusType;
  RemoveProductFromWishlistInput: RemoveProductFromWishlistInput;
  RequestLoginCodeInput: RequestLoginCodeInput;
  RequestPaymentInput: RequestPaymentInput;
  RequestWalletActivationForMerchantInput: RequestWalletActivationForMerchantInput;
  RequestWalletActivationInput: RequestWalletActivationInput;
  Review: ResolverTypeWrapper<Review>;
  ReviewInput: ReviewInput;
  Role: Role;
  RootMutationType: ResolverTypeWrapper<{}>;
  RootQueryType: ResolverTypeWrapper<{}>;
  RootSubscriptionType: ResolverTypeWrapper<{}>;
  SearchOrderInput: SearchOrderInput;
  SearchOrdersResult: ResolverTypeWrapper<SearchOrdersResult>;
  SearchQueryInput: SearchQueryInput;
  SearchResult: ResolverTypeWrapper<SearchResult>;
  SelectedOption: ResolverTypeWrapper<SelectedOption>;
  SelectedOptionInput: SelectedOptionInput;
  SendMessageInput: SendMessageInput;
  SendVerificationCodeInput: SendVerificationCodeInput;
  SetWalletPinInput: SetWalletPinInput;
  Settings: ResolverTypeWrapper<Settings>;
  SettingsInput: SettingsInput;
  SignupInput: SignupInput;
  SoleProprietor: ResolverTypeWrapper<SoleProprietor>;
  SoleProprietorInput: SoleProprietorInput;
  SortByDirection: SortByDirection;
  SystemNotification: ResolverTypeWrapper<SystemNotification>;
  TimeInHm: ResolverTypeWrapper<Scalars['TimeInHm']>;
  TimeInterval: ResolverTypeWrapper<TimeInterval>;
  TimeIntervalInput: TimeIntervalInput;
  Transaction:
    | ResolversTypes['BillTransaction']
    | ResolversTypes['LoadCreditTransaction']
    | ResolversTypes['OrderBatchTransaction']
    | ResolversTypes['RefundTransaction']
    | ResolversTypes['TransferTransaction']
    | ResolversTypes['WithdrawTransaction'];
  TransactionError: ResolverTypeWrapper<TransactionError>;
  TransactionEvent: ResolverTypeWrapper<TransactionEvent>;
  TransactionSelectionInput: TransactionSelectionInput;
  TransactionStatus: TransactionStatus;
  TransactionsList: ResolverTypeWrapper<TransactionsList>;
  TransferCreditInput: TransferCreditInput;
  TransferTransaction: ResolverTypeWrapper<
    Omit<TransferTransaction, 'issuer' | 'receiver'> & {
      issuer: ResolversTypes['Actor'];
      receiver: ResolversTypes['Actor'];
    }
  >;
  UpdateChatMembersInput: UpdateChatMembersInput;
  UpdateUserInput: UpdateUserInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  User: ResolverTypeWrapper<User>;
  UserIdentifier: UserIdentifier;
  UserRegistrationStatusInput: UserRegistrationStatusInput;
  ValidationError: ResolverTypeWrapper<ValidationError>;
  VerificationId: VerificationId;
  VerifyPhoneInput: VerifyPhoneInput;
  VerifyPinOrSignatureInput: VerifyPinOrSignatureInput;
  Wallet: ResolverTypeWrapper<Wallet>;
  WalletActivation: ResolverTypeWrapper<WalletActivation>;
  WalletActivationStatus: WalletActivationStatus;
  WalletHold: ResolverTypeWrapper<WalletHold>;
  Wishlist: ResolverTypeWrapper<Wishlist>;
  WithdrawInput: WithdrawInput;
  WithdrawTransaction: ResolverTypeWrapper<
    Omit<WithdrawTransaction, 'issuer'> & {
      issuer?: Maybe<ResolversTypes['Actor']>;
    }
  >;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AcceptPaymentRequestInput: AcceptPaymentRequestInput;
  ID: Scalars['ID'];
  String: Scalars['String'];
  Actor:
    | ResolversParentTypes['Customer']
    | ResolversParentTypes['Merchant']
    | ResolversParentTypes['Courier']
    | ResolversParentTypes['Admin'];
  AddProductToWishlistInput: AddProductToWishlistInput;
  Address: Address;
  Boolean: Scalars['Boolean'];
  AddressInput: AddressInput;
  Admin: Admin;
  Bill: Bill;
  BillTransaction: Omit<BillTransaction, 'issuer'> & {
    issuer?: Maybe<ResolversParentTypes['Actor']>;
  };
  BillingInfoInput: BillingInfoInput;
  BiometricsTokenInput: BiometricsTokenInput;
  CallbackTransaction: CallbackTransaction;
  CategorizedMerchants: CategorizedMerchants;
  ChangeMerchantApprovalStateInput: ChangeMerchantApprovalStateInput;
  Chat: Omit<Chat, 'members'> & {
    members: Array<ResolversParentTypes['Actor']>;
  };
  Int: Scalars['Int'];
  ChatEdge: ChatEdge;
  ChatsCursor: ChatsCursor;
  Courier: Courier;
  CourierLastLocationInput: CourierLastLocationInput;
  CourierSelectionInput: CourierSelectionInput;
  CourierWithDistance: CourierWithDistance;
  CouriersList: CouriersList;
  CouriersSearchResult: CouriersSearchResult;
  CreateInviteInput: CreateInviteInput;
  CreateWishlistInput: CreateWishlistInput;
  CreditCard: CreditCard;
  CreditCardInput: CreditCardInput;
  CursorInput: CursorInput;
  Customer: Customer;
  CustomerSelectionInput: CustomerSelectionInput;
  CustomerUpdateInput: CustomerUpdateInput;
  CustomersList: CustomersList;
  CustomersSearchResult: CustomersSearchResult;
  CybersourceDepositInput: CybersourceDepositInput;
  CybersourceDeviceCollectionJWT: CybersourceDeviceCollectionJwt;
  CybersourceJWT:
    | ResolversParentTypes['CybersourceDeviceCollectionJWT']
    | ResolversParentTypes['CybersourceStepupJWT'];
  CybersourceStepupJWT: CybersourceStepupJwt;
  DateInterval: DateInterval;
  DateTime: Scalars['DateTime'];
  DeliveriesList: DeliveriesList;
  DeliveriesSearchResult: DeliveriesSearchResult;
  Delivery: Delivery;
  DeliveryFilterInput: DeliveryFilterInput;
  DeliveryRequest: DeliveryRequest;
  DeliveryRequestInput: DeliveryRequestInput;
  DeliverySelectionInput: DeliverySelectionInput;
  DeliveryStatusUpdate: DeliveryStatusUpdate;
  DepositInput: DepositInput;
  DistanceFilterInput: DistanceFilterInput;
  Float: Scalars['Float'];
  Error:
    | ResolversParentTypes['TransactionError']
    | ResolversParentTypes['ValidationError'];
  Event:
    | ResolversParentTypes['DeliveryStatusUpdate']
    | ResolversParentTypes['PaymentRequestEvent']
    | ResolversParentTypes['SystemNotification']
    | ResolversParentTypes['TransactionEvent'];
  EventsInput: EventsInput;
  EventsList: EventsList;
  ExternalAccount: ExternalAccount;
  ExternalAccountInput: ExternalAccountInput;
  FetchBillInput: FetchBillInput;
  FreeMoneyDev: FreeMoneyDev;
  GeoCoordinates: GeoCoordinates;
  GeoCoordinatesInput: GeoCoordinatesInput;
  Image: Image;
  Individual: Individual;
  IndividualInput: IndividualInput;
  InterswitchBank: InterswitchBank;
  InterswitchBankAccount: InterswitchBankAccount;
  InterswitchBankAccountInput: InterswitchBankAccountInput;
  Invitation: Invitation;
  LimitedCompany: LimitedCompany;
  LimitedCompanyInput: LimitedCompanyInput;
  LoadCreditTransaction: Omit<LoadCreditTransaction, 'receiver'> & {
    receiver?: Maybe<ResolversParentTypes['Actor']>;
  };
  LoadWalletInput: LoadWalletInput;
  Location: Location;
  Login: Omit<Login, 'user'> & { user?: Maybe<ResolversParentTypes['Actor']> };
  LoginInput: LoginInput;
  LoginWithCodeInput: LoginWithCodeInput;
  LoginWithProviderInput: LoginWithProviderInput;
  LogoutInput: LogoutInput;
  Media: Media;
  Merchant: Omit<Merchant, 'type'> & {
    type?: Maybe<ResolversParentTypes['MerchantType']>;
  };
  MerchantCategory: MerchantCategory;
  MerchantCategoryInput: MerchantCategoryInput;
  MerchantFilterInput: MerchantFilterInput;
  MerchantSelectionInput: MerchantSelectionInput;
  MerchantType:
    | ResolversParentTypes['Individual']
    | ResolversParentTypes['SoleProprietor']
    | ResolversParentTypes['LimitedCompany']
    | ResolversParentTypes['Partnership'];
  MerchantTypeInput: MerchantTypeInput;
  MerchantUpdateInput: MerchantUpdateInput;
  MerchantWithDistance: MerchantWithDistance;
  MerchantsList: MerchantsList;
  MerchantsSearchResult: MerchantsSearchResult;
  Message: Omit<Message, 'author'> & { author: ResolversParentTypes['Actor'] };
  MessageEdge: MessageEdge;
  MessagesCursor: MessagesCursor;
  Money: Money;
  MoneyInput: MoneyInput;
  NaiveDateTime: Scalars['NaiveDateTime'];
  NewMessage: NewMessage;
  NotificationChannel: NotificationChannel;
  NotificationChannelInput: NotificationChannelInput;
  NotificationSettings: NotificationSettings;
  NotificationSettingsInput: NotificationSettingsInput;
  OperatingTime: OperatingTime;
  OperatingTimeInput: OperatingTimeInput;
  Order: Order;
  OrderBatch: OrderBatch;
  OrderBatchTransaction: OrderBatchTransaction;
  OrderFilterInput: OrderFilterInput;
  OrderInput: OrderInput;
  OrderListItem: OrderListItem;
  OrderListItemInput: OrderListItemInput;
  OrderTracking: OrderTracking;
  OwnProductsSearchResult: OwnProductsSearchResult;
  PageInfo: PageInfo;
  Partnership: Partnership;
  PartnershipInput: PartnershipInput;
  PayBillInput: PayBillInput;
  PaymentMethod:
    | ResolversParentTypes['CreditCard']
    | ResolversParentTypes['FreeMoneyDev']
    | ResolversParentTypes['InterswitchBankAccount']
    | ResolversParentTypes['PhoneNumber'];
  PaymentMethodInput: PaymentMethodInput;
  PaymentRequest: Omit<PaymentRequest, 'issuer' | 'receiver'> & {
    issuer: ResolversParentTypes['Actor'];
    receiver: ResolversParentTypes['Actor'];
  };
  PaymentRequestEvent: PaymentRequestEvent;
  PhoneNumber: PhoneNumber;
  Pickup: Pickup;
  PickupFilterInput: PickupFilterInput;
  Product: Product;
  ProductCategory: ProductCategory;
  ProductCategoryInput: ProductCategoryInput;
  ProductFilterInput: ProductFilterInput;
  ProductInput: ProductInput;
  ProductList: ProductList;
  ProductOption: ProductOption;
  ProductOptionInput: ProductOptionInput;
  ProductSelectionInput: ProductSelectionInput;
  ProductSortOptionsInput: ProductSortOptionsInput;
  ProductVariant: ProductVariant;
  ProductVariantInput: ProductVariantInput;
  PushNotificationTokenInput: PushNotificationTokenInput;
  ReactToDeliveryRequestInput: ReactToDeliveryRequestInput;
  RecentTransactionsInput: RecentTransactionsInput;
  RefreshTokenInput: RefreshTokenInput;
  RefundTransaction: RefundTransaction;
  RemoveProductFromWishlistInput: RemoveProductFromWishlistInput;
  RequestLoginCodeInput: RequestLoginCodeInput;
  RequestPaymentInput: RequestPaymentInput;
  RequestWalletActivationForMerchantInput: RequestWalletActivationForMerchantInput;
  RequestWalletActivationInput: RequestWalletActivationInput;
  Review: Review;
  ReviewInput: ReviewInput;
  RootMutationType: {};
  RootQueryType: {};
  RootSubscriptionType: {};
  SearchOrderInput: SearchOrderInput;
  SearchOrdersResult: SearchOrdersResult;
  SearchQueryInput: SearchQueryInput;
  SearchResult: SearchResult;
  SelectedOption: SelectedOption;
  SelectedOptionInput: SelectedOptionInput;
  SendMessageInput: SendMessageInput;
  SendVerificationCodeInput: SendVerificationCodeInput;
  SetWalletPinInput: SetWalletPinInput;
  Settings: Settings;
  SettingsInput: SettingsInput;
  SignupInput: SignupInput;
  SoleProprietor: SoleProprietor;
  SoleProprietorInput: SoleProprietorInput;
  SystemNotification: SystemNotification;
  TimeInHm: Scalars['TimeInHm'];
  TimeInterval: TimeInterval;
  TimeIntervalInput: TimeIntervalInput;
  Transaction:
    | ResolversParentTypes['BillTransaction']
    | ResolversParentTypes['LoadCreditTransaction']
    | ResolversParentTypes['OrderBatchTransaction']
    | ResolversParentTypes['RefundTransaction']
    | ResolversParentTypes['TransferTransaction']
    | ResolversParentTypes['WithdrawTransaction'];
  TransactionError: TransactionError;
  TransactionEvent: TransactionEvent;
  TransactionSelectionInput: TransactionSelectionInput;
  TransactionsList: TransactionsList;
  TransferCreditInput: TransferCreditInput;
  TransferTransaction: Omit<TransferTransaction, 'issuer' | 'receiver'> & {
    issuer: ResolversParentTypes['Actor'];
    receiver: ResolversParentTypes['Actor'];
  };
  UpdateChatMembersInput: UpdateChatMembersInput;
  UpdateUserInput: UpdateUserInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  Upload: Scalars['Upload'];
  User: User;
  UserIdentifier: UserIdentifier;
  UserRegistrationStatusInput: UserRegistrationStatusInput;
  ValidationError: ValidationError;
  VerifyPhoneInput: VerifyPhoneInput;
  VerifyPinOrSignatureInput: VerifyPinOrSignatureInput;
  Wallet: Wallet;
  WalletActivation: WalletActivation;
  WalletHold: WalletHold;
  Wishlist: Wishlist;
  WithdrawInput: WithdrawInput;
  WithdrawTransaction: Omit<WithdrawTransaction, 'issuer'> & {
    issuer?: Maybe<ResolversParentTypes['Actor']>;
  };
}>;

export type ClientDirectiveArgs = {};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'Customer' | 'Merchant' | 'Courier' | 'Admin',
    ParentType,
    ContextType
  >;
}>;

export type AddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pickup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  estateName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  houseNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  floorNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BillResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Bill'] = ResolversParentTypes['Bill']
> = ResolversObject<{
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BillTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillTransaction'] = ResolversParentTypes['BillTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  issuer?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  externalAccount?: Resolver<
    Maybe<ResolversTypes['ExternalAccount']>,
    ParentType,
    ContextType
  >;
  biller?: Resolver<ResolversTypes['Biller'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CallbackTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CallbackTransaction'] = ResolversParentTypes['CallbackTransaction']
> = ResolversObject<{
  transaction?: Resolver<
    ResolversTypes['Transaction'],
    ParentType,
    ContextType
  >;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CategorizedMerchantsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategorizedMerchants'] = ResolversParentTypes['CategorizedMerchants']
> = ResolversObject<{
  category?: Resolver<
    ResolversTypes['MerchantCategory'],
    ParentType,
    ContextType
  >;
  merchants?: Resolver<
    Maybe<Array<ResolversTypes['Merchant']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Chat'] = ResolversParentTypes['Chat']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  chatType?: Resolver<ResolversTypes['ChatType'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['Actor']>, ParentType, ContextType>;
  lastMessage?: Resolver<
    Maybe<ResolversTypes['Message']>,
    ParentType,
    ContextType
  >;
  unreadMessagesCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatEdge'] = ResolversParentTypes['ChatEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Chat'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatsCursorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatsCursor'] = ResolversParentTypes['ChatsCursor']
> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['ChatEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CourierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Courier'] = ResolversParentTypes['Courier']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  addresses?: Resolver<
    Maybe<Array<ResolversTypes['Address']>>,
    ParentType,
    ContextType
  >;
  online?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  onlineStatusChangedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  currentPosition?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >;
  requireBiometrics?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  currentDelivery?: Resolver<
    Maybe<ResolversTypes['Delivery']>,
    ParentType,
    ContextType
  >;
  assignedDeliveries?: Resolver<
    Maybe<Array<ResolversTypes['Delivery']>>,
    ParentType,
    ContextType
  >;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CourierWithDistanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourierWithDistance'] = ResolversParentTypes['CourierWithDistance']
> = ResolversObject<{
  courier?: Resolver<ResolversTypes['Courier'], ParentType, ContextType>;
  distanceToPickUpInMeters?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CouriersListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CouriersList'] = ResolversParentTypes['CouriersList']
> = ResolversObject<{
  couriers?: Resolver<
    Array<ResolversTypes['Courier']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CouriersSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CouriersSearchResult'] = ResolversParentTypes['CouriersSearchResult']
> = ResolversObject<{
  couriers?: Resolver<
    Array<ResolversTypes['Courier']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreditCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreditCard'] = ResolversParentTypes['CreditCard']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last4Digits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<
    Maybe<ResolversTypes['PaymentProvider']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['CardType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  addresses?: Resolver<
    Maybe<Array<ResolversTypes['Address']>>,
    ParentType,
    ContextType
  >;
  currentAddress?: Resolver<
    Maybe<ResolversTypes['Address']>,
    ParentType,
    ContextType
  >;
  requireBiometrics?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomersListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomersList'] = ResolversParentTypes['CustomersList']
> = ResolversObject<{
  customers?: Resolver<
    Array<ResolversTypes['Customer']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomersSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomersSearchResult'] = ResolversParentTypes['CustomersSearchResult']
> = ResolversObject<{
  customers?: Resolver<
    Array<ResolversTypes['Customer']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CybersourceDeviceCollectionJwtResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CybersourceDeviceCollectionJWT'] = ResolversParentTypes['CybersourceDeviceCollectionJWT']
> = ResolversObject<{
  ref?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jwt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CybersourceJwtResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CybersourceJWT'] = ResolversParentTypes['CybersourceJWT']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'CybersourceDeviceCollectionJWT' | 'CybersourceStepupJWT',
    ParentType,
    ContextType
  >;
  ref?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jwt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
}>;

export type CybersourceStepupJwtResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CybersourceStepupJWT'] = ResolversParentTypes['CybersourceStepupJWT']
> = ResolversObject<{
  ref?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jwt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  md?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeliveriesListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeliveriesList'] = ResolversParentTypes['DeliveriesList']
> = ResolversObject<{
  deliveries?: Resolver<
    Array<ResolversTypes['Delivery']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveriesSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeliveriesSearchResult'] = ResolversParentTypes['DeliveriesSearchResult']
> = ResolversObject<{
  deliveries?: Resolver<
    Array<ResolversTypes['Delivery']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Delivery'] = ResolversParentTypes['Delivery']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  courier?: Resolver<Maybe<ResolversTypes['Courier']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  orderBatch?: Resolver<ResolversTypes['OrderBatch'], ParentType, ContextType>;
  pickUp?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  dropOff?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DeliveryStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeliveryRequest'] = ResolversParentTypes['DeliveryRequest']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  delivery?: Resolver<ResolversTypes['Delivery'], ParentType, ContextType>;
  courier?: Resolver<ResolversTypes['Courier'], ParentType, ContextType>;
  admin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType>;
  availableUntil?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryStatusUpdateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeliveryStatusUpdate'] = ResolversParentTypes['DeliveryStatusUpdate']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  orderBatch?: Resolver<ResolversTypes['OrderBatch'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'TransactionError' | 'ValidationError',
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
}>;

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'DeliveryStatusUpdate'
    | 'PaymentRequestEvent'
    | 'SystemNotification'
    | 'TransactionEvent',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
}>;

export type EventsListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsList'] = ResolversParentTypes['EventsList']
> = ResolversObject<{
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExternalAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalAccount'] = ResolversParentTypes['ExternalAccount']
> = ResolversObject<{
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FreeMoneyDevResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FreeMoneyDev'] = ResolversParentTypes['FreeMoneyDev']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeoCoordinatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']
> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  small?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndividualResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Individual'] = ResolversParentTypes['Individual']
> = ResolversObject<{
  kraPin?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InterswitchBankResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InterswitchBank'] = ResolversParentTypes['InterswitchBank']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InterswitchBankAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InterswitchBankAccount'] = ResolversParentTypes['InterswitchBankAccount']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountNumber?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  interswitchBank?: Resolver<
    Maybe<ResolversTypes['InterswitchBank']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InvitationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Invitation'] = ResolversParentTypes['Invitation']
> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LimitedCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LimitedCompany'] = ResolversParentTypes['LimitedCompany']
> = ResolversObject<{
  kraPin?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  incorporationCertificate?: Resolver<
    ResolversTypes['Media'],
    ParentType,
    ContextType
  >;
  directorsIds?: Resolver<
    Array<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  cr12?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoadCreditTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoadCreditTransaction'] = ResolversParentTypes['LoadCreditTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  paymentMethod?: Resolver<
    ResolversTypes['PaymentMethod'],
    ParentType,
    ContextType
  >;
  receiver?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']
> = ResolversObject<{
  formattedAddress?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  coordinates?: Resolver<
    ResolversTypes['GeoCoordinates'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoginResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Login'] = ResolversParentTypes['Login']
> = ResolversObject<{
  accessToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  refreshToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Merchant'] = ResolversParentTypes['Merchant']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['MerchantType']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  merchantCategories?: Resolver<
    Maybe<ResolversTypes['MerchantCategory']>,
    ParentType,
    ContextType
  >;
  productCategories?: Resolver<
    Maybe<Array<ResolversTypes['ProductCategory']>>,
    ParentType,
    ContextType
  >;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reviews?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Review']>>>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    ResolversTypes['ProductList'],
    ParentType,
    ContextType,
    RequireFields<MerchantProductsArgs, never>
  >;
  operatingTime?: Resolver<
    Maybe<ResolversTypes['OperatingTime']>,
    ParentType,
    ContextType
  >;
  favorite?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shortDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  allowPayingDirectly?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  coverImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addresses?: Resolver<
    Maybe<Array<ResolversTypes['Address']>>,
    ParentType,
    ContextType
  >;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  idType?: Resolver<
    Maybe<ResolversTypes['VerificationId']>,
    ParentType,
    ContextType
  >;
  idNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicantsName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  approvalState?: Resolver<
    Maybe<ResolversTypes['ApprovalState']>,
    ParentType,
    ContextType
  >;
  approvalMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  showTutorial?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  selection?: Resolver<
    Maybe<Array<ResolversTypes['MerchantSelection']>>,
    ParentType,
    ContextType
  >;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MerchantCategory'] = ResolversParentTypes['MerchantCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<
    Maybe<ResolversTypes['MerchantCategory']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<
    Maybe<Array<ResolversTypes['MerchantCategory']>>,
    ParentType,
    ContextType
  >;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MerchantType'] = ResolversParentTypes['MerchantType']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'Individual' | 'SoleProprietor' | 'LimitedCompany' | 'Partnership',
    ParentType,
    ContextType
  >;
}>;

export type MerchantWithDistanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MerchantWithDistance'] = ResolversParentTypes['MerchantWithDistance']
> = ResolversObject<{
  merchant?: Resolver<ResolversTypes['Merchant'], ParentType, ContextType>;
  distanceInMeters?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantsListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MerchantsList'] = ResolversParentTypes['MerchantsList']
> = ResolversObject<{
  merchants?: Resolver<
    Array<ResolversTypes['Merchant']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantsSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MerchantsSearchResult'] = ResolversParentTypes['MerchantsSearchResult']
> = ResolversObject<{
  merchants?: Resolver<
    Array<ResolversTypes['Merchant']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  edited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageEdge'] = ResolversParentTypes['MessageEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessagesCursorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesCursor'] = ResolversParentTypes['MessagesCursor']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['MessageEdge']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MoneyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']
> = ResolversObject<{
  amountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<
    ResolversTypes['CurrencyCode'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface NaiveDateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NaiveDateTime'], any> {
  name: 'NaiveDateTime';
}

export type NewMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewMessage'] = ResolversParentTypes['NewMessage']
> = ResolversObject<{
  chatId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationChannel'] = ResolversParentTypes['NotificationChannel']
> = ResolversObject<{
  email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushNotification?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  textMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phoneCall?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationSettings'] = ResolversParentTypes['NotificationSettings']
> = ResolversObject<{
  general?: Resolver<
    ResolversTypes['NotificationChannel'],
    ParentType,
    ContextType
  >;
  reminders?: Resolver<
    ResolversTypes['NotificationChannel'],
    ParentType,
    ContextType
  >;
  promotionalMessages?: Resolver<
    ResolversTypes['NotificationChannel'],
    ParentType,
    ContextType
  >;
  account?: Resolver<
    ResolversTypes['NotificationChannel'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OperatingTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatingTime'] = ResolversParentTypes['OperatingTime']
> = ResolversObject<{
  mo?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  tu?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  we?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  th?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  fr?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  sa?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  su?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeInterval']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fulfillment?: Resolver<
    ResolversTypes['OrderFulfillment'],
    ParentType,
    ContextType
  >;
  batches?: Resolver<
    Array<ResolversTypes['OrderBatch']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deliveryDeadline?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  buyer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderBatchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderBatch'] = ResolversParentTypes['OrderBatch']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchant?: Resolver<ResolversTypes['Merchant'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['OrderListItem']>,
    ParentType,
    ContextType
  >;
  totalCost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>;
  tracking?: Resolver<
    Array<ResolversTypes['OrderTracking']>,
    ParentType,
    ContextType
  >;
  delivery?: Resolver<
    Maybe<ResolversTypes['Delivery']>,
    ParentType,
    ContextType
  >;
  pickup?: Resolver<Maybe<ResolversTypes['Pickup']>, ParentType, ContextType>;
  payment?: Resolver<
    Maybe<ResolversTypes['OrderBatchTransaction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderBatchTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderBatchTransaction'] = ResolversParentTypes['OrderBatchTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  orderBatch?: Resolver<ResolversTypes['OrderBatch'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderListItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderListItem'] = ResolversParentTypes['OrderListItem']
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  variant?: Resolver<ResolversTypes['ProductVariant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderTrackingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderTracking'] = ResolversParentTypes['OrderTracking']
> = ResolversObject<{
  status?: Resolver<
    Maybe<ResolversTypes['DeliveryStatus']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OwnProductsSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OwnProductsSearchResult'] = ResolversParentTypes['OwnProductsSearchResult']
> = ResolversObject<{
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = ResolversObject<{
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartnershipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Partnership'] = ResolversParentTypes['Partnership']
> = ResolversObject<{
  kraPin?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  registrationCertificate?: Resolver<
    ResolversTypes['Media'],
    ParentType,
    ContextType
  >;
  directorsIds?: Resolver<
    Array<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  partnershipDeed?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentMethodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'CreditCard' | 'FreeMoneyDev' | 'InterswitchBankAccount' | 'PhoneNumber',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
}>;

export type PaymentRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentRequest'] = ResolversParentTypes['PaymentRequest']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  issuer?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  fulfilled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  revoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  transaction?: Resolver<
    Maybe<ResolversTypes['Transaction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentRequestEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentRequestEvent'] = ResolversParentTypes['PaymentRequestEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  paymentRequest?: Resolver<
    ResolversTypes['PaymentRequest'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PickupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Pickup'] = ResolversParentTypes['Pickup']
> = ResolversObject<{
  status?: Resolver<ResolversTypes['PickupStatus'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  merchant?: Resolver<
    Maybe<ResolversTypes['Merchant']>,
    ParentType,
    ContextType
  >;
  fulfillment?: Resolver<
    Maybe<ResolversTypes['Fulfillment']>,
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    Array<ResolversTypes['ProductCategory']>,
    ParentType,
    ContextType
  >;
  images?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Image']>>>,
    ParentType,
    ContextType
  >;
  options?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProductOption']>>>,
    ParentType,
    ContextType
  >;
  variants?: Resolver<
    Maybe<Array<ResolversTypes['ProductVariant']>>,
    ParentType,
    ContextType
  >;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductCategory'] = ResolversParentTypes['ProductCategory']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<
    Maybe<ResolversTypes['ProductCategory']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<
    Maybe<Array<ResolversTypes['ProductCategory']>>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    Maybe<ResolversTypes['ProductList']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductList'] = ResolversParentTypes['ProductList']
> = ResolversObject<{
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<
    Array<Maybe<ResolversTypes['Product']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductOption'] = ResolversParentTypes['ProductOption']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  values?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<
    Maybe<Array<ResolversTypes['Image']>>,
    ParentType,
    ContextType
  >;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  option?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SelectedOption']>>>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefundTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RefundTransaction'] = ResolversParentTypes['RefundTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  orderBatch?: Resolver<ResolversTypes['OrderBatch'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RootMutationTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RootMutationType'] = ResolversParentTypes['RootMutationType']
> = ResolversObject<{
  acceptPaymentRequest?: Resolver<
    Maybe<ResolversTypes['Transaction']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAcceptPaymentRequestArgs, 'input'>
  >;
  addCardWithInterswitch?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  addOrUpdateAddresses?: Resolver<
    Array<ResolversTypes['Address']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdateAddressesArgs, 'input'>
  >;
  addOrUpdateBiometricsToken?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdateBiometricsTokenArgs, 'input'>
  >;
  addOrUpdateMerchantCategory?: Resolver<
    ResolversTypes['MerchantCategory'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdateMerchantCategoryArgs, never>
  >;
  addOrUpdateProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdateProductArgs, never>
  >;
  addOrUpdateProductCategory?: Resolver<
    ResolversTypes['ProductCategory'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdateProductCategoryArgs, never>
  >;
  addOrUpdatePushNotificationToken?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddOrUpdatePushNotificationTokenArgs, 'input'>
  >;
  addPaymentMethod?: Resolver<
    Maybe<ResolversTypes['PaymentMethod']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddPaymentMethodArgs, 'input'>
  >;
  addProductToWishlist?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddProductToWishlistArgs, 'input'>
  >;
  addReview?: Resolver<
    Maybe<ResolversTypes['Review']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeAddReviewArgs, 'input'>
  >;
  changeCustomerWalletState?: Resolver<
    Maybe<ResolversTypes['WalletActivation']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeChangeCustomerWalletStateArgs, never>
  >;
  changeMerchantApprovalState?: Resolver<
    Maybe<ResolversTypes['Merchant']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeChangeMerchantApprovalStateArgs, never>
  >;
  changeMultipleMerchantsApprovalState?: Resolver<
    Array<ResolversTypes['Merchant']>,
    ParentType,
    ContextType,
    RequireFields<
      RootMutationTypeChangeMultipleMerchantsApprovalStateArgs,
      'ids'
    >
  >;
  changeOrdersStatus?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeChangeOrdersStatusArgs, 'ids' | 'status'>
  >;
  checkCardEnrollment?: Resolver<
    ResolversTypes['CybersourceStepupJWT'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeCheckCardEnrollmentArgs, 'ref'>
  >;
  createChat?: Resolver<
    ResolversTypes['Chat'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeCreateChatArgs, 'memberIds'>
  >;
  createInvite?: Resolver<
    ResolversTypes['Invitation'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeCreateInviteArgs, 'input'>
  >;
  createOrder?: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeCreateOrderArgs, 'input'>
  >;
  createWishlist?: Resolver<
    Maybe<ResolversTypes['Wishlist']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeCreateWishlistArgs, 'input'>
  >;
  deleteMessage?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeDeleteMessageArgs, 'messageId'>
  >;
  deleteWishlist?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeDeleteWishlistArgs, 'id'>
  >;
  deposit?: Resolver<
    ResolversTypes['Transaction'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeDepositArgs, never>
  >;
  depositWithCybersource?: Resolver<
    ResolversTypes['CybersourceDeviceCollectionJWT'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeDepositWithCybersourceArgs, never>
  >;
  depositWithInterswitch?: Resolver<
    ResolversTypes['CallbackTransaction'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeDepositWithInterswitchArgs, never>
  >;
  editMessage?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeEditMessageArgs, 'messageId' | 'text'>
  >;
  favoriteMerchant?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      RootMutationTypeFavoriteMerchantArgs,
      'merchantId' | 'favorite'
    >
  >;
  inviteCouriers?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeInviteCouriersArgs, 'emails'>
  >;
  loadWallet?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoadWalletArgs, 'walletId' | 'input'>
  >;
  lockUsers?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLockUsersArgs, 'ids' | 'locked'>
  >;
  login?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoginArgs, never>
  >;
  loginWithCode?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoginWithCodeArgs, never>
  >;
  loginWithCodeForCourier?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoginWithCodeForCourierArgs, never>
  >;
  loginWithCodeForMerchant?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoginWithCodeForMerchantArgs, never>
  >;
  loginWithProvider?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLoginWithProviderArgs, never>
  >;
  logout?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeLogoutArgs, never>
  >;
  merchantLoginWithProvider?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeMerchantLoginWithProviderArgs, never>
  >;
  merchantSignup?: Resolver<
    Maybe<ResolversTypes['Actor']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeMerchantSignupArgs, never>
  >;
  nearbyMerchants?: Resolver<
    Array<ResolversTypes['MerchantWithDistance']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeNearbyMerchantsArgs, 'currentPosition'>
  >;
  payBill?: Resolver<
    ResolversTypes['Transaction'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypePayBillArgs, 'input'>
  >;
  reactToDeliveryRequest?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeReactToDeliveryRequestArgs, 'input'>
  >;
  readMessages?: Resolver<
    Array<ResolversTypes['Message']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeReadMessagesArgs, 'messageIds'>
  >;
  refreshToken?: Resolver<
    Maybe<ResolversTypes['Login']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRefreshTokenArgs, never>
  >;
  removeAddresses?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveAddressesArgs, 'ids'>
  >;
  removeMerchantCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveMerchantCategoryArgs, 'id'>
  >;
  removePaymentMethod?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemovePaymentMethodArgs, 'id'>
  >;
  removeProduct?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveProductArgs, 'id'>
  >;
  removeProductCategory?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveProductCategoryArgs, 'id'>
  >;
  removeProductFromWishlist?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveProductFromWishlistArgs, 'input'>
  >;
  removeReview?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRemoveReviewArgs, 'id'>
  >;
  requestLoginCode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRequestLoginCodeArgs, never>
  >;
  requestLoginCodeForCourier?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRequestLoginCodeForCourierArgs, never>
  >;
  requestLoginCodeForMerchant?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRequestLoginCodeForMerchantArgs, never>
  >;
  requestOtpForWithdraw?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  requestPayment?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRequestPaymentArgs, 'input'>
  >;
  requestWalletActivation?: Resolver<
    Maybe<ResolversTypes['WalletActivation']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRequestWalletActivationArgs, 'input'>
  >;
  requestWalletActivationForMerchant?: Resolver<
    Maybe<ResolversTypes['WalletActivation']>,
    ParentType,
    ContextType,
    RequireFields<
      RootMutationTypeRequestWalletActivationForMerchantArgs,
      'input'
    >
  >;
  revokeRequest?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeRevokeRequestArgs, 'id'>
  >;
  sendDeliveryRequest?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeSendDeliveryRequestArgs, 'input'>
  >;
  sendMessage?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeSendMessageArgs, 'input'>
  >;
  sendVerificationCode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeSendVerificationCodeArgs, 'input'>
  >;
  setWalletPin?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeSetWalletPinArgs, 'input'>
  >;
  signup?: Resolver<
    Maybe<ResolversTypes['Actor']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeSignupArgs, never>
  >;
  startDelivery?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeStartDeliveryArgs, 'id'>
  >;
  transferCredit?: Resolver<
    ResolversTypes['TransferTransaction'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeTransferCreditArgs, 'input'>
  >;
  updateChatMembers?: Resolver<
    ResolversTypes['Chat'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateChatMembersArgs, 'input'>
  >;
  updateCourierLastLocation?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateCourierLastLocationArgs, 'input'>
  >;
  updateCourierOnlineStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateCourierOnlineStatusArgs, 'online'>
  >;
  updateDeliveryStatus?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateDeliveryStatusArgs, 'id' | 'status'>
  >;
  updateMerchant?: Resolver<
    Maybe<ResolversTypes['Merchant']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateMerchantArgs, never>
  >;
  updateMultipleMerchantsSelections?: Resolver<
    Array<ResolversTypes['Merchant']>,
    ParentType,
    ContextType,
    RequireFields<
      RootMutationTypeUpdateMultipleMerchantsSelectionsArgs,
      'ids' | 'selections'
    >
  >;
  updatePaymentMethod?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdatePaymentMethodArgs, 'id'>
  >;
  updatePickupStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdatePickupStatusArgs, 'id' | 'status'>
  >;
  updateReview?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateReviewArgs, 'id' | 'input'>
  >;
  updateUser?: Resolver<
    Maybe<ResolversTypes['Actor']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateUserArgs, never>
  >;
  updateUserPassword?: Resolver<
    Maybe<ResolversTypes['Actor']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUpdateUserPasswordArgs, 'input'>
  >;
  uploadMedia?: Resolver<
    Maybe<ResolversTypes['Media']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeUploadMediaArgs, 'file'>
  >;
  verifyPhone?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeVerifyPhoneArgs, 'input'>
  >;
  verifyPinOrSignature?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeVerifyPinOrSignatureArgs, 'input'>
  >;
  withdraw?: Resolver<
    ResolversTypes['Transaction'],
    ParentType,
    ContextType,
    RequireFields<RootMutationTypeWithdrawArgs, never>
  >;
}>;

export type RootQueryTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RootQueryType'] = ResolversParentTypes['RootQueryType']
> = ResolversObject<{
  balance?: Resolver<
    Array<Maybe<ResolversTypes['Money']>>,
    ParentType,
    ContextType
  >;
  chats?: Resolver<
    ResolversTypes['ChatsCursor'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeChatsArgs, never>
  >;
  courier?: Resolver<
    ResolversTypes['Courier'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeCourierArgs, 'id'>
  >;
  courierSearch?: Resolver<
    Maybe<ResolversTypes['CouriersSearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeCourierSearchArgs, 'searchQuery'>
  >;
  couriers?: Resolver<
    ResolversTypes['CouriersList'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeCouriersArgs, never>
  >;
  couriersNearDelivery?: Resolver<
    Array<ResolversTypes['CourierWithDistance']>,
    ParentType,
    ContextType,
    RequireFields<
      RootQueryTypeCouriersNearDeliveryArgs,
      'deliveryId' | 'radiusInMeters'
    >
  >;
  customerSearch?: Resolver<
    Maybe<ResolversTypes['CustomersSearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeCustomerSearchArgs, 'searchQuery'>
  >;
  customers?: Resolver<
    Maybe<ResolversTypes['CustomersList']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeCustomersArgs, never>
  >;
  deliveries?: Resolver<
    ResolversTypes['DeliveriesList'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeDeliveriesArgs, never>
  >;
  delivery?: Resolver<
    ResolversTypes['Delivery'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeDeliveryArgs, 'id'>
  >;
  deliveryByOrderId?: Resolver<
    ResolversTypes['Delivery'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeDeliveryByOrderIdArgs, 'id'>
  >;
  deliveryRequests?: Resolver<
    Array<ResolversTypes['DeliveryRequest']>,
    ParentType,
    ContextType
  >;
  deliverySearch?: Resolver<
    Maybe<ResolversTypes['DeliveriesSearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeDeliverySearchArgs, 'searchQuery'>
  >;
  duePaymentRequests?: Resolver<
    Array<ResolversTypes['PaymentRequest']>,
    ParentType,
    ContextType
  >;
  error?: Resolver<
    Maybe<ResolversTypes['ValidationError']>,
    ParentType,
    ContextType
  >;
  events?: Resolver<
    ResolversTypes['EventsList'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeEventsArgs, never>
  >;
  fetchBill?: Resolver<
    ResolversTypes['Bill'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeFetchBillArgs, 'input'>
  >;
  geoCoordinatesToLocation?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeGeoCoordinatesToLocationArgs, 'input'>
  >;
  interswitchBankProviders?: Resolver<
    Array<ResolversTypes['InterswitchBank']>,
    ParentType,
    ContextType
  >;
  invitation?: Resolver<
    ResolversTypes['Invitation'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeInvitationArgs, 'code'>
  >;
  isCashtagAvailable?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeIsCashtagAvailableArgs, 'cashtag'>
  >;
  issuedPaymentRequests?: Resolver<
    Array<Maybe<ResolversTypes['PaymentRequest']>>,
    ParentType,
    ContextType
  >;
  me?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  merchant?: Resolver<
    Maybe<ResolversTypes['Merchant']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeMerchantArgs, 'id'>
  >;
  merchantCategories?: Resolver<
    Array<ResolversTypes['MerchantCategory']>,
    ParentType,
    ContextType
  >;
  merchantSearch?: Resolver<
    Maybe<ResolversTypes['MerchantsSearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeMerchantSearchArgs, 'searchQuery'>
  >;
  merchants?: Resolver<
    Maybe<ResolversTypes['MerchantsList']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeMerchantsArgs, never>
  >;
  merchantsByCategory?: Resolver<
    Maybe<Array<ResolversTypes['CategorizedMerchants']>>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeMerchantsByCategoryArgs, never>
  >;
  messages?: Resolver<
    ResolversTypes['MessagesCursor'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeMessagesArgs, 'chatId'>
  >;
  order?: Resolver<
    ResolversTypes['Order'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeOrderArgs, 'id'>
  >;
  orders?: Resolver<
    Array<Maybe<ResolversTypes['Order']>>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeOrdersArgs, never>
  >;
  ownProductsSearch?: Resolver<
    Maybe<ResolversTypes['OwnProductsSearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeOwnProductsSearchArgs, 'searchQuery'>
  >;
  paymentMethods?: Resolver<
    Array<Maybe<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeProductArgs, 'id'>
  >;
  productCategories?: Resolver<
    Array<ResolversTypes['ProductCategory']>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    ResolversTypes['ProductList'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeProductsArgs, never>
  >;
  recentTransactions?: Resolver<
    Array<ResolversTypes['Transaction']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeRecentTransactionsArgs, never>
  >;
  search?: Resolver<
    Maybe<ResolversTypes['SearchResult']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeSearchArgs, 'searchQuery'>
  >;
  searchOrders?: Resolver<
    ResolversTypes['SearchOrdersResult'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeSearchOrdersArgs, 'input'>
  >;
  transaction?: Resolver<
    Maybe<ResolversTypes['Transaction']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeTransactionArgs, 'id'>
  >;
  transactions?: Resolver<
    ResolversTypes['TransactionsList'],
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeTransactionsArgs, never>
  >;
  userRegistrationStatus?: Resolver<
    Maybe<ResolversTypes['Actor']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeUserRegistrationStatusArgs, 'input'>
  >;
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Actor']>>>,
    ParentType,
    ContextType
  >;
  wishlists?: Resolver<
    Array<ResolversTypes['Wishlist']>,
    ParentType,
    ContextType,
    RequireFields<RootQueryTypeWishlistsArgs, never>
  >;
}>;

export type RootSubscriptionTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RootSubscriptionType'] = ResolversParentTypes['RootSubscriptionType']
> = ResolversObject<{
  newMessage?: SubscriptionResolver<
    ResolversTypes['NewMessage'],
    'newMessage',
    ParentType,
    ContextType
  >;
}>;

export type SearchOrdersResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchOrdersResult'] = ResolversParentTypes['SearchOrdersResult']
> = ResolversObject<{
  orders?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']
> = ResolversObject<{
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType
  >;
  merchants?: Resolver<
    Array<ResolversTypes['Merchant']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SelectedOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SelectedOption'] = ResolversParentTypes['SelectedOption']
> = ResolversObject<{
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Settings'] = ResolversParentTypes['Settings']
> = ResolversObject<{
  notifications?: Resolver<
    Maybe<ResolversTypes['NotificationSettings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SoleProprietorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SoleProprietor'] = ResolversParentTypes['SoleProprietor']
> = ResolversObject<{
  kraPin?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  registrationCertificate?: Resolver<
    ResolversTypes['Media'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemNotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SystemNotification'] = ResolversParentTypes['SystemNotification']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TimeInHmScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['TimeInHm'], any> {
  name: 'TimeInHm';
}

export type TimeIntervalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeInterval'] = ResolversParentTypes['TimeInterval']
> = ResolversObject<{
  from?: Resolver<Maybe<ResolversTypes['TimeInHm']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['TimeInHm']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'BillTransaction'
    | 'LoadCreditTransaction'
    | 'OrderBatchTransaction'
    | 'RefundTransaction'
    | 'TransferTransaction'
    | 'WithdrawTransaction',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
}>;

export type TransactionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionError'] = ResolversParentTypes['TransactionError']
> = ResolversObject<{
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionEvent'] = ResolversParentTypes['TransactionEvent']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  transaction?: Resolver<
    ResolversTypes['Transaction'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionsListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsList'] = ResolversParentTypes['TransactionsList']
> = ResolversObject<{
  transactions?: Resolver<
    Array<ResolversTypes['Transaction']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransferTransaction'] = ResolversParentTypes['TransferTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalAccount?: Resolver<
    Maybe<ResolversTypes['ExternalAccount']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  issuer?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface UploadScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValidationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ValidationError'] = ResolversParentTypes['ValidationError']
> = ResolversObject<{
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WalletResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Wallet'] = ResolversParentTypes['Wallet']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  holds?: Resolver<
    Array<ResolversTypes['WalletHold']>,
    ParentType,
    ContextType
  >;
  totalHeld?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  activation?: Resolver<
    Maybe<ResolversTypes['WalletActivation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WalletActivationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalletActivation'] = ResolversParentTypes['WalletActivation']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['WalletActivationStatus'],
    ParentType,
    ContextType
  >;
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  cashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idType?: Resolver<ResolversTypes['VerificationId'], ParentType, ContextType>;
  idNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idPhotos?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WalletHoldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalletHold'] = ResolversParentTypes['WalletHold']
> = ResolversObject<{
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WishlistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Wishlist'] = ResolversParentTypes['Wishlist']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WithdrawTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WithdrawTransaction'] = ResolversParentTypes['WithdrawTransaction']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  referenceNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TransactionStatus'],
    ParentType,
    ContextType
  >;
  paymentMethod?: Resolver<
    ResolversTypes['PaymentMethod'],
    ParentType,
    ContextType
  >;
  issuer?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Actor?: ActorResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  Admin?: AdminResolvers<ContextType>;
  Bill?: BillResolvers<ContextType>;
  BillTransaction?: BillTransactionResolvers<ContextType>;
  CallbackTransaction?: CallbackTransactionResolvers<ContextType>;
  CategorizedMerchants?: CategorizedMerchantsResolvers<ContextType>;
  Chat?: ChatResolvers<ContextType>;
  ChatEdge?: ChatEdgeResolvers<ContextType>;
  ChatsCursor?: ChatsCursorResolvers<ContextType>;
  Courier?: CourierResolvers<ContextType>;
  CourierWithDistance?: CourierWithDistanceResolvers<ContextType>;
  CouriersList?: CouriersListResolvers<ContextType>;
  CouriersSearchResult?: CouriersSearchResultResolvers<ContextType>;
  CreditCard?: CreditCardResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomersList?: CustomersListResolvers<ContextType>;
  CustomersSearchResult?: CustomersSearchResultResolvers<ContextType>;
  CybersourceDeviceCollectionJWT?: CybersourceDeviceCollectionJwtResolvers<ContextType>;
  CybersourceJWT?: CybersourceJwtResolvers<ContextType>;
  CybersourceStepupJWT?: CybersourceStepupJwtResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeliveriesList?: DeliveriesListResolvers<ContextType>;
  DeliveriesSearchResult?: DeliveriesSearchResultResolvers<ContextType>;
  Delivery?: DeliveryResolvers<ContextType>;
  DeliveryRequest?: DeliveryRequestResolvers<ContextType>;
  DeliveryStatusUpdate?: DeliveryStatusUpdateResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventsList?: EventsListResolvers<ContextType>;
  ExternalAccount?: ExternalAccountResolvers<ContextType>;
  FreeMoneyDev?: FreeMoneyDevResolvers<ContextType>;
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Individual?: IndividualResolvers<ContextType>;
  InterswitchBank?: InterswitchBankResolvers<ContextType>;
  InterswitchBankAccount?: InterswitchBankAccountResolvers<ContextType>;
  Invitation?: InvitationResolvers<ContextType>;
  LimitedCompany?: LimitedCompanyResolvers<ContextType>;
  LoadCreditTransaction?: LoadCreditTransactionResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Login?: LoginResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  Merchant?: MerchantResolvers<ContextType>;
  MerchantCategory?: MerchantCategoryResolvers<ContextType>;
  MerchantType?: MerchantTypeResolvers<ContextType>;
  MerchantWithDistance?: MerchantWithDistanceResolvers<ContextType>;
  MerchantsList?: MerchantsListResolvers<ContextType>;
  MerchantsSearchResult?: MerchantsSearchResultResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessageEdge?: MessageEdgeResolvers<ContextType>;
  MessagesCursor?: MessagesCursorResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  NaiveDateTime?: GraphQLScalarType;
  NewMessage?: NewMessageResolvers<ContextType>;
  NotificationChannel?: NotificationChannelResolvers<ContextType>;
  NotificationSettings?: NotificationSettingsResolvers<ContextType>;
  OperatingTime?: OperatingTimeResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderBatch?: OrderBatchResolvers<ContextType>;
  OrderBatchTransaction?: OrderBatchTransactionResolvers<ContextType>;
  OrderListItem?: OrderListItemResolvers<ContextType>;
  OrderTracking?: OrderTrackingResolvers<ContextType>;
  OwnProductsSearchResult?: OwnProductsSearchResultResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Partnership?: PartnershipResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PaymentRequest?: PaymentRequestResolvers<ContextType>;
  PaymentRequestEvent?: PaymentRequestEventResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  Pickup?: PickupResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductCategory?: ProductCategoryResolvers<ContextType>;
  ProductList?: ProductListResolvers<ContextType>;
  ProductOption?: ProductOptionResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  RefundTransaction?: RefundTransactionResolvers<ContextType>;
  Review?: ReviewResolvers<ContextType>;
  RootMutationType?: RootMutationTypeResolvers<ContextType>;
  RootQueryType?: RootQueryTypeResolvers<ContextType>;
  RootSubscriptionType?: RootSubscriptionTypeResolvers<ContextType>;
  SearchOrdersResult?: SearchOrdersResultResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  SelectedOption?: SelectedOptionResolvers<ContextType>;
  Settings?: SettingsResolvers<ContextType>;
  SoleProprietor?: SoleProprietorResolvers<ContextType>;
  SystemNotification?: SystemNotificationResolvers<ContextType>;
  TimeInHm?: GraphQLScalarType;
  TimeInterval?: TimeIntervalResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  TransactionError?: TransactionErrorResolvers<ContextType>;
  TransactionEvent?: TransactionEventResolvers<ContextType>;
  TransactionsList?: TransactionsListResolvers<ContextType>;
  TransferTransaction?: TransferTransactionResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  ValidationError?: ValidationErrorResolvers<ContextType>;
  Wallet?: WalletResolvers<ContextType>;
  WalletActivation?: WalletActivationResolvers<ContextType>;
  WalletHold?: WalletHoldResolvers<ContextType>;
  Wishlist?: WishlistResolvers<ContextType>;
  WithdrawTransaction?: WithdrawTransactionResolvers<ContextType>;
}>;

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  client?: ClientDirectiveResolver<any, any, ContextType>;
}>;

/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<
  ContextType = any
> = DirectiveResolvers<ContextType>;

export type PossibleTypesResultData = {
  possibleTypes: {
    Actor: ['Customer', 'Merchant', 'Courier', 'Admin'];
    CybersourceJWT: ['CybersourceDeviceCollectionJWT', 'CybersourceStepupJWT'];
    Error: ['TransactionError', 'ValidationError'];
    Event: [
      'DeliveryStatusUpdate',
      'PaymentRequestEvent',
      'SystemNotification',
      'TransactionEvent',
    ];
    MerchantType: [
      'Individual',
      'SoleProprietor',
      'LimitedCompany',
      'Partnership',
    ];
    PaymentMethod: [
      'CreditCard',
      'FreeMoneyDev',
      'InterswitchBankAccount',
      'PhoneNumber',
    ];
    Transaction: [
      'BillTransaction',
      'LoadCreditTransaction',
      'OrderBatchTransaction',
      'RefundTransaction',
      'TransferTransaction',
      'WithdrawTransaction',
    ];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    Actor: ['Customer', 'Merchant', 'Courier', 'Admin'],
    CybersourceJWT: ['CybersourceDeviceCollectionJWT', 'CybersourceStepupJWT'],
    Error: ['TransactionError', 'ValidationError'],
    Event: [
      'DeliveryStatusUpdate',
      'PaymentRequestEvent',
      'SystemNotification',
      'TransactionEvent',
    ],
    MerchantType: [
      'Individual',
      'SoleProprietor',
      'LimitedCompany',
      'Partnership',
    ],
    PaymentMethod: [
      'CreditCard',
      'FreeMoneyDev',
      'InterswitchBankAccount',
      'PhoneNumber',
    ],
    Transaction: [
      'BillTransaction',
      'LoadCreditTransaction',
      'OrderBatchTransaction',
      'RefundTransaction',
      'TransferTransaction',
      'WithdrawTransaction',
    ],
  },
};
export default result;
