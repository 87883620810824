import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead';
import TableBody from './TableBody';
import { Actions, AppContext } from '../../store/index';
import { CourierFieldsFragment, useCouriersLazyQuery } from '../../generated';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { routes } from '../../App';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
    border: '1px solid #E4E4E4',
  },
  paperContainer: {
    margin: '14px 25px',
    boxShadow: 'none',
    width: 'auto',
  },
}));

export type Order = 'asc' | 'desc';

export type SortableFields = 'online';

const Couriers: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { dispatch } = useContext(AppContext);

  const [couriers, setCouriers] = useState<CourierFieldsFragment[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<SortableFields>('online');

  const page = useMemo(() => searchParams.get('page'), [searchParams]);

  const [getCouriers, { loading, data }] = useCouriersLazyQuery({
    variables: {
      input: {
        page: page ? parseFloat(page) : 1,
        pageSize: rowsPerPage,
      },
    },
  });

  useEffect(() => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: loading,
    });
  }, [loading, dispatch]);

  useEffect(() => {
    getCouriers();
    setCouriers(data?.couriers?.couriers || []);
  }, [data?.couriers?.couriers, page, rowsPerPage, getCouriers]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: SortableFields) => {
      setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, order, orderBy],
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      navigate(
        `${routes.couriers}?${createSearchParams({
          page: (newPage + 1).toString(),
        }).toString()}`,
      );
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value));
      navigate(
        `${routes.couriers}?${createSearchParams({ page: '1' }).toString()}`,
      );
    },
    [setRowsPerPage, navigate],
  );

  return (
    <>
      <TableContainer component={Paper} className={classes.paperContainer}>
        <Table className={classes.table} size="medium">
          {couriers.length === 0 && (
            <caption>There are no couriers to display</caption>
          )}
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody data={couriers} order={order} orderBy={orderBy} />
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                colSpan={7}
                count={data?.couriers?.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={page ? parseFloat(page) - 1 : 0}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default Couriers;
