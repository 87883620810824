import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import {
  CircularProgress,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import { AppContext, AppProvider } from './store/index';
import Login from './pages/Login';
import theme from './theme';
import AuthProvider, { useAuth } from './hooks/useAuthContext';
import { MockedResponse } from '@apollo/client/testing';
import UserProvider from './utils/user';
import NotificationProvider from './utils/notification';
import Layout from './components/Layout';
import Merchants from './pages/Merchants/MerchantTable';
import Customers from './pages/Customers/CustomersTable';
import Deliveries from './pages/Deliveries/DeliveriesTable';
import Profile from './pages/Profile/Profile';
import MerchantDetails from './pages/Merchants/MerchantDetails';
import Couriers from './pages/Couriers/CouriersTable';
import DeliveriesHistory from './pages/Couriers/DeliveriesHistory';

interface Props {
  mocks?: MockedResponse[];
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px 0',
  },
}));

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { state } = useContext(AppContext);
  const { isAuthenticated } = useAuth();
  const classes = useStyles();

  return isAuthenticated ? (
    <Layout>
      {state.loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
      {children}
    </Layout>
  ) : (
    <Navigate to={'/login'} />
  );
};

export const routes = {
  dashboard: '/',
  merchants: '/merchants',
  customers: '/customers',
  couriers: '/couriers',
  deliveries: '/deliveries',
  profile: '/profile',
  login: '/login',
};

const App: React.FC<Props> = ({ mocks }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider>
        <Router>
          <AuthProvider mocks={mocks}>
            <UserProvider>
              <NotificationProvider>
                <Routes>
                  <Route path={routes.login} element={<Login />} />
                  <Route
                    path={routes.dashboard}
                    element={
                      <RequireAuth>
                        <Merchants />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.merchants}
                    element={
                      <RequireAuth>
                        <Merchants />
                      </RequireAuth>
                    }>
                    <Route
                      path=":merchantId"
                      element={
                        <RequireAuth>
                          <MerchantDetails />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route
                    path={routes.customers}
                    element={
                      <RequireAuth>
                        <Customers />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.couriers}
                    element={
                      <RequireAuth>
                        <Couriers />
                      </RequireAuth>
                    }>
                    <Route
                      path="history/:courierId"
                      element={
                        <RequireAuth>
                          <DeliveriesHistory />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route
                    path={routes.deliveries}
                    element={
                      <RequireAuth>
                        <Deliveries />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.profile}
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </NotificationProvider>
            </UserProvider>
          </AuthProvider>
        </Router>
      </AppProvider>
    </MuiThemeProvider>
  );
};

export default App;
