import React, { useMemo } from 'react';
import {
  Box,
  makeStyles,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Badge,
  styled,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import { InsertPhoto } from '@material-ui/icons';
import {
  CourierFieldsFragment,
  CouriersNearDeliveryQuery,
} from '../../generated';

interface CouriersTableProps {
  couriers:
    | CouriersNearDeliveryQuery['couriersNearDelivery']
    | CourierFieldsFragment[];
  assignDelivery: (courierId: string) => Promise<void>;
}

const useStyles = makeStyles(() => ({
  paperContainer: {
    boxShadow: 'none',
    borderRadius: 'none',
  },
  table: {
    minWidth: 750,
    borderTop: '2px solid #E4E4E4',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: '10px',
    backgroundSize: 'contain',
    marginRight: '10px',
  },
  detailsButton: {
    minWidth: '84px',
    height: '35px',
    fontSize: '14px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: '10px',
    minWidth: '10px',
    borderRadius: '5px',
    right: '11px',
    bottom: '4px',
  },
}));

const CouriersTable: React.FC<CouriersTableProps> = ({
  assignDelivery,
  couriers,
}) => {
  const classes = useStyles();

  const hasDistance = useMemo(
    () => couriers?.[0] && 'distanceToPickUpInMeters' in couriers[0],
    [couriers],
  );

  return (
    <TableContainer component={Paper} className={classes.paperContainer}>
      <Table className={classes.table} size="medium">
        {couriers.length === 0 && (
          <caption>There are no available couriers</caption>
        )}
        <TableHead>
          <TableRow>
            <TableCell>Courier Name</TableCell>
            <TableCell>Phone</TableCell>
            {hasDistance && <TableCell>Distance to pick-up point</TableCell>}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {couriers.map((data) => {
            const { courier, distanceToPickUpInMeters } =
              'distanceToPickUpInMeters' in data
                ? {
                    courier: data.courier,
                    distanceToPickUpInMeters: data.distanceToPickUpInMeters,
                  }
                : { courier: data, distanceToPickUpInMeters: false };
            return (
              !!courier?.id && (
                <StyledTableRow key={courier.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <StyledBadge
                        overlap="rectangular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot">
                        {courier.avatar?.small ? (
                          <Avatar
                            variant="square"
                            className={classes.image}
                            src={courier.avatar?.small}
                          />
                        ) : (
                          <Avatar variant="square" className={classes.image}>
                            <InsertPhoto />
                          </Avatar>
                        )}
                      </StyledBadge>
                      {courier.user?.name}
                    </Box>
                  </TableCell>
                  <TableCell width="20%">{courier.user?.phone}</TableCell>
                  {distanceToPickUpInMeters &&
                    typeof distanceToPickUpInMeters === 'number' && (
                      <TableCell width="20%">
                        {(distanceToPickUpInMeters / 1000).toFixed(1) + ' km'}
                      </TableCell>
                    )}
                  <TableCell width="100px">
                    <Button
                      size="small"
                      classes={{ root: classes.detailsButton }}
                      onClick={() => courier.id && assignDelivery(courier.id)}>
                      Assign
                    </Button>
                  </TableCell>
                </StyledTableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CouriersTable;
