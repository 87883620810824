import {
  Box,
  Button as MUIButton,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useUserAuth } from '../../utils/user';
import { useNotification } from '../../utils/notification';
import { AuthContext } from '../../hooks/useAuthContext';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} from '../../generated';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../App';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '25px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    font: 'bold 16px/24px Montserrat;',
    marginBottom: '30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textfield: {
    marginBottom: '20px',
    width: '300px',
    background: '#ffffff',
  },
  changePasswordButton: {
    width: '200px',
    marginBottom: '15px',
  },
  buttonContainer: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: '15px',
    color: '#D32F2F',
    marginBottom: '15px',
  },
  helperText: {
    backgroundColor: '#f5f5f5',
    margin: '0',
    padding: '3px 14px',
  },
}));

interface Form {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmedNewPassword: string;
}

const emailSchema = yup
  .object({
    email: yup
      .string()
      .required('Email cannot be empty')
      .email('Please enter a valid email address'),
  })
  .required();

const schema = yup
  .object({
    newPassword: yup
      .string()
      .min(8, 'Your password needs to be at least 8 characters long')
      .max(25, 'Your password needs to be 25 characters long max')
      .matches(/^(?!\s+$)/, 'This field cannot contain only blankspaces'),
    confirmedNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
    email: yup
      .string()
      .required('Email cannot be empty')
      .email('Please enter a valid email address'),
  })
  .required();

const Profile: React.FC = () => {
  const classes = useStyles();
  const { user } = useUserAuth();
  const { logout } = useContext(AuthContext);
  const { openNotification } = useNotification();
  const navigate = useNavigate();

  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const [updateUser] = useUpdateUserMutation();

  const [changePassword, setChangePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedNewPassword, setShowConfirmedNewPassword] = useState(
    false,
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
    resolver: yupResolver(changePassword ? schema : emailSchema),
    defaultValues: {
      email: '',
      oldPassword: '',
      newPassword: '',
      confirmedNewPassword: '',
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    changePassword && updatePassword(data);
    updateEmail(data);
  };

  useEffect(() => {
    const userEmail = user?.email;
    if (!userEmail) return;
    setValue('email', userEmail);
  }, [user?.email, setValue]);

  const updatePassword = useCallback(
    async (data: Form) => {
      try {
        await updateUserPassword({
          variables: {
            input: {
              currentPassword: data.oldPassword,
              password: data.newPassword,
            },
          },
        });
        setValue('oldPassword', data.newPassword),
          setValue('newPassword', ''),
          setValue('confirmedNewPassword', ''),
          setShowOldPassword(false),
          setErrorMessage('');
        openNotification('Password successfully updated!', 'success');
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(
            error.message === 'invalid_password'
              ? 'The password you entered is incorrect. Please try again.'
              : error.message,
          );
        }
      }
    },
    [updateUserPassword, openNotification, setValue],
  );

  const updateEmail = useCallback(
    async (data: Form) => {
      if (data.email === user?.email) return;
      try {
        await updateUser({
          variables: {
            id: user?.id,
            input: {
              email: data.email,
            },
          },
        });
        setErrorMessage('');
        openNotification('Email successfully updated!', 'success');
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    },
    [updateUser, openNotification, user?.email, user?.id],
  );

  return (
    <Box className={classes.container} display="flex">
      <Typography className={classes.title}>Profile Settings</Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!errors.email}
          helperText={errors.email?.message}
          inputRef={register}
          className={classes.textfield}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
        />
        {changePassword && (
          <>
            <TextField
              variant="outlined"
              fullWidth
              id="oldPassword"
              label="Current password"
              name="oldPassword"
              autoComplete="oldPassword"
              autoFocus
              type={showOldPassword ? 'text' : 'password'}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword?.message}
              inputRef={register}
              className={classes.textfield}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end">
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
            />
            <TextField
              variant="outlined"
              fullWidth
              id="newPassword"
              label="New password"
              name="newPassword"
              autoComplete="newPassword"
              autoFocus
              type={showNewPassword ? 'text' : 'password'}
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              inputRef={register}
              className={classes.textfield}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end">
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
            />
            <TextField
              variant="outlined"
              fullWidth
              id="confirmedNewPassword"
              label="Confirm new password"
              name="confirmedNewPassword"
              autoComplete="confirmedNewPassword"
              type={showConfirmedNewPassword ? 'text' : 'password'}
              error={!!errors.confirmedNewPassword}
              helperText={errors.confirmedNewPassword?.message}
              inputRef={register}
              className={classes.textfield}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmedNewPassword(!showConfirmedNewPassword)
                      }
                      edge="end">
                      {showConfirmedNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
            />
          </>
        )}
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        <Box className={classes.buttonContainer}>
          <Button
            classes={{ root: classes.changePasswordButton }}
            onClick={() => setChangePassword(!changePassword)}>
            {changePassword ? 'Cancel' : 'Change password'}
          </Button>
          <Button
            gradient
            type="submit"
            classes={{ root: classes.changePasswordButton }}>
            Save
          </Button>
          <MUIButton
            onClick={() => {
              logout();
              navigate(routes.login);
            }}>
            Logout
          </MUIButton>
        </Box>
      </form>
    </Box>
  );
};

export default Profile;
