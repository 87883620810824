import React, { useContext, useState, useEffect } from 'react';
import { LinearProgress, Snackbar } from '@material-ui/core';
import { MeFieldsFragment, useMeQuery, UserFieldsFragment } from '../generated';
import Alert from '@material-ui/lab/Alert';
import { isAdmin, isUser } from './apollo/helpers';

interface Context {
  admin?: MeFieldsFragment;
  user?: UserFieldsFragment;
}

const UserContext = React.createContext<Context>({});

export const useUserAuth = (): Context => useContext(UserContext);

const UserProvider: React.FC = ({ children }) => {
  const [admin, setAdmin] = useState<MeFieldsFragment>();
  const [user, setUser] = useState<UserFieldsFragment>();
  const [errorMessage, setErrorMessage] = useState('');
  const { data, error, loading } = useMeQuery();

  useEffect(() => {
    if (data?.me && !error && isAdmin(data.me) && isUser(data.me.user)) {
      setAdmin(data.me);
      setUser(data.me?.user);
    }
  }, [data, error]);

  useEffect(() => {
    if (!error) return;
    setErrorMessage(`Unable to get user info. ${error.message}`);
  }, [error]);

  return (
    <>
      {loading && <LinearProgress data-cy="user-loading" />}
      <Snackbar
        open={errorMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <UserContext.Provider
        value={{
          admin,
          user,
        }}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export default UserProvider;
