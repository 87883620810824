import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { DeliveryFieldsFragment } from '../../generated';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as LocationIcon } from '../../icons/location-icon.svg';
import DeliveryStatusTypography from './DeliveryStatus';
import DeliveryStepper from './DeliveryStepper';
import GoogleMapReact from 'google-map-react';
import { googleMapsKey } from '../../utils/constants';
import { ReactComponent as PickUpIcon } from '../../icons/pickup-icon.svg';
import { ReactComponent as DropOffIcon } from '../../icons/dropoff-icon.svg';

interface DeliveryDetailsProps {
  open: boolean;
  delivery: DeliveryFieldsFragment;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '75%',
    maxWidth: 'inherit',
    height: '90vh',
    borderRadius: '10px',
  },
  dialogContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  table: {
    borderTop: '2px solid #E4E4E4',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: '10px 40px 40px 40px',
  },
  closeButton: {
    height: '40px',
    padding: '0',
    margin: '8px',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.primary.main,
  },
  title: {
    margin: '28px 0 0 40px',
  },
  deliveryId: {
    font: 'bold 15px/24px Montserrat',
    color: '#525252',
  },
  deliveryLocation: {
    font: 'normal 14px/20px Montserrat',
    color: '#DC1F5C',
    display: 'flex',
    alignItems: 'center',
  },
  deliveryIcon: {
    marginRight: '6px',
    height: '17px',
    width: '17px',
  },
  detailsTableRow: {
    background: '#F5F5F5',
  },
  detailsTableCell: {
    borderBottomStyle: 'none',
  },
  mapContainer: {
    height: 'calc(90vh - 360px)',
    width: '100%',
  },
  pickupIcon: {
    height: '45px',
    color: '#FE6B01',
    cursor: 'pointer',
  },
}));

const PickUpPoint: React.FC<{
  lat: number;
  lng: number;
}> = () => {
  const classes = useStyles();
  return <PickUpIcon className={classes.pickupIcon} />;
};

const DropOffPoint: React.FC<{
  lat: number;
  lng: number;
}> = () => {
  const classes = useStyles();
  return <DropOffIcon className={classes.pickupIcon} />;
};

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({
  open,
  delivery,
  onCancel,
}) => {
  const classes = useStyles();

  const mapCenterCoord = useMemo(() => {
    return {
      lat: delivery?.dropOff?.location?.coordinates?.lat,
      lng: delivery?.dropOff?.location?.coordinates?.long,
    };
  }, [
    delivery?.dropOff?.location?.coordinates?.lat,
    delivery?.dropOff?.location?.coordinates?.long,
  ]);

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialog }}>
      <Box className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.title}>
              <Typography className={classes.deliveryId}>
                Delivery #{delivery?.id}
              </Typography>
              <Typography className={classes.deliveryLocation}>
                <LocationIcon className={classes.deliveryIcon} />
                {delivery?.pickUp?.location?.formattedAddress}
              </Typography>
            </Box>
            <IconButton className={classes.closeButton} onClick={onCancel}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table className={classes.table} size="medium">
            <TableHead>
              <TableRow>
                <TableCell className={classes.detailsTableCell}>
                  Order ID
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  Pick up from
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  Delivery address
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  Assigned courier
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.detailsTableRow}>
                <TableCell className={classes.detailsTableCell}>
                  {delivery?.orderBatch?.referenceNumber}
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  {delivery?.pickUp?.location?.formattedAddress}
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  {delivery?.dropOff?.location?.formattedAddress}
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  {delivery?.courier?.user?.name ||
                    delivery.courier?.user?.phone}
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  <DeliveryStatusTypography status={delivery?.status} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <DeliveryStepper status={delivery?.status} />
          <div className={classes.mapContainer}>
            {googleMapsKey && (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: googleMapsKey,
                }}
                center={mapCenterCoord}
                zoom={13}>
                <PickUpPoint
                  lat={delivery?.pickUp?.location?.coordinates?.lat}
                  lng={delivery?.pickUp?.location?.coordinates?.long}
                />
                <DropOffPoint
                  lat={delivery?.dropOff?.location?.coordinates?.lat}
                  lng={delivery?.dropOff?.location?.coordinates?.long}
                />
              </GoogleMapReact>
            )}
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeliveryDetails;
