import React from 'react';
import {
  TableBody,
  TableRow,
  withStyles,
  TableCell as MUITableCell,
  Box,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import {
  CustomerFieldsFragment,
  Maybe,
  WalletActivationStatus,
} from '../../generated';
import { getFormattedDate, customerWallet } from '../../utils/utils';
import { SortableFields } from './CustomersTable';
import { InsertPhoto } from '@material-ui/icons';
import ChangeWalletActivationStatusPopover from './ChangeWalletActivationStatusPopover';
import { Order } from '../../utils/types';

interface EnhancedTableBodyProps {
  data: CustomerFieldsFragment[];
  order: Order;
  orderBy: SortableFields;
  updateCustomerWalletStatus: (
    id: Maybe<string> | undefined,
    state: WalletActivationStatus,
  ) => void;
}

const useStyles = makeStyles(() => ({
  image: {
    width: 50,
    height: 50,
    borderRadius: '10px',
    backgroundSize: 'contain',
    marginRight: '10px',
  },
}));

const TableCell = withStyles(() => ({
  root: {
    font: 'normal normal 14px/14px Montserrat',
    padding: '16px 16px 16px 0',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    '&:first-child': {
      padding: '16px',
    },
  },
}))(MUITableCell);

const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = ({
  data,
  orderBy,
  order,
  updateCustomerWalletStatus,
}) => {
  const classes = useStyles();

  const descendingComparator = (
    a: CustomerFieldsFragment,
    b: CustomerFieldsFragment,

    orderBy: SortableFields,
  ) => {
    if (b?.user?.[orderBy] < a?.user?.[orderBy]) {
      return -1;
    }
    if (b?.user?.[orderBy] > a?.user?.[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = <T,>(
    array: CustomerFieldsFragment[],
    comparator: (a: T, b: T) => number,
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: Order,
    orderBy: SortableFields,
  ): ((a: CustomerFieldsFragment, b: CustomerFieldsFragment) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy)).map((customer) => {
        if (!customer?.user?.name) return null;
        return (
          <TableRow key={customer.id}>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                {customer.avatar?.small ? (
                  <Avatar
                    variant="square"
                    className={classes.image}
                    src={customer.avatar?.small}
                  />
                ) : (
                  <Avatar variant="square" className={classes.image}>
                    <InsertPhoto />
                  </Avatar>
                )}
                {customer?.user?.name}
              </Box>
            </TableCell>
            <TableCell width="10%">
              <Box display="flex" alignItems="center">
                {customer?.user?.phone}
              </Box>
            </TableCell>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                {customer?.user?.email}
              </Box>
            </TableCell>
            <TableCell width="20%">
              <Box display="flex" alignItems="center">
                {customer?.currentAddress?.location?.formattedAddress}
              </Box>
            </TableCell>
            <TableCell width="10%">
              <Box display="flex" alignItems="center">
                {customer?.tag}
              </Box>
            </TableCell>
            <TableCell width="10%">
              {getFormattedDate(customer.createdAt)}
            </TableCell>
            <TableCell width="10%">
              <Box display="flex" alignItems="center">
                <ChangeWalletActivationStatusPopover
                  wallet={customerWallet(customer)}
                  onConfirm={async (activationStatus) =>
                    updateCustomerWalletStatus(customer?.id, activationStatus)
                  }
                />
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default EnhancedTableBody;
