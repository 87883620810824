import {
  CustomerFieldsFragment,
  Money,
  ProductVariant,
  WalletFieldsFragment,
} from '../generated';

export const getFormattedDate = (date: Date): string =>
  new Date(date).toLocaleDateString('en-KE');

export const customerWallet = (
  customer: CustomerFieldsFragment,
): WalletFieldsFragment | null | undefined => {
  return customer?.wallet;
};

export const defaultVariant: (
  variants?: ProductVariant[] | null,
) => ProductVariant | undefined = (variants) => {
  if (!variants) return;
  return variants?.find((v) => v.default);
};

export const formatMoney = (money: Money): string => {
  const formatter = new Intl.NumberFormat('en-KE', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(money.amountInCents / 100);
};

export const getValidUrl = (url: string): string => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};
