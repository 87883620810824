import React, { useMemo, useCallback, useState } from 'react';
import {
  Box,
  Checkbox,
  withStyles,
  makeStyles,
  TableBody,
  TableCell as MUITableCell,
  TableRow,
  Typography,
  Chip,
  Avatar,
} from '@material-ui/core';
import {
  ApprovalState,
  Maybe,
  MerchantFieldsFragment,
  MerchantSelection,
} from '../../generated';
import Button from '../../components/common/Button';
import CommentTooltip from './CommentTooltip';
import MerchantDetails from './MerchantDetails';
import { SortableFields } from './MerchantTable';
import { MerchantSelectionLabels } from './FilterDrawer';
import ChangeApprovalStatePopover from './ChangeApprovalStatePopover';
import { getFormattedDate } from '../../utils/utils';
import SelectionPopover, {
  MerchantSelectionAssignedByAdmin,
} from './SelectionPopover';
import { InsertPhoto } from '@material-ui/icons';
import { Order } from '../../utils/types';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../App';
import ManageCategoriesDialog from './ManageCategoriesDialog';

interface EnhancedTableBodyProps {
  data: MerchantFieldsFragment[];
  isSelected: (name: string) => boolean;
  handleClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
  updateMerchant: (id?: Maybe<string>, selection?: MerchantSelection[]) => void;
  updateMerchantApprovalState: (
    merchant: MerchantFieldsFragment,
    approvalState: ApprovalState,
    message?: string,
  ) => void;
  order: Order;
  orderBy: SortableFields;
}

const useStyles = makeStyles(() => ({
  image: {
    width: 50,
    height: 50,
    borderRadius: '10px',
    backgroundSize: 'contain',
    marginRight: '10px',
  },
  commentIcon: {
    width: '25px',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  approvalStatesDefault: {
    color: '#525252',
    font: 'bold 14px/20px Montserrat',
  },
  approvalStatesPending: {
    color: '#DC1F1F',
    font: 'bold 14px/20px Montserrat',
  },
  approvalStatesApproved: {
    color: '#57A200',
    font: 'bold 14px/20px Montserrat',
  },
  detailsButton: {
    minWidth: '84px',
    height: '35px',
    fontSize: '14px',
  },
  selectionChip: {
    margin: '0 5px 5px 0',
  },
  editCategoriesButton: {
    color: '#535353',
  },
}));

const TableCell = withStyles(() => ({
  root: {
    font: 'normal normal 14px/14px Montserrat',
    padding: '16px 16px 16px 0',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
}))(MUITableCell);

const Table: React.FC<EnhancedTableBodyProps> = ({
  data,
  isSelected,
  handleClick,
  updateMerchant,
  updateMerchantApprovalState,
  orderBy,
  order,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { merchantId } = useParams();

  const [manageCategoriesDialogOpen, setManageCategoriesDialogOpen] = useState(
    false,
  );

  const approvalStates = useMemo(() => {
    return {
      NOT_IN_REVIEW: {
        label: 'Not in review',
        style: classes.approvalStatesDefault,
      },
      REVIEW_PENDING: {
        label: 'Review Pending',
        style: classes.approvalStatesPending,
      },
      CHANGES_REQUIRED: {
        label: 'Changes Required',
        style: classes.approvalStatesDefault,
      },
      APPROVED: { label: 'Approved', style: classes.approvalStatesApproved },
      REJECTED: { label: 'Rejected', style: classes.approvalStatesDefault },
    };
  }, [
    classes.approvalStatesApproved,
    classes.approvalStatesDefault,
    classes.approvalStatesPending,
  ]);

  const descendingComparator = (
    a: MerchantFieldsFragment,
    b: MerchantFieldsFragment,
    orderBy: SortableFields,
  ) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = <T,>(
    array: MerchantFieldsFragment[],
    comparator: (a: T, b: T) => number,
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: Order,
    orderBy: SortableFields,
  ): ((a: MerchantFieldsFragment, b: MerchantFieldsFragment) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const deleteSelection = useCallback(
    (merchant: MerchantFieldsFragment, selection: MerchantSelection) => {
      updateMerchant(
        merchant?.id,
        merchant?.selection?.filter((sel) => sel !== selection),
      );
    },
    [updateMerchant],
  );

  const getLabel = useCallback((key) => {
    return MerchantSelectionLabels[
      (key as unknown) as MerchantSelectionAssignedByAdmin
    ];
  }, []);

  return (
    <TableBody>
      {merchantId && <MerchantDetails />}
      <ManageCategoriesDialog
        open={manageCategoriesDialogOpen}
        onCancel={() => {
          setManageCategoriesDialogOpen(false);
        }}
      />
      {stableSort(data, getComparator(order, orderBy)).map(
        (merchant, index) => {
          if (!merchant?.name) return null;

          const isItemSelected = isSelected(merchant?.id || '');
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={merchant.id}>
              <MUITableCell width="42px" padding="none">
                <Checkbox
                  checked={isItemSelected}
                  className={classes.checkbox}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onChange={(e) => handleClick(e, merchant.id || '')}
                />
              </MUITableCell>
              <TableCell width="15%">
                <Box display="flex" alignItems="center">
                  {merchant.logo?.small ? (
                    <Avatar
                      variant="square"
                      className={classes.image}
                      src={merchant.logo?.small}
                    />
                  ) : (
                    <Avatar variant="square" className={classes.image}>
                      <InsertPhoto />
                    </Avatar>
                  )}
                  {merchant.name}
                </Box>
              </TableCell>
              {/* <TableCell width="15%">
                {merchant?.merchantCategories && (
                  <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Typography>
                      {merchant.merchantCategories.parent?.parent?.name}
                    </Typography>
                    <Typography>
                      {merchant.merchantCategories.parent?.name}
                    </Typography>
                    <Typography>{merchant.merchantCategories.name}</Typography>
                  </Breadcrumbs>
                )}
                <Chip
                  variant="outlined"
                  size="small"
                  label="Edit"
                  className={classes.editCategoriesButton}
                  onClick={() => {
                    setManageCategoriesDialogOpen(true);
                  }}
                />
              </TableCell> */}
              <TableCell width="15%">
                {merchant?.selection?.map(
                  (selection) =>
                    getLabel(selection) && (
                      <Chip
                        key={selection}
                        variant="outlined"
                        size="small"
                        label={getLabel(selection)}
                        className={classes.selectionChip}
                        onDelete={() => deleteSelection(merchant, selection)}
                      />
                    ),
                )}
                <SelectionPopover
                  merchant={merchant}
                  updateMerchant={updateMerchant}
                />
              </TableCell>
              <TableCell width="15%">{merchant.shortDescription}</TableCell>
              <TableCell width="10%">
                {getFormattedDate(merchant.createdAt)}
              </TableCell>
              <TableCell width="10%">
                <Box display="flex" alignItems="center">
                  <ChangeApprovalStatePopover
                    merchant={merchant}
                    onConfirm={(approvalState, message) =>
                      updateMerchantApprovalState(
                        merchant,
                        approvalState,
                        message,
                      )
                    }
                  />
                  {merchant.approvalMessage && (
                    <CommentTooltip message={merchant.approvalMessage} />
                  )}
                </Box>
              </TableCell>
              <TableCell width="10%">
                <Typography
                  style={{ whiteSpace: 'nowrap' }}
                  className={
                    merchant?.approvalState
                      ? approvalStates[merchant?.approvalState].style
                      : ''
                  }>
                  {merchant?.approvalState
                    ? approvalStates[merchant?.approvalState].label
                    : ''}
                </Typography>
              </TableCell>
              <TableCell width="100px">
                <Button
                  size="small"
                  classes={{ root: classes.detailsButton }}
                  onClick={() => {
                    navigate(`${routes.merchants}/${merchant.id || ''}`);
                  }}>
                  View
                </Button>
              </TableCell>
              <TableCell width="50px" />
            </TableRow>
          );
        },
      )}
    </TableBody>
  );
};

export default Table;
