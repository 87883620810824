import React, { useMemo, useState, ReactNode } from 'react';
import {
  makeStyles,
  Switch,
  Box,
  Popover,
  Typography,
} from '@material-ui/core';
import { WalletActivationStatus, WalletFieldsFragment } from '../../generated';
import Button from '../../components/common/Button';

interface ChangeWalletActivationStatusPopover {
  wallet?: WalletFieldsFragment | null;
  onConfirm: (activationStatus: WalletActivationStatus) => void;
}

interface WalletActivationMap {
  [WalletActivationStatus.Verified]: {
    title: string;
    status: WalletActivationStatus;
  };
  [WalletActivationStatus.Pending]: {
    title: string;
    status: WalletActivationStatus;
  };
  [WalletActivationStatus.InProgress]: {
    title: string;
    status: WalletActivationStatus;
  };
}

const useStyles = makeStyles(() => ({
  buttonContainer: {},
  popoverContainer: {
    border: '1px solid #DBDBDB',
    boxShadow: '0px 0px 10px #00000019',
    borderRadius: '8px',
    width: '290px',
    padding: '15px',
  },
  walletStatusTitle: {
    font: 'normal 14px/14px Montserrat',
  },
}));

const ChangeWalletActivationStatusPopover: React.FC<ChangeWalletActivationStatusPopover> = ({
  wallet,
  onConfirm,
}) => {
  const [anchorEl, setAnchorEl] = useState<ReactNode | null>(null);

  const classes = useStyles();

  const currentWalletStatus = useMemo(() => wallet?.activation?.status, [
    wallet?.activation?.status,
  ]);

  const walletActivationMap = useMemo((): WalletActivationMap => {
    return {
      [WalletActivationStatus.Verified]: {
        title: "Are you sure you want to deactivate this customer's wallet?",
        status: WalletActivationStatus.Pending,
      },
      [WalletActivationStatus.Pending]: {
        title: "Are you sure you want to activate this customer's wallet?",
        status: WalletActivationStatus.Verified,
      },
      [WalletActivationStatus.InProgress]: {
        title: "Are you sure you want to activate this customer's wallet?",
        status: WalletActivationStatus.Verified,
      },
    };
  }, []);

  return (
    <div>
      <Switch
        checked={!!wallet && currentWalletStatus === 'VERIFIED'}
        color="primary"
        onChange={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        inputProps={{ 'aria-label': 'activation status' }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl as Element}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popoverContainer }}>
        <Box className={classes.buttonContainer}>
          {wallet && currentWalletStatus ? (
            <Box>
              <Typography className={classes.walletStatusTitle}>
                {walletActivationMap[currentWalletStatus].title}
              </Typography>
              <Box display="flex" justifyContent="space-between" mt={2.5}>
                <Button color="primary" onClick={() => setAnchorEl(null)}>
                  Cancel
                </Button>
                <Button
                  gradient
                  onClick={() => {
                    onConfirm(walletActivationMap[currentWalletStatus].status);
                    setAnchorEl(null);
                  }}>
                  Ok
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography>This customer doesn&apos;t have a wallet</Typography>
          )}
        </Box>
      </Popover>
    </div>
  );
};

export default ChangeWalletActivationStatusPopover;
