export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Actor: ['Customer', 'Merchant', 'Courier', 'Admin'],
    CybersourceJWT: ['CybersourceDeviceCollectionJWT', 'CybersourceStepupJWT'],
    Error: ['TransactionError', 'ValidationError'],
    Event: [
      'DeliveryStatusUpdate',
      'PaymentRequestEvent',
      'SystemNotification',
      'TransactionEvent',
    ],
    MerchantType: [
      'Individual',
      'SoleProprietor',
      'LimitedCompany',
      'Partnership',
    ],
    PaymentMethod: [
      'CreditCard',
      'FreeMoneyDev',
      'InterswitchBankAccount',
      'PhoneNumber',
    ],
    Transaction: [
      'BillTransaction',
      'LoadCreditTransaction',
      'OrderBatchTransaction',
      'RefundTransaction',
      'TransferTransaction',
      'WithdrawTransaction',
    ],
  },
};
export default result;
