import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  makeStyles,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { ReactComponent as Courier } from '../../icons/stepper-bycycle-icon.svg';
import { ReactComponent as Home } from '../../icons/stepper-home-icon.svg';
import { ReactComponent as Shop } from '../../icons/stepper-shop-icon.svg';
import { ReactComponent as Cancel } from '../../icons/stepper-cancel-icon.svg';

interface DeliveryStepperProps {
  status: string;
}

const steps = {
  PICK_UP: 'Delivery assigned',
  DROP_OFF: 'Delivery in progress',
  DONE: 'Order delivered',
  CANCELLED: 'Order cancelled',
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#C1C1C1',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#FE6B01',
  },
  completed: {
    backgroundColor: '#FE6B01',
  },
});

const StepIcon: React.FC<StepIconProps> = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles();

  const icons: { [index: string]: React.ReactElement } = {
    1: <Courier />,
    2: <Shop />,
    3: <Home />,
    4: <Cancel height={16} width={16} />,
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icons[String(icon)]}
    </div>
  );
};

const DeliveryStepper: React.FC<DeliveryStepperProps> = ({ status }) => {
  const activeStep = useMemo(
    () => Object.keys(steps).findIndex((el) => el === status),
    [status],
  );

  return (
    <Stepper activeStep={activeStep || 0}>
      {Object.values(steps).map((label) => {
        return (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default DeliveryStepper;
