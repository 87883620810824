import React from 'react';
import {
  Button as MUIButton,
  makeStyles,
  ButtonProps,
  ButtonTypeMap,
} from '@material-ui/core';
import classNames from 'classnames';

interface CustomProps {
  gradient?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  marginTop?: number;
  marginBottom?: number;
  large?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props: Props) =>
      props.gradient ? 'white' : theme.palette.primary.main,
    border: (props: Props) =>
      props.gradient ? 'none' : `1px solid ${theme.palette.primary.main}`,
    background: (props: Props) =>
      props.gradient
        ? 'linear-gradient(90deg, #dc1f5c, #ff6d02)'
        : theme.palette.background.paper,
    padding: (props: Props) =>
      `${props.paddingTop || 'auto'} 40px ${
        props.paddingBottom || 'auto'
      } 40px`,
    marginTop: (props: Props) => props.marginTop || 0,
    marginBottom: (props: Props) => props.marginBottom || 0,
    borderRadius: (props: Props) => (props.large ? '25px' : '30px'),
    minWidth: '120px',
    height: (props: Props) => (props.large ? '50px' : '40px'),
    '&:hover': {
      backgroundColor: (props: Props) =>
        props.gradient
          ? 'linear-gradient(90deg, #dc1f5c, #ff6d02)'
          : theme.palette.background.paper,
      opacity: 0.7,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15,
      height: (props: Props) => (props.large ? '40px' : '35px'),
    },
  },
}));

type Props<
  D extends React.ElementType = ButtonTypeMap['defaultComponent']
> = ButtonProps<D, CustomProps>;

const Button = <C extends React.ElementType>({
  gradient,
  paddingBottom,
  paddingTop,
  marginTop,
  marginBottom,
  large,
  className,
  ...rest
}: React.PropsWithChildren<Props<C>>): React.ReactElement | null => {
  const classes = useStyles({
    gradient,
    paddingBottom,
    paddingTop,
    large,
    marginTop,
    marginBottom,
  });
  return (
    <MUIButton {...rest} className={classNames(classes.root, className)} />
  );
};

export default Button;
