import React, { useCallback, useContext, useState, createContext } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';

interface Notification {
  openNotification(
    message: string,
    type?: Color,
    autoHideDuration?: number,
  ): void;
}

export const NotificationContext = createContext<Notification>({
  openNotification: () => undefined,
});

export const useNotification = (): Notification =>
  useContext(NotificationContext);

const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<Color | undefined>(undefined);
  const [autoHideDuration, setAutoHideDuration] = useState(undefined);

  const openNotification = useCallback((message, type, autoHideDuration) => {
    setNotification(true);
    setMessage(message);
    setType(type);
    setAutoHideDuration(autoHideDuration || 6000);
  }, []);

  const handleClose = useCallback(() => {
    setNotification(false);
    setMessage('');
    setType(undefined);
    setAutoHideDuration(undefined);
  }, []);

  return (
    <>
      {notification && (
        <Snackbar
          open={notification}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}>
          <Alert severity={type} onClose={handleClose}>
            {message}
          </Alert>
        </Snackbar>
      )}
      <NotificationContext.Provider value={{ openNotification }}>
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export default NotificationProvider;
