import React, { useContext } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Button from '../../components/common/Button';
import { DeliveryStatus } from '../../generated';
import { Actions, AppContext } from '../../store/index';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from '../../App';

interface FilterDrawerProps {
  toggleDrawer: (drawerOpened: boolean) => void;
}

enum DeliveryStatusLabels {
  UNASSIGNED = 'Unassigned',
  COURIER_CONFIRMATION_PENDING = 'Request sent',
  PICK_UP = 'Courier assigned',
  DROP_OFF = 'In progress',
  CANCELLED = 'Cancelled',
  DONE = 'Done',
}

const useStyles = makeStyles(() => ({
  approvalStatusFilter: {
    margin: '110px 30px 25px 30px',
    padding: '20px 20px 25px 20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '5px',
  },
  filterTitle: {
    font: 'bold 14px/20px Montserrat',
    color: '#525252',
    marginBottom: '10px',
  },
  filterSelectContainer: {
    width: '100%',
  },
  filterSelect: {
    padding: '8px 14px',
    fontSize: '14px',
  },
  filterMenuItem: {
    fontSize: '14px',
  },
  buttonContainer: {
    margin: '0 30px 25px 30px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  undoFiltersButton: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
  },
}));

const FilterDrawer: React.FC<FilterDrawerProps> = ({ toggleDrawer }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppContext);

  return (
    <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
      <Box className={classes.approvalStatusFilter}>
        <Typography className={classes.filterTitle}>
          Filter by delivery status:
        </Typography>
        <FormControl
          variant="outlined"
          className={classes.filterSelectContainer}>
          <Select
            value={state.selectedDeliveriesStatuses}
            multiple
            displayEmpty
            classes={{ root: classes.filterSelect }}
            onChange={(e) => {
              dispatch({
                type: Actions.SET_SELECTED_DELIVERIES_STATUS,
                payload: e.target.value as DeliveryStatus[],
              });
            }}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Show all</em>;
              }
              return (selected as Array<keyof typeof DeliveryStatusLabels>)
                .map((key) => DeliveryStatusLabels[key])
                .join(', ');
            }}>
            <MenuItem disabled value="" className={classes.filterMenuItem}>
              <em>Show all</em>
            </MenuItem>
            {(Object.keys(DeliveryStatusLabels) as Array<
              keyof typeof DeliveryStatusLabels
            >).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={key}
                  className={classes.filterMenuItem}>
                  {DeliveryStatusLabels[key]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.undoFiltersButton}
          onClick={() => {
            dispatch({
              type: Actions.UNDO_ALL_DELIVERIES_FILTERS,
            });
            navigate(
              `${routes.deliveries}?${createSearchParams({
                page: '1',
              }).toString()}`,
            );
          }}>
          Undo filter
        </Button>
        <Button
          gradient
          autoFocus
          onClick={() => {
            dispatch({
              type: Actions.SET_DELIVERIES_STATUS_FILTERS,
              payload: state.selectedDeliveriesStatuses,
            });
            navigate(
              `${routes.deliveries}?${createSearchParams({
                page: '1',
              }).toString()}`,
            );
            toggleDrawer(false);
          }}>
          Apply
        </Button>
      </Box>
    </div>
  );
};

export default FilterDrawer;
