import { CircularProgress, makeStyles, Snackbar } from '@material-ui/core';
import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  Context,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { MockedProvider, MockedResponse } from '@apollo/client/testing';
import Login from '../pages/Login';
import { ApolloLink } from '@apollo/client';
import { createLink } from 'apollo-absinthe-upload-link';
import {
  localStorageAccessTokenKey,
  localStorageRefreshTokenKey,
  rememberMeKey,
} from '../utils/constants';
import { ErrorTypes } from '../generated';
import result from '../introspection-result';
import typePolicies from '../utils/apollo/typePolicies';
import authClient from '../utils/authClient';
import { Alert } from '@material-ui/lab';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from '../App';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';
const uri = `${backendUrl}/graphql`;
const isTest = process.env.REACT_ENV === 'test';

type AuthContextData = {
  isLoading: boolean;
  isAuthenticated: boolean;
  login: (
    email: string,
    password: string,
    isRememberMe?: boolean,
  ) => Promise<void>;
  logout: () => Promise<void>;
};

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
  },
}));

export const AuthContext = createContext<AuthContextData>({
  isLoading: false,
  isAuthenticated: false,
  login: async () => undefined,
  logout: async () => undefined,
});

const redirectLink = createLink({
  uri,
}) as ApolloLink;

export const useAuth: () => Context = () => useContext(AuthContext);

interface Props {
  mocks?: MockedResponse[];
}

const AuthContextProvider: React.FC<Props> = ({ children, mocks }) => {
  const classes = useStyles();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<string | null | undefined>();
  const [, setRefreshToken] = useState<string | null | undefined>();

  const errorLink = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) return;

    for (const err of graphQLErrors) {
      const errorType = err.extensions?.['type'] as ErrorTypes;
      switch (errorType) {
        case ErrorTypes.TokenExpired:
        case ErrorTypes.TokenInvalid:
          void logout();
          break;
      }
    }
  });

  const authMiddleware = setContext(() => {
    return {
      headers: {
        ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      typePolicies,
    }),
    link: errorLink.concat(authMiddleware).concat(redirectLink),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
    },
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  // Utiliity
  const setTokens = useCallback(
    (
      fromStorage: boolean,
      accessToken = '',
      refreshToken = '',
      isRememberMe = false,
    ) => {
      if (fromStorage) {
        const accessTokenStorage = localStorage.getItem(
          localStorageAccessTokenKey,
        );
        const refreshTokenStorage = localStorage.getItem(
          localStorageRefreshTokenKey,
        );
        if (accessTokenStorage && refreshTokenStorage) {
          setAccessToken(accessTokenStorage);
          setRefreshToken(refreshTokenStorage);
          setIsAuthenticated(true);
        }
        return;
      }
      localStorage.setItem(rememberMeKey, String(Number(isRememberMe)));
      localStorage.setItem(localStorageAccessTokenKey, accessToken);
      localStorage.setItem(localStorageRefreshTokenKey, refreshToken);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setIsAuthenticated(true);
    },
    [],
  );

  const removeTokens = useCallback(() => {
    localStorage.removeItem(rememberMeKey);
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setIsAuthenticated(false);
  }, []);

  const login = useCallback(
    async (identity: string, password: string, isRememberMe?: boolean) => {
      setIsLoading(true);
      try {
        const { data } = await authClient.login({
          identity,
          password,
        });
        setTokens(false, data?.access_token, data?.refresh_token, isRememberMe);
        setIsAuthenticated(true);

        navigate(
          `${routes.merchants}?${createSearchParams({ page: '1' }).toString()}`,
        );
      } catch (e) {
        if (!(e instanceof Error)) return;
        setNotification(true);
        setMessage(
          e?.message === 'Request failed with status code 403'
            ? 'Unable to log in. Please check your email and password and try again'
            : e?.message,
        );
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, setTokens, setNotification, setMessage],
  );

  const init = useCallback(() => {
    const accessToken = localStorage.getItem(localStorageAccessTokenKey);
    if (!accessToken) return;

    setTokens(true);
  }, [setTokens]);

  useEffect(() => {
    if (!isAuthenticated) {
      init();
    }
    setIsLoading(false);
  }, [init, isAuthenticated]);

  const logout = useCallback(async () => {
    removeTokens();
  }, [removeTokens]);

  const handleClose = useCallback(() => {
    setNotification(false);
    setMessage('');
  }, []);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {!isAuthenticated ? (
        <>
          <Login />
          {notification && (
            <Snackbar
              open={notification}
              autoHideDuration={6000}
              onClose={handleClose}>
              <Alert severity="error" onClose={handleClose}>
                {message}
              </Alert>
            </Snackbar>
          )}
        </>
      ) : isTest ? (
        <MockedProvider mocks={mocks} addTypename={false}>
          <>{children}</>
        </MockedProvider>
      ) : (
        <ApolloProvider client={client}>{children}</ApolloProvider>
      )}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
